import { Stack } from "@mui/material";
import ToolbarButton from "./common/toolbarButton";
import { Z_TOOLBAR } from "../constants/constants";
import { KeyboardDoubleArrowLeft } from "@mui/icons-material";
import { KeyboardDoubleArrowRight } from "@mui/icons-material";

const SearchPanelOpenCloseButton = ({ dragWidth,isSearchPanelOpen,setIsSearchPanelOpen, }) => {
	
	const handleClickButton = () => {
		setIsSearchPanelOpen(!isSearchPanelOpen)
	}

	return (
		<Stack
			sx={{
				position: "absolute",
				left: `${dragWidth}px`,
				bottom: 0,
				borderTopRightRadius: "50%",
				borderBottomRightRadius: "0",
				// boxShadow: 3,
				background: "#fff",
				zIndex: Z_TOOLBAR,
			}}
		>
			<ToolbarButton
				title={""}
				icon={isSearchPanelOpen ? <KeyboardDoubleArrowLeft/> : <KeyboardDoubleArrowRight/>}
				onClick={handleClickButton}
			/>
		</Stack>
	);
};
export default SearchPanelOpenCloseButton;
