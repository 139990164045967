import { useState, useEffect, useContext } from "react";
import { Button, Paper, Tooltip } from "@mui/material";
import { SEARCH_OPTION_BUTTONS } from "../../constants/constants";
import {
  searchMode as search,
  province,
  district,
  parsel,
  kadastroApiUrl,
  map,
} from "../../config";
import axios from "axios";
import CustomSelect from "./customSelect";
import { deleteLayer, getLayer, setCenter } from "../../utils/getFeatureLayer";
import { unvisibleLayer, olMap } from "../map/mapView";
import { LeftPanelContext } from "../../context/leftPanelContext";
import { TableContext } from "../../context/tableContext";
import { useTranslation } from 'react-i18next';

export default function SearchBox() {
  const { t, i18n } = useTranslation();
  const { searchMode, setSearchValue } = useContext(LeftPanelContext);
  const { setSelectedRow, filterData, setFilterData } =
    useContext(TableContext);

  useEffect(() => {
    if (district) {
      setSearchValue({ province: province?.id, district: district?.id });
    } else {
      setSearchValue({ province: province?.id });
    }
  }, []);

  useEffect(() => {
    if (district) {
      setSearchValue({ province: province?.id, district: district?.id });
    } else {
      setSearchValue({ province: province?.id });
    }
    deleteLayer("address-layer");
    deleteLayer("parcel-layer");
    deleteLayer("poi-layer");
    if (parsel?.id) {
      unvisibleLayer(parsel?.id);
    }
  }, [searchMode]);

  const handleSearch = () => {
    switch (searchMode) {
      case 0:
        setCenter("address-layer");
        setCenter("yol-layer");
        break;
      case 1:
        setCenter("parcel-layer");
        break;
      case 2:
        setCenter("poi-layer");
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    olMap.on("click", function (e) {
      olMap.forEachFeatureAtPixel(e.pixel, function (feature, layer) {
        if (layer?.getClassName() === "parcel-layer") {
          const query = {};
          query.geometry = getCoordToWKT(e.coordinate);
          query.wkid = map.wkid;

          axios
            .post(kadastroApiUrl.parselDetailsApiUrl, query)
            .then((res) => {
              setSelectedRow({ data: res.data[0], status: "parcel" });
            })
            .catch((err) => {
              throw err;
            });
        } else if (layer?.getClassName() === "table-layer") {          
          JSON.parse(sessionStorage?.filterData)?.map((item) => {
            item?.id === layer?.values_?.oid &&
              setSelectedRow({ data: item, status: "table" });            
          });
        }
      });
    });
  }, []);

  const getCoordToWKT = (coord) => {
    const result = `POINT(${coord[0]} ${coord[1]})`;
    return result;
  };

  return (
    <Paper
      id="searchbox-main-container"
      variant="outlined"
      component="form"
      onSubmit={(evt) => {
        evt.preventDefault();
      }}
      sx={{
        py: 1,
        m: 1,
        flex: 1,
        height: 21,
        display: "flex",
        position: "relative",
      }}
    >
      {/* main search button */}
      <Tooltip title={t(SEARCH_OPTION_BUTTONS[search][searchMode].placeholder)}>
        <Button
          fullWidth
          disableElevation
          color="primary"
          type="submit"
          variant="contained"
          id="main-search-button"
          aria-label="main-search-button"
          onClick={handleSearch}
          sx={{
            my: -1,
            borderRadius: 1,
            bgColor: "primary.main",
            color: "#fff",
            "&:hover": {
              backgroundColor: "primary.dark",
            },
          }}
        >
          {SEARCH_OPTION_BUTTONS[search].map(
            (button, i) => searchMode === button.mode && <button.icon key={i} />
          )}
        </Button>
      </Tooltip>
      <Paper sx={{ ...quickSearchResultsStyles }} elevation={0}>
        <CustomSelect title={SEARCH_OPTION_BUTTONS[search][searchMode].name} />
      </Paper>
    </Paper>
  );
}

const quickSearchResultsStyles = {
  width: "100%",
  borderRadius: "0 0 4px 4px",
  position: "absolute",
  top: "40px",
  left: 0,
  "& .MuiButtonBase-root": {
    justifyContent: "flex-start",
    paddingLeft: 3,
  },
  "& .MuiButton-text": { textTransform: "capitalize", padding: "6px 24px" },
  "& .MuiButton-startIcon": { marginRight: 2 },
};
