import { IconButton, Typography, Grid } from "@mui/material";
import { MainGrid, Root2, SubGrid } from "./common/grid";
import CloseIcon from "@mui/icons-material/Close";
import { useContext, useEffect } from "react";
import { TableContext } from "../context/tableContext";
import RigthPanelSelectedRow from "./rightPanelActions/rightPanelSelectedRow";
import { MainContext } from "../context/mainContext";
import RightPanelMeasurement from "./rightPanelActions/rightPanelMeasurement";
import { RIGHT_PANEL_ACTIONS } from "../constants/constants";
import removeAllRightPanelMeasurementLayers from "./rightPanelActions/rightPanelMeasurementComponents/rightPanelUtils/rightPanelMeasurementFunctions";
import { useTranslation } from 'react-i18next';
import { RightPanelInventory } from "./rightPanelActions/rightPanelInventory";
import { RightPanelPMSAnalysis } from "./rightPanelActions/rightPanelPMSAnalysis";
import { removeAllPMSAnalysisLayers } from "./rightPanelActions/rightPanelPMSAnalysis/rightPanelPMSAnalysisLayers";
import { removeInventoryLayer } from "./rightPanelActions/rightPanelInventoryComponents/rightPanelInventoryData";

const RigthPanel = () => {
  const { t, i18n } = useTranslation();
  const { selectedRow, setSelectedRow, fields } = useContext(TableContext);
  const {
    rightPanelAction,
    setRightPanelAction,
    setRightPanelQueryOnMap,
    setIsRightOverlayOpen
  } = useContext(MainContext);

  useEffect(() => {
    if (selectedRow) {
      setRightPanelAction({});
      setRightPanelQueryOnMap(false)
    }
  }, [selectedRow]);

  return (
    <Root2>
      <MainGrid
        container
        direction="column"
        justify="flex-start"
        alignContent="center"
        sx={{ borderRight: 1, borderColor: "gray.main" }}
      >
        <SubGrid
          item
          sm={12}
          justify="flex-start"
          alignContent="flex-start"
          sx={{ overflow: "hidden", zIndex: 10, padding: 0 }}
        >
          <Grid
            sx={{
              backgroundColor: "#29b6f6",
              color: "white !important",
              pt: 1,
            }}
          >
            <Typography variant="h4">
              {" "}
              {selectedRow ? t("Attirubutes") : t(rightPanelAction?.title)}
            </Typography>

            <IconButton
              onClick={async () => {
                await setRightPanelQueryOnMap(false);
                await setIsRightOverlayOpen(false);
                await setRightPanelAction({});
                removeAllRightPanelMeasurementLayers(); // Eğer Ölçüm katmanları varsa kaldırır
                removeAllPMSAnalysisLayers(); // Eğer PMS Analizi katmanları varsa kaldırır
                removeInventoryLayer(); // Eğer Envanter Tabaka katmanı varsa kaldırır
                setSelectedRow();
              }}
              sx={{ ...buttonStyles }}
            >
              <CloseIcon color="white" />
            </IconButton>
          </Grid>
          {selectedRow && <RigthPanelSelectedRow />}
          {!selectedRow && rightPanelAction?.title === RIGHT_PANEL_ACTIONS[1] && <RightPanelMeasurement />}
          {!selectedRow && rightPanelAction?.title === RIGHT_PANEL_ACTIONS[2] && <RightPanelInventory />}
          {!selectedRow && rightPanelAction?.title === RIGHT_PANEL_ACTIONS[3] && <RightPanelPMSAnalysis />}
        </SubGrid>
      </MainGrid>
    </Root2>
  );
};

const buttonStyles = {
  position: "absolute",
  right: 10,
  top: 7,
  width: 40,
  zIndex: 10,
};
export default RigthPanel;
