import React, { useState, createContext } from 'react';

export const TableContext = createContext();




export const TableContextProvider = ({ children }) => {
    const [isOpenLayerTable, setIsOpenLayerTable] = useState(false);
    const [selectedRow, setSelectedRow] = useState();
    const [filterData, setFilterData] = useState([]);
    const [filterAttributes, setFilterAttributes] = useState([]);
    const [fields, setFields] = useState([]);
    const [tableKeyColumn,setTableKeyColumn] = useState("");
    const [isThereIdColumn,setIsThereIdColumn] = useState(false);
    const [uploadedLocalLayers, setUploadedLocalLayers] = useState({});
    const [uploadedAllLocalLayers, setUploadedAllLocalLayers] = useState([]);
    const [localLayersId, setLocalLayersId] = useState(10000); // Lokal katman Id değerleri 10000 değeri üzerinden başlar
    

    const props = {
        isOpenLayerTable, setIsOpenLayerTable,
        selectedRow, setSelectedRow,
        filterData, setFilterData,
        filterAttributes, setFilterAttributes,
        fields, setFields,
        tableKeyColumn, setTableKeyColumn,
        isThereIdColumn,setIsThereIdColumn,
        uploadedLocalLayers, setUploadedLocalLayers,
        uploadedAllLocalLayers, setUploadedAllLocalLayers,
        localLayersId, setLocalLayersId,
    }

    return <TableContext.Provider value={props}>{children}</TableContext.Provider>
}