import { Close, Image, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Fab,
  FormControl,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { envanterTabakaLayerSettings } from "./rightPanelInventoryData";
import { getServiceLegendBySid } from "../../../services/getServiceLegend";
import { useTranslation } from "react-i18next";
import zIndex from "@mui/material/styles/zIndex";

export const RightPanelInventoryTimeComponent = ({
  time,
  setTime,
}) => {

  const { t, i18n } = useTranslation();

  const [legendModalOpen,setLegendModalOpen] = useState(false);
  const [legendInfo,setLegendInfo] = useState(false);
  
  const handleChangeRequestValue = (item) => {
    if (time?.value === item.value) setTime({});
    else setTime(item);
  };

  return (
    <Box>
      <FormControl
        style={{ padding: "1em", paddingRight: "0.5em", width: "90%" }}
      >
        <Typography variant="h5" style={{ fontWeight: "bold" }}>
          Envanter Tabakaları Tarih Aralığı
        </Typography>
        {timeDict.map((item) => (
          <Button
            key = {item.value}
            variant="contained"
            style={buttonStyle}
            color={time?.value === item.value ? "secondary" : "inherit"}
            endIcon={
              time?.value === item.value ? (
                <Visibility />
              ) : (
                <VisibilityOff />
              )
            }
            onClick={() => handleChangeRequestValue(item)}
          >
            {item?.title}{" "}
          </Button>
        ))}
        
        <Tooltip title={t("legend")}>
        <Fab
          variant="contained"
          style={buttonStyle}
          color={legendModalOpen ? "secondary" : "inherit"}
          onClick={async () => {
            await getServiceLegendBySid(
              parseInt(envanterTabakaLayerSettings.id)
            ).then((legend) => legend && setLegendInfo(legend));
            setLegendModalOpen(!legendModalOpen);
          }}
        >
            <Image/>
        </Fab>
        </Tooltip>

        {legendModalOpen && <div
          // open={legendModalOpen}
          // onClose={() => setLegendModalOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box style={{ ...modalBoxStyle }}>
            <IconButton
              sx={{
                position: "absolute",
                right: "0",
                marginRight: "5px",
                marginTop: "5px"
              }}
              onClick={() => setLegendModalOpen(false)}
            >
              <Close></Close>
            </IconButton>
            <img
              src={legendInfo.lgnd}
              width="100%"
              height="auto"
              alt="legend-1"
              loading="lazy"
              style={{ objectFit: "cover" }}
            />
          </Box>
        </div>}
      </FormControl>
    </Box>
  );
};

const timeDict = [
  { title: "Tümü", value: 1, request: "" },
  { title: "Son 1 ay", value: 2, request: "" },
  { title: "Son 6 ay", value: 3, request: "" },
  { title: "Son 1 yıl", value: 4, request: "" },
];

const buttonStyle = {
  margin: "0.5em",
};

const modalBoxStyle = {
  position: "absolute",
  zIndex:100001,
  bottom: "-100%",
  left: "0%",
  width: 300,
  height: "auto",
  backgroundColor: "white",
  bordeRadius: "10px !important",
  boxShadow: 24,
  p: 4,
  boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
};
