import { useContext, useEffect } from "react";
import TreeView from "@mui/lab/TreeView";
import { PANEL_BP_WIDTH } from "../../constants/constants";
import TreeRenderer, { MinusSquare, PlusSquare, LayerIcon } from "./tree";
import BaseMapFolder from "./baseMapFolder";
import { LeftPanelContext } from "../../context/leftPanelContext";

export default function TreeBoard({
  dragWidth,
  onToggleChange,
  onOpacityChange,
  toggleLayerTable,
  isOpenLayerTable,
}) {
  const {
    treeData,
    expanded,
    setExpanded,
    selectedInnerTreeNodeIds,setSelectedInnerTreeNodeIds
  } = useContext(LeftPanelContext);


  //Multi seçimlerde seçili klasörlerin içindeki itemleri de dönen fonksiyon
  const onNodeSelect = (event, selectedNodeIds) => {
    if (treeData?.items?.length !== 0) {
      var currentAllSelectedNode = [];
      currentAllSelectedNode = selectedNodeIds;
      
      const getItem = (data) => { //Tüm ağacı dönen iç içe döngü 
        //resurcave        
        if (data?.items) { 
          if (currentAllSelectedNode.includes(data?.id)) {
            data.items.map((x) => {
              currentAllSelectedNode.push(x?.id);              
              if (x.item) {
                getItem(x);
              }
            });
          }
          data.items.map((x) => {
            if (x?.items) {
              getItem(x);
            }
          });
        }
      };
      getItem(treeData);
      setSelectedInnerTreeNodeIds(currentAllSelectedNode);
    }
  };

  return (
    <TreeView
      defaultExpanded={expanded}
      onNodeToggle={(event, nodeIds) => setExpanded(nodeIds)} //Genişlet daralt işlemleri için kullanılır
      onNodeSelect={onNodeSelect}
      expanded={expanded} //Genişletilmiş klasörleri tutar
      multiSelect
      aria-label="customized"
      defaultCollapseIcon={
        <MinusSquare size={dragWidth > PANEL_BP_WIDTH ? 14 : 12} />
      }
      defaultExpandIcon={
        <PlusSquare size={dragWidth > PANEL_BP_WIDTH ? 14 : 12} />
      }
      defaultEndIcon={<LayerIcon size={dragWidth > PANEL_BP_WIDTH ? 16 : 14} />}
      sx={{
        height: "60vh",
        flexGrow: 2,
        maxWidth: "100%",
        overflowY: "auto",
      }}
    >
      <TreeRenderer
        nodes={treeData}
        dragWidth={dragWidth}
        onToggleChange={onToggleChange}
        onOpacityChange={onOpacityChange}
        toggleLayerTable={toggleLayerTable}
        isOpenLayerTable={isOpenLayerTable}
      />
      <BaseMapFolder
        dragWidth={dragWidth}
        onToggleChange={onToggleChange}
        onOpacityChange={onOpacityChange}
        toggleLayerTable={toggleLayerTable}
      />
    </TreeView>
  );
}
