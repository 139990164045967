import React from "react";
import AddressDetails from "./detailsMenu/addressDetails";
import ParcelDetails from "./detailsMenu/parcelDetails";
import PoiDetails from "./detailsMenu/poiDetails";
import RoadDetails from "./detailsMenu/roadDetails";

export default function UnstyledSelectsMultiple({
	title,
}) {
	if (title === "address") {
		return (
			<AddressDetails
				title={title}
			/>
		);
	} else if (title === "cadastre") {
		return (
			<ParcelDetails
				title={title}
			/>
		);
	} else if (title === "poi") {
		return (
			<PoiDetails
				title={title}
			/>
		);
	} else if (title === "yol") {
		return (
			<RoadDetails
				title={title}
			/>
		);
	}
}
