import { Visibility } from "@mui/icons-material";
import { VisibilityOff } from "@mui/icons-material";
import { Button } from "@mui/material";
import {
  handleOnClickLayerButton,
} from "./rightPanelUtils/rightPanelMeasurementFunctions";

export const RightPanelMeasurementFIRIListButton = (
{  listObject,
  filteredRSPtaskLayers,
  measurementFilesLayers,
  setMeasurementFilesLayers,
  setFilteredRSPtasksLayers,
  searchValue
}
) => {
  // listObject={data}
  // filteredRSPtaskLayers={filteredRSPtaskLayers}                                            
  // measurementFilesLayers = {measurementFilesLayers}
  // setMeasurementFilesLayers = {setMeasurementFilesLayers}
  // setFilteredRSPtasksLayers = {setFilteredRSPtasksLayers}         
  return (
    <div>
      <Button
        variant="outlined"
        color={
          filteredRSPtaskLayers[listObject.id]?.isFIRILayerOpen
            ? "success"
            : "error"
        }
        sx={{ ...buttonStyles }}
        onClick={() => {
          handleOnClickLayerButton(
            "FIRI-layer",
            listObject?.id,
            measurementFilesLayers,
            setMeasurementFilesLayers,
            setFilteredRSPtasksLayers,
            filteredRSPtaskLayers,
            searchValue
          );
        }}
        endIcon={
          !filteredRSPtaskLayers[listObject.id]?.isFIRILayerOpen ? (
            <VisibilityOff style={{ ...iconInbuttonStyles }}></VisibilityOff>
          ) : (
            <Visibility style={{ ...iconInbuttonStyles }}></Visibility>
          )
        }
      >
        FIRI
      </Button>
    </div>
  );
};

const buttonStyles = {
  height: "2em",
  width: "100%",
  mt: 0.5,
};

const iconInbuttonStyles = {
  height: "0.75em",
  width: "auto",
};
