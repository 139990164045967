import React, { Fragment, useEffect, useState } from 'react'
import { RightPanelPMSAnalysisTimeComponent } from './rightPanelPMSAnalysis/rightPanelPMSAnalysisTimeComponent'
import { RightPanelPMSAnalysisLayer } from './rightPanelPMSAnalysis/rightPanelPMSAnalysisLayers'

export const RightPanelPMSAnalysis = () => {
    const [time,setTime] = useState(1)
    
    useEffect(()=>{
        console.log(time)
    },[time])

  return (
    <Fragment>
       <RightPanelPMSAnalysisTimeComponent
          time={time}
          setTime={setTime}
       />
       <RightPanelPMSAnalysisLayer time={time}/>
    </Fragment>
  )
}
