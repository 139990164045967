import React, { useState, useEffect, useContext } from "react";
import {
  Autocomplete,
  TextField,
  InputAdornment,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import { province, map, district as dist, adresApiUrl } from "../../../config";
import axios from "axios";

import { deleteLayer, getLayer } from "../../../utils/getFeatureLayer";
import { LeftPanelContext } from "../../../context/leftPanelContext";
import { CleaningServicesRounded, Clear, Map } from "@mui/icons-material";
import { useTranslation } from 'react-i18next';

export default function UnstyledSelectsMultiple({ title }) {
  const [district, setDistrict] = useState([]); //ilçe
  const [neighborhoods, setNeighborhoods] = useState([]); //mahalle
  const [csbm, setCsbm] = useState([]); //cadde sokak bulvar mahalle
  const [door, setDoor] = useState([]); //kapı

  const { searchValue, setSearchValue } = useContext(LeftPanelContext);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (dist) {
      axios(adresApiUrl.mahalleApiUrl + dist?.id)
        .then((res) => {
          setNeighborhoods(res.data);
        })
        .catch((err) => {
          throw err;
        });
    } else {
      axios(adresApiUrl.ilceApiUrl, { method: "Get" })
        .then((res) => {
          setDistrict(res.data);
        })
        .catch((err) => {
          throw err;
        });
    }
  }, []);

  const handleChange = (val, title) => {
    switch (title) {
      case "district":
        if (val) {
          axios(adresApiUrl.mahalleApiUrl + val?.id)
            .then((res) => {
              setNeighborhoods(res.data);
            })
            .catch((err) => {
              throw err;
            });
          axios(
            adresApiUrl.sgIlceApiUrl + val?.id + "/simple-geometry/" + map.wkid
          )
            .then((res) => {
              getLayer(res.data, val.ad, "address-layer");
            })
            .catch((err) => {
              throw err;
            });
        } else {
          setNeighborhoods([]);
        }
        setCsbm([]);
        setDoor([]);
        setSearchValue((prev) => ({
          ...prev,
          neighborhoods: undefined,
          csbm: undefined,
          door: undefined,
          [title]: val?.id,
        }));
        break;

      case "neighborhoods":
        if (val) {
          axios(adresApiUrl.csbmApiUrl + val?.id)
            .then((res) => setCsbm(res.data))
            .catch((err) => {
              throw err;
            });
          axios(
            adresApiUrl.sgMahalleApiUrl +
              val?.id +
              "/simple-geometry/" +
              map.wkid
          )
            .then((res) => {
              getLayer(res.data, val.ad, "address-layer");
            })
            .catch((err) => {
              throw err;
            });
        } else {
          setCsbm([]);
        }
        setDoor([]);
        setSearchValue((prev) => ({
          ...prev,
          csbm: undefined,
          door: undefined,
          [title]: val?.id,
        }));
        break;

      case "csbm":
        if (val) {
          axios(
            adresApiUrl.numaratajApiUrl +
              searchValue.neighborhoods +
              "/" +
              val?.id
          )
            .then((res) => setDoor(res.data))
            .catch((err) => {
              throw err;
            });
          axios(
            adresApiUrl.sgCsbmApiUrl +
              searchValue.neighborhoods +
              "/" +
              val?.id +
              "/simple-geometry/" +
              map.wkid
          )
            .then((res) => {
              getLayer(res.data, "", "address-layer");
            })
            .catch((err) => {
              throw err;
            });
        } else {
          setDoor([]);
        }
        setSearchValue((prev) => ({
          ...prev,
          door: undefined,
          [title]: val?.id,
        }));
        break;

      case "door":
        if (val) {
          axios(
            adresApiUrl.cpNumaratajApiUrl +
              val?.id +
              "/center-point/" +
              map.wkid
          )
            .then((res) => {
              getLayer(res.data, "", "address-layer");
            })
            .catch((err) => {
              throw err;
            });
        } else {
          setDoor([]);
        }
        setSearchValue((prev) => ({ ...prev, [title]: val?.id }));
        break;

      default:
        break;
    }
  };

  const getLabel = (option) => {
    if (typeof option === "string") {
      return option;
    } else {
      return option.ad;
    }
  };

  return (
    <>
      {!province?.ad && (
        <Autocomplete
          disableClearable={true}
          freeSolo
          readOnly={true}
          fullWidth
          id="combo-box-demo"
          options={[]}
          sx={{}}
          getOptionLabel={(option) => getLabel(option)}
          isOptionEqualToValue={(option, val) => option.ad === val}
          value={!searchValue?.province ? "" : null}
          onChange={(evt, v) => handleChange(v, "province")}
          ListboxProps={{
            style: {
              maxHeight: 140,
              border: "1px solid #039be5",
              fontSize: 13,
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                sx: { fontSize: "13px !important" },
                readOnly: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <Typography
                      variant="body1"
                      sx={{ fontSize: "14px !important" }}
                    >
                      {t("Province")}:
                    </Typography>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      )}

      <Autocomplete
        clearOnEscape
        clearIcon={
          <Clear
            onClick={() => {
              handleChange("", "district");
              handleChange("", "neighborhoods");
              handleChange("", "csbm");
              handleChange("", "door");
            }}
          />
        }
        //disableClearable={true}
        forcePopupIcon={dist ? false : true}
        freeSolo
        readOnly={dist ? true : false}
        fullWidth
        id="combo-box-demo"
        options={dist ? [] : district}
        sx={{}}
        getOptionLabel={(option) => getLabel(option)}
        isOptionEqualToValue={(option, val) => option.ad === val}
        value={dist ? dist.ad : !searchValue?.district ? "" : null}
        onChange={(evt, v) => handleChange(v, "district")}
        ListboxProps={{
          style: {
            maxHeight: 140,
            border: "1px solid #039be5",
            fontSize: 13,
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              sx: { fontSize: "13px !important" },
              readOnly: dist ? true : false,
              startAdornment: (
                <InputAdornment position="start">
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "14px !important" }}
                  >
                    {t("District")}:
                  </Typography>
                </InputAdornment>
              ),
            }}
          />
        )}
      />

      <Autocomplete
        //disableClearable={true},
        clearOnEscape
        clearIcon={
          <Clear
            onClick={() => {
              handleChange("", "neighborhoods");
              handleChange("", "csbm");
              handleChange("", "door");
            }}
          />
        }
        forcePopupIcon={searchValue?.["district"] === undefined ? false : true}
        freeSolo
        readOnly={searchValue?.["district"] === undefined ? true : false}
        fullWidth
        id="combo-box-demo"
        options={neighborhoods}
        sx={{}}
        getOptionLabel={(option) => getLabel(option)}
        isOptionEqualToValue={(option, val) => option.ad === val}
        value={!searchValue?.neighborhoods ? "" : null}
        onChange={(evt, v) => handleChange(v, "neighborhoods")}
        ListboxProps={{
          style: {
            maxHeight: 140,
            border: "1px solid #039be5",
            fontSize: 13,
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              sx: { fontSize: "13px !important" },
              readOnly: searchValue?.["district"] === undefined ? true : false,
              startAdornment: (
                <InputAdornment position="start">
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "14px !important" }}
                  >
                    {t("Neighborhood")}:
                  </Typography>
                </InputAdornment>
              ),
            }}
          />
        )}
      />

      <Autocomplete
        forcePopupIcon={
          searchValue?.["neighborhoods"] === undefined ? false : true
        }
        //disableClearable={true}
        clearOnEscape
        clearIcon={
          <Clear
            onClick={() => {
              handleChange("", "csbm");
              handleChange("", "door");
            }}
          />
        }
        freeSolo
        readOnly={searchValue?.["neighborhoods"] === undefined ? true : false}
        fullWidth
        id="combo-box-demo"
        options={csbm}
        sx={{}}
        getOptionLabel={(option) => getLabel(option)}
        isOptionEqualToValue={(option, val) => option.ad === val}
        value={!searchValue?.csbm ? "" : null}
        onChange={(evt, v) => handleChange(v, "csbm")}
        ListboxProps={{
          style: {
            maxHeight: 140,
            border: "1px solid #039be5",
            fontSize: 13,
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              sx: { fontSize: "13px !important" },
              readOnly:
                searchValue?.["neighborhoods"] === undefined ? true : false,
              startAdornment: (
                <InputAdornment position="start">
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "14px !important" }}
                  >
                    Csbm:
                  </Typography>
                </InputAdornment>
              ),
            }}
          />
        )}
      />

      <Autocomplete
        forcePopupIcon={searchValue?.["csbm"] === undefined ? false : true}
        //disableClearable={true}
        clearIcon={
          <Clear
            onClick={() => {
              handleChange("", "door");
            }}
          />
        }
        freeSolo
        readOnly={searchValue?.["csbm"] === undefined ? true : false}
        fullWidth
        id="combo-box-demo"
        options={door}
        sx={{}}
        getOptionLabel={(option) => getLabel(option)}
        isOptionEqualToValue={(option, val) => option.ad === val}
        value={!searchValue?.door ? "" : null}
        onChange={(evt, v) => handleChange(v, "door")}
        ListboxProps={{
          style: {
            maxHeight: 140,
            border: "1px solid #039be5",
            fontSize: 13,
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              sx: { fontSize: "13px !important" },
              readOnly: searchValue?.["csbm"] === undefined ? true : false,
              startAdornment: (
                <InputAdornment position="start">
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "14px !important" }}
                  >
                    {t("Door")}:
                  </Typography>
                </InputAdornment>
              ),
            }}
          />
        )}
      />

      <Grid container spacing={2}>
        <Grid item xs={7}></Grid>
        <Grid item xs={5}>
          {searchValue?.district && (
            <Button
              fullWidth
              variant="outlined"
              sx={{ ...buttonStyles }}
              onClick={() => {
                handleChange("", "district");
                handleChange("", "neighborhoods");
                handleChange("", "csbm");
                handleChange("", "door");
                deleteLayer("address-layer");
              }}
            >
              {t("Clean")} &nbsp;
              <CleaningServicesRounded
                sx={{ transform: "rotate(315deg)", height: "15px" }}                
              ></CleaningServicesRounded>
            </Button>
          )}
        </Grid>
      </Grid>
    </>
  );
}

const buttonStyles = {
  justifyContent: "center !important",
  fontSize: "12px",
  fontWeight: "bold",
  alignItems: "center",
  mt: 2,
  // color: "black",
  // boxShadow: "1px 1px 2px #9e9e9e, 0 0 3px #9e9e9e, 0 0 3px #9e9e9e",
  // "&:hover": { background: "#f5f5f5" },
};
