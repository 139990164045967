import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { addWMSLayer, olMap } from "../../map/mapView";
import { LeftPanelContext } from "../../../context/leftPanelContext";
import { deleteLayer } from "../../../utils/getFeatureLayer";

export const RightPanelPMSAnalysisLayer = ({
  time,
}) => {

  const {searchValue} = useContext(LeftPanelContext)

  const [timeRequest,setTimeRequest] = useState(false);
  const [layers,setLayers] = useState(layerDict)

  const handleClickButton = (layerKey) => {
    const updatedList = layers.map(item => {
      if (item.key === layerKey) {
        if (!item.isOpen) addWMSLayerFunc(item)
        else removeWMSLayerFunc(item.className)
        return { ...item, isOpen: !item.isOpen }; 
      }
      removeWMSLayerFunc(item.className);
      return { ...item, isOpen: false };
    });
    setLayers(updatedList);
  };

  const handleCloseLayers = () => {
    const updatedList = layers.map(item => {
      removeWMSLayerFunc(item.className)
      return { ...item, isOpen: false };;
    });
    setLayers(updatedList);
  };

  
  const addWMSLayerFunc = (layerSettings) => {
    let cqlFilter = layerSettings.cqlFilterRegionColumnName + " = " + searchValue.region  + " ";
    if (timeRequest) cqlFilter = cqlFilter + " AND " + layerSettings.cqlFilterTimeColumnName + " > " + timeRequest;
    const layer = addWMSLayer(layerSettings,cqlFilter,undefined)
    const layerList = olMap.getLayers();
    layer.className_ = layerSettings.className
    layer && layerList.push(layer);
  };
  
  const removeWMSLayerFunc = (layerClassName) => {
    deleteLayer(layerClassName);
  };
  
  useEffect(()=>{
    handleCloseLayers();
    let currentDate = new Date();
    switch (time?.toString()) {
      case "1": // 1 Tümü
        setTimeRequest(false)
        return; // Tümü için filtre gereksinimi yok !!!
      case "2": // 2 Son 1 ay
        currentDate.setMonth(currentDate.getMonth() - 1);
        break;
      case "3": // 3 Son 6 ay
        currentDate.setMonth(currentDate.getMonth() - 6);
        break;
      case "4": // 4 Son 1 yıl
        currentDate.setMonth(currentDate.getMonth() - 12);
        break;
      default:
        break;
    }
    let formattedDate = currentDate.toISOString().split('T')[0];
    setTimeRequest(formattedDate);
  },[time]);

  return (
    <Box>
      <FormControl
        style={{ padding: "1em", paddingRight: "0.5em", width: "90%" }}
      >
        {layers.map((layer) => (
          <Button
            key = {layer.key}
            variant="contained"
            style={buttonStyle}
            color={layer?.isOpen ? "secondary" : "inherit"}
            endIcon={
              layer?.isOpen ? (
                <Visibility />
              ) : (
                <VisibilityOff />
              )
            }
            onClick={() => handleClickButton(layer.key)}
          >
            {layer?.title}{" "}
          </Button>
        ))}
      </FormControl>
    </Box>
  );
};

  // IRI, GMO, FWD, ÜYP, ÜDÖ

const layerDict = [
  { className: "PMS-IRI-LAYER", title: "IRI", key: 1, id: "66", mapServiceLayerName:"uys-v_rsp_iri", isOpen:false, cqlFilterRegionColumnName:"bolge_no", cqlFilterTimeColumnName:"last_edit_time" },
  // { className: "PMS-GMO-LAYER", title: "GMO", key: 2, id: "", mapServiceLayerName:"", isOpen:false, cqlFilterRegionColumnName:"bolge_no", cqlFilterTimeColumnName:"last_edit_time" }, // sdID ?
  { className: "PMS-FWD-LAYER", title: "FWD", key: 3, id: "90", mapServiceLayerName:"uys-fwd_drops_analyzed", isOpen:false, cqlFilterRegionColumnName:"bolge_no", cqlFilterTimeColumnName:"last_edit_time" },
  { className: "PMS-ÜYP-LAYER", title: "ÜYP", key: 4, id: "257", mapServiceLayerName:"uys-v_ustyapi_puan_shape", isOpen:false, cqlFilterRegionColumnName:"bolge_no", cqlFilterTimeColumnName:"last_edit_time" },
  // { className: "PMS-ÜDÖ-LAYER", title: "ÜDÖ", key: 5, id: "", mapServiceLayerName:"", isOpen:false, cqlFilterRegionColumnName:"bolge_no", cqlFilterTimeColumnName:"last_edit_time" }, // sdID ?
];

const buttonStyle = {
  margin: "0.5em",
};

export const removeAllPMSAnalysisLayers = () => { layerDict.map(item => deleteLayer(item.className)); }

// {
//   "id": 52,
//   "srvId": 21,
//   "name": "CBS - Tünel",
//   "serviceType": 32,
//   "mapServiceLayerName": "tunel",
//   "wkid": 3857,
//   "extent": "[3019965.052,4306430.6563,4940630.9186,5153816.8453]",
//   "opacity": 0,
//   "visible": false,
//   "geometryType": 0,
//   "featureType": false
// }




