import Dexie from "dexie";
import axios from "axios";
import { appName, baseApiUrl } from "../config";
import { useContext, useEffect } from "react";
import { MainContext } from "../context/mainContext";

const DatabaseProviderXDB = () => {
  const { mainServiceToken } = useContext(MainContext);

  const dbName = "ViewerProduct-webgis";
  const WebSettingsTableName = "WebgisSetting";
  const WebSettingsVersion = 8;
  const instance = new Dexie(dbName);

  const defaultTableSchemaDefinitions = [
    {
      WebgisSetting: "&id,versionNumber",
      Session: "++id, time",
      VersionSchema: "++id, versionNumber, schema",
      //Project: "++id, name, wkid, viewCenter, scale",
      //ProjectServiceDescription:
      //"++id, projectId, serviceDescriptionId, name, order, minScale, maxScale, opacity, visible",
      SessionEdit:
        "++id, sessionId, serviceDescriptionId, sequence, key, operationType, featureType, attributes, customAttributes,[sessionId+serviceDescriptionId],[sessionId+serviceDescriptionId+sequence]",
      //update at startup
      TableVersion: "&id, name, versionNumber",
      ServiceDescription:
        "&id, name, serviceType, serverType, mapServiceURL, mapServiceLayerName, wkid, extent, minScale, maxScale, opacity, visible, settings, tableName, geometryType, featureType",
      Field:
        "&id, serviceDescriptionId, name, fieldName, alias, sequence, visible, editable, required,  keyColumn, geometryColumn, labelColumn, componentType, inputType, minNumber, maxNumber, minLength, maxLength, pattern, domainId, foreignFieldId",
      CoordinateSystem: "&wkid, name, wkt, proj4, extent, tolerance",
      Domain: "&id, name, keyType, valueType, foreignKeyType",
      DomainValue: "&id, domainId, key, value, foreignKey",
    },
    {
      ProjectService: "projectId, preview",
    },
    {
      UserGroupServiceDescription:
        "&id, groupId, serviceDescriptionId, mapOperation, queryOperation, editOperation",
    },
    {
      ServiceDescription:
        "&id, name, serviceType, serverType, mapServiceURL, mapServiceLayerName, wkid, extent, minScale, maxScale, opacity, visible, settings, tableName, geometryType, featureType, dataFilter",
      Field:
        "&id, serviceDescriptionId, name, fieldName, alias, sequence, visible, editable, required,  keyColumn, geometryColumn, labelColumn, componentType, inputType, minNumber, maxNumber, minLength, maxLength, pattern, domainId, foreignFieldId, defaultValue",
    },
    // {
    // 	Project: "++id, name, wkid, viewCenter, scale, preview, isDefault",
    // },
    // {
    // 	Project: "++id, &uuid, name, wkid, viewCenter, scale, preview, isDefault",
    // },
    // {
    // 	Project:
    // 		"++id, &uuid, name, wkid, viewCenter, scale, preview, isDefault, activateModule",
    // },
    // {
    // 	Project:
    // 		"++id, &uuid, name, wkid, viewCenter, scale, preview, isDefault, activeModules",
    // },
    // {
    // 	Project:
    // 		"++id, &uuid, version, name, wkid, viewCenter, scale, preview, isDefault, activeModules",
    // },
    {
      ServiceDescription:
        "&id, name, serviceType, serverType, mapServiceURL, mapServiceLayerName, mapServiceFilter, wkid, extent, minScale, maxScale, opacity, visible, settings, tableName, geometryType, featureType, dataFilter",
    },
    {
      ServiceDescription:
        "&id, name, serviceType, mapServiceURL, mapServiceLayerName, wkid, extent, minScale, maxScale, opacity, visible, settings, tableName, geometryType, featureType",
    },
    {
      ServiceDescription:
        "&id, name, serviceType, mapServiceLayerName, wkid, extent, minScale, maxScale, opacity, visible, settings, tableName, geometryType, featureType",
    },
    {
      ServiceLegend: "&id, sid, lgnd",
    },
    {
      LocalLayers: "&id, title, color, layerObject",
    },
  ];

  const isDBExists = function () {
    try {
      return new Promise(async function (resolve, reject) {
        const exists = await Dexie.exists(dbName);
        resolve(exists);
      });
    } catch (e) {
      throw e;
    }
  };

  const deleteDatabase = function () {
    Dexie.delete(dbName);
  };

  const openDatabase = function () {
    const db = instance;

    try {
      return new Promise(async function (resolve, reject) {
        await db.open();
        resolve();
      });
    } catch (e) {
      throw e;
    }
  };

  const closeDatabase = function () {
    instance.close();
  };

  const updateVersionedTable = function (tableName) {
    const db = instance;

    try {
      const table = db.table(tableName);
      if (tableName.toLowerCase() === "locallayers") {
        return new Promise(async function (resolve, reject) {
          resolve();
        });
      }
      return new Promise(async function (resolve, reject) {
        const response = await axios(
          tableName.toLowerCase() === "usergroupservicedescription"
            ? `${baseApiUrl}/security/user-group`
            : `${baseApiUrl}/xdb/` + tableName.toLowerCase()
        );

        if (response?.data && response?.data.length > 0) {
          await db.transaction("rw", table, async () => {
            await table.clear();
            await table.bulkAdd(response.data);
          });
          resolve();
        } else resolve();
      });
    } catch (e) {
      throw e;
    }
  };

  const syncVersionedTable = function (
    localVersions,
    targetVersions,
    tableName,
    force
  ) {
    const db = instance;

    try {
      const tableVersion = db.table("TableVersion");

      return new Promise(async function (resolve, reject) {
        const layerLocal = localVersions.find(
          (elem) => elem.name === tableName
        );

        const layerTarget = targetVersions.find(
          (elem) => elem.name === tableName
        );

        if (layerTarget?.versionNumber > layerLocal?.versionNumber || force) {
          await tableVersion.update(layerLocal?.id, layerTarget);
          await updateVersionedTable(tableName);
        }
        resolve();
      });
    } catch (e) {
      throw e;
    }
  };

  const updateVersionedTables = function () {
    const db = instance;

    try {
      const table = db.table("TableVersion");

      return new Promise(async function (resolve, reject) {
        const response = await axios(`${baseApiUrl}/xdb/tableversions`, {
          method: "Get",
        });
        const tableVersions = await table.toArray();

        const storage = localStorage.getItem(appName + ".Credentials")
          ? sessionStorage
          : localStorage;

        if (
          tableVersions.length === 0 ||
          tableVersions.length < response.data.length
        ) {
          await table.clear();
          await table.bulkAdd(response.data);

          await updateVersionedTable("ServiceDescription");
          await updateVersionedTable("Field");
          await updateVersionedTable("CoordinateSystem");
          await updateVersionedTable("UserGroupServiceDescription");
          await updateVersionedTable("Domain");
          await updateVersionedTable("DomainValue");
          await updateVersionedTable("ServiceLegend");
          await updateVersionedTable("LocalLayers");
          resolve();
        } else {
          await syncVersionedTable(
            tableVersions,
            response.data,
            "ServiceDescription"
          );
          await syncVersionedTable(tableVersions, response.data, "Field");
          await syncVersionedTable(
            tableVersions,
            response.data,
            "CoordinateSystem"
          );
          await syncVersionedTable(tableVersions, response.data, "Domain");
          await syncVersionedTable(tableVersions, response.data, "DomainValue");
          await syncVersionedTable(
            tableVersions,
            response.data,
            "ServiceLegend"
          );
          const userId = JSON.parse(
            localStorage.getItem("1513.Viewer.Credentials")
          )?.userId;
          const lastUserId = storage.getItem(appName + ".LastUserId");

          let force = false;
          if (lastUserId === null || userId !== Number(lastUserId)) {
            force = true;
          }
          await syncVersionedTable(
            tableVersions,
            response.data,
            "UserGroupServiceDescription",
            force
          );
          await syncVersionedTable(tableVersions, response.data, "LocalLayers");
          resolve();
        }
        // const userId = JSON.parse(
        // 	localStorage.getItem("1513.Viewer.Credentials")
        // )?.userId;
        // storage.setItem(appName + ".LastUserId", userId);
      });
    } catch (e) {
      throw e;
    }
  };

  const updateSettingsData = function () {
    const db = instance;

    try {
      return new Promise(async function (resolve, reject) {
        const table = db.table(WebSettingsTableName);

        const numberOfRows = await table.count();
        if (numberOfRows > 0) {
          await table.update(0, { versionNumber: WebSettingsVersion });
          console.log("WebSettings data updated.");
          resolve();
        } else {
          await table.add({ id: 0, versionNumber: WebSettingsVersion });
          console.log("WebSettings data added.");
          resolve();
        }
      });
    } catch (e) {
      throw e;
    }
  };

  const getVersionSchemas = function (maxVersion) {
    const db = instance;

    try {
      const table = db.table("VersionSchema");
      return new Promise(async function (resolve, reject) {
        const schemas = await table
          .where("versionNumber")
          .belowOrEqual(maxVersion)
          .toArray();
        resolve(schemas);
      });
    } catch (e) {
      throw e;
    }
  };

  const saveVersionSchema = function (version, schema) {
    const db = instance;

    try {
      return new Promise(async function (resolve, reject) {
        const table = db.table("VersionSchema");
        await table.add({ versionNumber: version, schema: schema });
        resolve();
      });
    } catch (e) {
      throw e;
    }
  };

  const createDatabase = function () {
    const db = instance;

    try {
      return new Promise(async function (resolve, reject) {
        if (db.isOpen()) db.close();

        let version = undefined;
        const schema = defaultTableSchemaDefinitions[0];
        version = db.version(1);
        version.stores(schema);

        await openDatabase();
        resolve();
      });
    } catch (e) {
      throw e;
    }
  };

  const upgradeDatabase = function (newSchema) {
    const db = instance;

    const newVersionNo = db.verno + 1;
    let version = undefined;
    let schema = undefined;

    try {
      return new Promise(async function (resolve, reject) {
        let schemas = await getVersionSchemas(db.verno);
        if (db.isOpen()) db.close();

        for (var index = 0; index < schemas.length; index++) {
          schema = schemas[index].schema;
          version = db.version(schemas[index].versionNumber);
          version.stores(schema);
        }

        version = db.version(newVersionNo);
        version.stores(newSchema);

        await openDatabase();

        resolve();
      });
    } catch (e) {
      throw e;
    }
  };

  const init = async function () {
    const db = instance;

    try {
      const dbExists = await isDBExists();

      if (dbExists) {
        await openDatabase();

        const table = db.table(WebSettingsTableName);
        const record = await table.get(0);
        if (WebSettingsVersion > record.versionNumber) {
          const schema = defaultTableSchemaDefinitions[WebSettingsVersion];
          if (schema !== undefined) {
            await upgradeDatabase(schema);

            await saveVersionSchema(db.verno, schema);

            await updateSettingsData();
          }
        }
      } else {
        await db.delete(); //test

        await createDatabase();

        let version = 1;
        let schema1 = defaultTableSchemaDefinitions[0];

        await saveVersionSchema(version, schema1);

        for (
          let index = 1;
          index < defaultTableSchemaDefinitions.length;
          index++
        ) {
          version++;

          schema1 = defaultTableSchemaDefinitions[index];
          await upgradeDatabase(schema1);

          await saveVersionSchema(version, schema1);
        }

        await updateSettingsData();
      }
      await updateVersionedTables();
    } catch (e) {
      throw e;
    }
  };

  useEffect(() => {    
    if (mainServiceToken) init();
  }, [mainServiceToken]);
  
  return <></>;
};

export default DatabaseProviderXDB;
