import { useState, useEffect, useContext } from "react";
import { useScreen } from "./hooks/useScreen";
import {
  Alert,
  CircularProgress,
  Toolbar,
  AppBar,
  Box,
  IconButton,
} from "@mui/material";
import {
  VisibilityOffOutlined,
  Visibility,
  UnfoldLess,
  UnfoldMoreTwoTone,
} from "@mui/icons-material";

import { MainGrid, Root, SubGrid, GridHandle } from "./common/grid";
import SearchBox from "./common/searchBox";
import TreeBoard from "./common/treeBoard";
import LegendPanel from "./legendPanel";
import { Rnd } from "react-rnd";
import { PANEL_WIDTH, Z_DRAG_HANDLE } from "../constants/constants";
import {
  getServiceDescriptionById,
  getServiceDescriptionByServiceType,
} from "../services/getServiceDescription";
import {
  olMap,
  addDbLayers,
  visibleLayer,
  unvisibleLayer,
  setLayerOpacity,
} from "./map/mapView";
import { parsel, serviceDirectory } from "../config";
import axios from "axios";
import { setSessionLayer, getSessionLayer } from "../utils/sessionLayer";
import { getCredentials } from "../utils/auth";
import { LeftPanelContext } from "../context/leftPanelContext";
import { MainContext } from "../context/mainContext";
import { ScreenshotProject } from "../utils/screenshotProject";
import { TableContext } from "../context/tableContext";
import { ObjectEvent } from "ol/Object";
// import { getLocalLayers, getLocalLayersMaxId } from "../services/getField";
import { useTranslation } from 'react-i18next';
import { deleteLayersForSpecificAttributeBoolValue } from "../utils/getFeatureLayer";

export default function SearchPanel({
  dragWidth,
  dragHeight,
  onDragWidthChange,
  toggleLayerTable,
  handleChangeProject,
  isSearchPanelOpen,
}) {
  const { setUploadedAllLocalLayers, uploadedAllLocalLayers,setLocalLayersId } =
    useContext(TableContext);
  
    const { searchValue} =
    useContext(LeftPanelContext);
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState({ status: false, message: "" });

  const [isLayerAddComplited, setIsLayerAddComplited] = useState(false);

  const { isMobile, height, width } = useScreen();
  const {
    layerSelect,
    setLayerSelect,
    treeData,
    setTreeData,
    toggle,
    setToggle,
    opacity,
    setOpacity,
    expanded,
    setExpanded,
    treeNodeIds,
    setTreeNodeIds,
    selectedInnerTreeNodeIds,
  } = useContext(LeftPanelContext);
  const { selectedProject, setSelectedProject, legendPanelSRID,setLegendPanelSRID } =
    useContext(MainContext);

  const getKeys = (object) => {
    if (object) {
      return Object.keys(object);
    } else {
      return [];
    }
  };

  //Görünürlük (Visible) Değişimi Fonksiyonu
  const onToggleChange = (nodeId, val) => {
    if (toggle?.[nodeId]) {
      unvisibleLayer(nodeId);
      setLayerSelect({ serviceDescriptionId: "", state: false });
    } else {
      visibleLayer(nodeId);
      setLayerSelect({ serviceDescriptionId: nodeId, state: true });
    }
    setToggle((prev) => ({
      ...prev,
      [nodeId]: val,
    }));
  };

  // Opacity Değişimi Fonksiyonu
  const onOpacityChange = (nodeId, val) => {
    setOpacity({ ...opacity, [nodeId]: val });
    if (toggle?.[nodeId]) {
      setLayerOpacity(nodeId, val);
    } else {
      setLayerOpacity(nodeId, 100);
    }
  };

  //Tüm Klasörleri Genişletme/Daraltma Tıklaması
  const handleExpandClick = () => {
    setExpanded((expanded) => (expanded.length === 0 ? treeNodeIds : []));
  };

  //Seçili Klasörlerin Görünürlüklerini Kapatma Tıklaması
  const handdleDisableAllVisibility = () => {
    if (selectedInnerTreeNodeIds?.length === 0) {
      treeNodeIds.map((layerId) => {
        unvisibleLayer(parseInt(layerId)); //Haritada görünürlüğü kapar
        //onToggleChange(parseInt(layerId), false);
        setToggle((prev) => ({ ...prev, [parseInt(layerId)]: false }));
      });
    } else {
      selectedInnerTreeNodeIds.map((layerId) => {
        unvisibleLayer(parseInt(layerId)); //Haritada görünürlüğü kapar
        //onToggleChange(parseInt(layerId), false);
        setToggle((prev) => ({ ...prev, [parseInt(layerId)]: false }));
      });
    }
  };

  //Seçili Klasörlerin Görünürlüklerini Açma Tıklaması
  const handdleEnableAllVisibility = () => {
    if (selectedInnerTreeNodeIds?.length === 0) {
      treeNodeIds.map((layerId) => {
        visibleLayer(parseInt(layerId)); //Haritada görünürlüğü açar
        //onToggleChange(parseInt(layerId), true);
        setToggle((prev) => ({ ...prev, [parseInt(layerId)]: false }));
      });
    } else {
      selectedInnerTreeNodeIds.map((layerId) => {
        visibleLayer(parseInt(layerId)); //Haritada görünürlüğü açar
        //onToggleChange(parseInt(layerId), true);
        setToggle((prev) => ({ ...prev, [parseInt(layerId)]: true }));
      });
    }
  };

  const changeObjectKeys = (objectArr) =>{
    const newObject = []
    if(objectArr){
      objectArr.forEach(item => {
        const newObj = {id: item.Id, items: item.Items?.length>0?changeObjectKeys(item.Items) : null, operationType: item.OperationType, title: item.Title}
        newObject.push(newObj);
      })
    }

    return newObject;
  }

  //* KLASÖRDEKİ KATMANLARI SERVİSTEN ÇEKER
  useEffect(() => {
    if (selectedProject.id !== 0) {
      axios(serviceDirectory.hierarchicalData + "/" + selectedProject.id)
        .then(async (res) => {
          // addGeojsonLocalLayer(uploadedLocalLayers[item], localLayersColor,item); // Katmanı OpenLayers a ekleme
          
          //Yerel Katmanların en üst klasör olarak eklenmesi
          if (Object.keys(uploadedAllLocalLayers).length !== 0) {
            res.data = [
              {
                id: "999999",
                title: <strong>Yerel Katmanlar</strong>,
                items: uploadedAllLocalLayers,
              },
            ].concat(res.data);
          }
          const resData = changeObjectKeys(res.data);
          setTreeData({
            id: "-1",
            title: selectedProject?.name + " Projesi Katmanları",
            items: resData,
          });

          setIsLoading(false);
          setError({ status: false, message: "" });
        })
        .catch((err) => {
          if (err?.response?.status === 405) {
            setError({
              status: true,
              message: t("NotAuthorizedView"),
            });
          } else if (err?.response?.status === 404) {
            setError({ status: true, message: t("ContentnotFound") });
          } else {
            setError({ status: true, message: t("UnexpectedError") });
          }
          setIsLoading(false);
          throw err;
        });
    }
  }, [selectedProject, uploadedAllLocalLayers]);

  // * KLASÖRLERDEKİ KATMANLARI HARİTAYA EKLER
  useEffect(() => {
    const removeLayerInfoArray = deleteLayersForSpecificAttributeBoolValue("shouldItBeUptadedBasedOnSearchValue") // search value ya göre silinmesi gereken katmanları silmek için çalıştırıldı
    
    if (treeData) {
      let itemCount = 0;
      let count = 0;
      const getItem = (data) => {
        if (data?.items) {
          data.items.map((x) => getItem(x));
        } else {
          if (data?.title) {
            getServiceDescriptionById(data?.id).then((x) => {
              if (x) {
                count++;
                const isForce = removeLayerInfoArray.filter(layer => layer.title.toString() === data?.id.toString())?.[0]?.isVisible;  // search value ya göre silinen katmanların yeniden eklenirkenki görünürlükleri için eklendi
                addDbLayers(x,isForce,undefined,searchValue);
              }
              if (count === itemCount) {
                //* parsel katmanını ekler
                
                if (parsel) {
                  getServiceDescriptionById(parsel?.id).then((x) => {
                    if (x) {
                      addDbLayers(x, false,undefined,searchValue);
                    }
                  });
                }

                //* projenin altlık katmanını ekler
                if (selectedProject.bmapid) {
                  getServiceDescriptionById(selectedProject.bmapid).then(
                    (x) => {
                      if (x) {
                        addDbLayers(x, false, true,searchValue);
                      }
                      setIsLayerAddComplited(true);
                    }
                  );
                } else {
                  getServiceDescriptionByServiceType(8).then((x) => {
                    if (x) {
                      addDbLayers(x, false, true,searchValue);
                      setSelectedProject((prev) => ({
                        ...prev,
                        bmapid: x[0].id,
                      }));
                    }
                    setIsLayerAddComplited(true);
                  });
                }
              }
            });
          }
        }
      };

      const getItemCount = (data) => {
        if (data?.items) {
          data.items.map((x) => getItemCount(x));
        } else {
          if (data?.title) {
            itemCount++;
          }
        }
      };

      getItemCount(treeData);
      getItem(treeData);
    }

    olMap.render()
  }, [searchValue,treeData, uploadedAllLocalLayers]);

  //* HER KATMAN VEYA OPACİTY DEĞİŞTİĞİNDE STORAGE KAYIT EDER
  useEffect(() => {
    if (Object.keys(toggle).length > 0 || Object.keys(opacity).length > 0) {
      setSessionLayer(toggle, opacity);
    }
  }, [opacity, toggle]);

  //* Tüm klasör ve katmanların Id değerlerini tutar
  useEffect(() => {
    if (treeData?.items?.length !== 0) {
      var currentNodeIds = [];
      const getItem = (data) => {
        currentNodeIds.push(data?.id);
        if (data?.items) {
          data.items.map((x) => getItem(x));
        } else {
          if (data?.id) {
            currentNodeIds.push(data?.id);
          }
        }
      };
      getItem(treeData);
      setTreeNodeIds(currentNodeIds);
    }
  }, [treeData, uploadedAllLocalLayers]);

  //* KATMANLAR YÜKLENDİKTEN SONRA KULLANICI ÖNCEKİ OTURUMDA İŞLEM YAPTIĞI KATMANLARI GETİRİR
  useEffect(() => {
    if (isLayerAddComplited) {
      const prevToggle = getSessionLayer()?.toggle;
      const prevOpacity = getSessionLayer()?.opacity;
      const currentUserId = getCredentials()?.userId;
      if (prevToggle) {
        setToggle(
          prevToggle?.[currentUserId] ? prevToggle?.[currentUserId] : {}
        );
        const parentKeys = getKeys(prevToggle?.[currentUserId]);
        parentKeys.forEach((item, index) => {
          if (prevToggle?.[currentUserId][item]) {
            visibleLayer(parseInt(item));
            setLayerSelect({ serviceDescriptionId: item, state: true });
          }
        });
      }
      if (prevOpacity) {
        setOpacity(
          prevOpacity?.[currentUserId] ? prevOpacity?.[currentUserId] : {}
        );
        const parentKeys = getKeys(prevOpacity?.[currentUserId]);
        parentKeys.forEach((item) => {
          setTimeout(() => {
            prevOpacity[currentUserId][item] &&
              setLayerOpacity(parseInt(item), prevOpacity[currentUserId][item]);
          }, 1000);
        });
      }
      //* Projenin altlık haritasını görünür yapar
      setToggle((prev) => ({ ...prev, [selectedProject?.bmapid]: false }));
      visibleLayer(parseInt(selectedProject?.bmapid));

      setTimeout(() => {
        ScreenshotProject(selectedProject);
      }, 1000);
    }
  }, [isLayerAddComplited]);

//   useEffect(async ()=>{          
//     // IndexDB üzerinden lokal katmanları ekler
//     const localLayers = await getLocalLayers();    
//     if (localLayers.length > 0) {
//       let draftUploadedAllLocalLayers = [];
//       localLayers.forEach((layer) => {
//         draftUploadedAllLocalLayers.push({
//           id: layer.id,
//           title: layer.title,
//           items: null,
//         });
//         addGeojsonLocalLayer(
//           layer.layerObject,
//           layer.color,
//           layer.id,
//         );
//       });
//       let draftMaxLocalLayersId = await getLocalLayersMaxId();
//       await setLocalLayersId(draftMaxLocalLayersId+1)
//       await setUploadedAllLocalLayers(draftUploadedAllLocalLayers.sort((a,b) => a.id - b.id).reverse());
//     }
// },[isLayerAddComplited])

  return (
    <Rnd      
      default={{
        x: 0,
        y: 0,
        width: PANEL_WIDTH,
        height: height,
      }}
      disableDragging
      enableResizing={{ top: false, bottom: false, right: isSearchPanelOpen ? true : false, left: false }}
      resizeGrid={[10, 10]}
      minWidth={isSearchPanelOpen ? PANEL_WIDTH * 0.9 : 0}
      maxWidth={PANEL_WIDTH * 1.5} //width > 1200 ? PANEL_WIDTH * 1.5 : width * 0.3
      size={{ width: dragWidth, height: height }}
      onResize={(e, direction, ref, delta, position) => {
        onDragWidthChange(ref.offsetWidth);
      }}
      onResizeStop={(e, direction, ref, delta, position) => {
        localStorage.setItem("dragWidth", parseInt(ref.style.width));
      }}
      resizeHandleStyles={{
        right: { zIndex: Z_DRAG_HANDLE },
      }}
      resizeHandleComponent={{
        right: (
          <GridHandle
            sx={{
              right: -9,
              bottom: dragHeight / 2,
              transform: "rotate(90deg) !important",
            }}
          />
        ),
      }}
      style={{
        position: "fixed",
        zIndex: isSearchPanelOpen ? Z_DRAG_HANDLE : -1,
      }}
    >
      <Root id="table-panel">
        <MainGrid
          container
          direction="column"
          justify="flex-start"
          alignContent="center"
          sx={{ borderRight: 1, borderColor: "gray.main" }}
        >
          <SubGrid
            item
            sm={4.5}
            justify="flex-start"
            alignContent="flex-start"
            sx={{ overflow: "hidden", zIndex: 10,overflowY:'auto' }}
          >
            {/* <Typography variant="h5">Arama</Typography> */}
            <SearchBox />
          </SubGrid>

          <SubGrid
            item
            sm={1.1}
            justify="flex-start"
            alignContent="flex-start"
            sx={{ borderTop: "1px solid lightgray",overflowY:"hidden" }}
          >
            {/* Katman Araç Çubuğu */}
            <Box sx={{ mb: 1 }}>
              <AppBar position="static">
                <Toolbar
                  variant="dense"
                  style={{ minHeight: "20px", maxHeight: "auto" }}
                >
                  <IconButton
                    onClick={handdleEnableAllVisibility}
                    title={t("TurnOnLayer")}
                  >
                    <Visibility
                      style={{
                        marginLeft: "7px",
                        color: "white",
                      }}
                    />
                  </IconButton>

                  <IconButton
                    onClick={handdleDisableAllVisibility}
                    title={t("TurnOffLayer")}
                  >
                    <VisibilityOffOutlined
                      style={{
                        marginLeft: "7px",
                        color: "white",
                      }}
                    />
                  </IconButton>

                  <IconButton
                    onClick={handleExpandClick}
                    title={
                      expanded?.length === 0
                        ? t("ExpandFolders")
                        : t("CollapseFolders")
                    }
                  >
                    {expanded?.length === 0 ? (
                      <UnfoldMoreTwoTone //Klasörleri Genişlet (Expand)
                        style={{
                          marginLeft: "7px",
                          color: "white",
                        }}
                      />
                    ) : (
                      <UnfoldLess //Klasörleri Daralt (Collapse)
                        style={{
                          marginLeft: "7px",
                          color: "white",
                        }}
                      />
                    )}
                  </IconButton>
                </Toolbar>
              </AppBar>
            </Box>
          </SubGrid>

          {/* layer data */}
          {!isLoading ? (
            error.status ? (
              <Alert severity="error">{error.message}</Alert>
            ) : (
              <SubGrid
                item
                sm={6}
                justify="flex-start"
                alignContent="flex-start"
              >
                {/* <Stack direction="row" sx={{ justifyContent: "space-evenly", alignItems: "center" }}>
								<Typography variant="h5">{selectedProject.name} Proje Katman Klasörleri</Typography>
								<IconButton sx={{ borderRadius: 1, marginTop: -1, padding: "3px" }} color="primary" onClick={handleChangeProject}>
									<RepeatIcon />
								</IconButton>
							</Stack> */}
                <TreeBoard
                  dragWidth={dragWidth}
                  onToggleChange={onToggleChange}
                  onOpacityChange={onOpacityChange}
                  toggleLayerTable={toggleLayerTable}
                  isLoading={isLoading}
                />
              </SubGrid>
            )
          ) : (
            <SubGrid
              item
              sm={8}
              justify="flex-center"
              alignContent="flex-center"
              sx={{ borderTop: "1px solid lightgray" }}
            >
              <CircularProgress />
            </SubGrid>
          )}
          <LegendPanel
            onClose={() => setLegendPanelSRID(null)}
            selectedSid={parseInt(legendPanelSRID)}
          />
        </MainGrid>
      </Root>
    </Rnd>
  );
}
