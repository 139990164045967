import {
  Avatar,
  FormControl,
  Input,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  StepIcon,
} from "@mui/material";

import UploadFileIcon from "@mui/icons-material/UploadFile";

import Close from "@mui/icons-material/Close";
import ToolbarButton from "./common/toolbarButton";
import { Z_TOOLBAR } from "../constants/constants";

import React, { useState, useEffect, useContext } from "react";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { getAllCoordinateSystem } from "../services/getCoordinateSystem";

import parse, { DxfParser } from "dxf-parser";
import { dxf2Geojson } from "../utils/dxf2Geojson";
import {
  ColorLens,
  DeleteForever,
  DeleteForeverSharp,
  DeleteOutline,
  FolderSpecialOutlined,
  Layers,
  Queue,
} from "@mui/icons-material";

import { TableContext } from "../context/tableContext";
import { addGeojsonLocalLayer } from "./map/mapView";
import { map } from "../config";
import { CircularProgress } from "@mui/material";

import {addLocalLayerToIndexDB} from "../services/getField"
import { useTranslation } from 'react-i18next';

const UploadLayerButton = ({ dragWidth }) => {
  const { t, i18n } = useTranslation();
  const {
    setUploadedLocalLayers,
    uploadedLocalLayers,
    uploadedAllLocalLayers,
    setUploadedAllLocalLayers,
    localLayersId,
    setLocalLayersId,
  } = useContext(TableContext);

  const [modalOpen, setModalOpen] = useState(false);
  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => {
    setLocalLayers({});
    setFileFormat("");
    setModalOpen(false);
    setLocalLayerName("");
  };

  const [fileFormat, setFileFormat] = useState("");
  const [fileCoordinateSystem, setFileCoordinateSystem] = useState(map?.wkid);
  const [allCoordinateSystem, setAllCoordinateSystem] = useState();
  const [localLayerName, setLocalLayerName] = useState("");
  const [localLayers, setLocalLayers] = useState({});
  const [localLayersColor, setLocalLayersColor] = useState("#FFFFFF");

  const [files, setFiles] = useState([]);

  //Dosyalar (Yüklenen Veri)
  const [dxf, setDxf] = useState({});
  const [geoJson, setGeoJson] = useState({});

  // Loading Animasyonu
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (modalOpen) {
      getAllCoordinateSystem().then((res) => setAllCoordinateSystem(res));
    }
  }, [modalOpen]);

  useEffect(() => {
    if (fileFormat == "DXF" && Object.keys(dxf).length !== 0) {
      setLocalLayers(
        dxf2Geojson(
          dxf,
          fileCoordinateSystem,
          localLayerName,
          localLayersId,
          setLocalLayersId
        )
      );
    }
    if (fileFormat == "GeoJson" && Object.keys(geoJson).length !== 0) {
      var draftGeoJson = geoJson;
      draftGeoJson["name"] = localLayerName;

      var localLayerObject =  {};
      localLayerObject[localLayersId] = draftGeoJson

      setLocalLayers(localLayerObject);
      setLocalLayersId((parseInt(localLayersId) + 1).toString()); 
    }
  }, [dxf,geoJson]);

  //DXF reader
  const dxfOnFileLoadingSuccess = (event) => {
    const fileReader = event.target;
    var parser = new DxfParser();
    setDxf(parser.parseSync(fileReader.result));
    return dxf;
  };

  // Dosya Formatına Göre Yüklenmesi 
  const onFileSelected = (event) => {
    setLoading(true);
    const file = event.target.files?.[0];
    const reader = new FileReader();

    if (fileFormat === "DXF" && file) {
      reader.onloadend = dxfOnFileLoadingSuccess;
      reader.readAsText(file);
    }
    if (fileFormat === "GeoJson" && file) {            
      try {
        reader.onload = event => setGeoJson(JSON.parse(event.target.result))        
        reader.readAsText(file)      
      }
      catch(err) {
        console.log("Dosya Dönüştürülemedi.")
      }
    }
    setLoading(false);
  };

  // Tüm Uploaded Layerları Tutar
  useEffect(() => {
    if (Object.keys(uploadedLocalLayers).length !== 0) {
      //Tüm katman grubuna seçili katmanları ekleme
      Object.keys(uploadedLocalLayers).map((item) => {          
        if (
          uploadedAllLocalLayers.length==0 || uploadedAllLocalLayers.filter(layer => layer.id === item).length === 0
          )
        {setUploadedAllLocalLayers((prev) => [
          {
            id: item,
            title: uploadedLocalLayers[item].name,
            items: null,
          },...prev          
        ]);}
      });

      //Seçili katmanları yayınlama
      Object.keys(uploadedLocalLayers).map((item) => {        
        addLocalLayerToIndexDB(  // Katmanı Index DB ye ekleme
          item,
          uploadedLocalLayers[item].name,
          localLayersColor,
          uploadedLocalLayers[item]);
        addGeojsonLocalLayer(uploadedLocalLayers[item], localLayersColor,item); // Katmanı OpenLayers a ekleme
      });      
      
      setUploadedLocalLayers({});
    }
  }, [uploadedLocalLayers]);



  return (
    <div>
      <Modal
        keepMounted
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={{ ...style }}>
          <Grid container style={{ marginBottom: "25px" }}>
            <Grid item md={10}>
              {" "}
              <h2>{t("AddLocalLayer")}</h2>
            </Grid>

            <Grid item md={2}>
              <Button
                style={{
                  float: "right",
                  borderRadius: "50%",
                  minWidth: "0px",
                  marginTop: "15px",
                }}
                onClick={handleModalClose}
              >
                <Close />
              </Button>
            </Grid>
          </Grid>

          {/* Katman Adı */}
          <Grid container style={{ justifyContent: "space-between" }}>
            <Grid item md={3}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  {t("LocalLayerName")}
                </InputLabel>
                <Input
                  value={localLayerName}
                  label="Yerel Katman Adı"
                  onChange={(value) => setLocalLayerName(value.target.value)}
                ></Input>
              </FormControl>
            </Grid>

            {/* Dosya Formatı */}
            <Grid item md={3}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  {t("FileFormat")}
                </InputLabel>
                <Select
                  value={fileFormat}
                  label="Dosya Formatı"
                  onChange={(value) => {
                    setLocalLayers({});
                    setFileFormat(value.target.value);}}
                >
                  <MenuItem value="DXF">DXF</MenuItem>                  
                  <MenuItem value="GeoJson">
                    GeoJson
                  </MenuItem>
                  <MenuItem value="ShapeFile" disabled>
                    ShapeFile
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Katman Koordinat Sistemi */}
            <Grid item md={5}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                {t("LayerCoordinateSystem")}
                </InputLabel>

                <Select
                  value={fileCoordinateSystem}
                  label="Katman Koordinat Sistemi"
                  onChange={(value) => {
                    setFileCoordinateSystem(value.target.value);
                  }}
                  MenuProps={{
                    style: {
                      maxHeight: 200,
                      maxWidth: 200,
                    },
                  }}
                  disabled = {fileFormat!="DXF"}                  
                >
                  {allCoordinateSystem?.map((item, index) => (
                    <MenuItem
                      title={item.name.toString()}
                      key={item.wkid}
                      value={item.wkid}
                    >
                      &nbsp; {item.wkid} - {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

          </Grid>


          {/* Dosya Yükle */}
          <Grid
            container
            style={{ marginTop: "25px", justifyContent: "center" }}
          >
            <Grid item>
              <label htmlFor="upload-photo">
                <Button
                  color="primary"
                  variant="contained"
                  component="span"
                  style={{ color: "white" }}
                  disabled={
                    !localLayerName || !fileFormat || !fileCoordinateSystem
                  }
                >
                  <input
                    style={{ display: "none" }}
                    id="upload-photo"
                    name="upload-photo"
                    type="file"
                    accept={"." + fileFormat.toLowerCase()}
                    multiple //= {fileFormat==="ShapeFile"}
                    onChange={async (event) => {
                      await setUploadedLocalLayers({});
                      onFileSelected(event);
                    }} //Uploaded Layers Sıfırlanır
                    disabled={
                      !localLayerName || !fileFormat || !fileCoordinateSystem
                    }
                    hidden
                  />
                  <UploadFileIcon />
                  &nbsp;&nbsp;{t("UploadFile")}
                </Button>
              </label>
            </Grid>
          </Grid>

          <Grid container style={{ justifyContent: "center" }}>
            <Grid item ml={3} hidden={!loading} style = {{"marginTop":"30px"}}>
               <CircularProgress></CircularProgress>
            </Grid>
          </Grid>

          {/* Dosya Listesi */}
          <Grid container style={{ justifyContent: "center" }}>
            <Grid item ml={3} hidden={Object.keys(localLayers).length < 1}>
              <List
                dense={false}
                style={{
                  maxHeight: "250px",
                  maxWidth: "500px",
                  overflow: "auto",
                }}
              >
                <ListItem style={{ justifyContent: "center" }}>
                  <input
                    type="color"
                    defaultValue={localLayersColor}
                    onChange={(event) =>
                      setLocalLayersColor(event.target.value)
                    }
                    style={{ marginRight: "7px", marginLeft: "7px" }}
                  ></input>

                  {Object.keys(localLayers).length == 0 && (
                    <h4>Katman Rengi</h4>
                  )}
                  {Object.keys(localLayers).length > 0 && (
                    <h4>Katmanların Rengi</h4>
                  )}
                </ListItem>
                {Object.keys(localLayers).map((layer) => (
                  <ListItem
                    // Delete İşlemi
                    // secondaryAction={
                    //   <IconButton
                    //     edge="end"
                    //     aria-label="delete"
                    //     title={"Yerel Katman Sil"}
                    //     onClick={() => deleteLocalLayer(layer)} //
                    //   >
                    //     <DeleteOutline />
                    //   </IconButton>
                    // }
                    key={layer}
                  >
                    <ListItemAvatar>
                      <Avatar style={{ color: localLayersColor }}>
                        <Layers />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={localLayers[layer].name}
                      secondary={
                        "Veri Sayısı : " +
                        Object.keys(
                          localLayers[layer].features
                        ).length.toString()
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>

          {/* Yerel Katmanları Ekle */}
          <Grid
            container
            style={{ marginTop: "25px", justifyContent: "center" }}
          >
            <Grid item hidden={Object.keys(localLayers).length < 1}>
              <Button              
                color="success"
                variant="contained"
                component="span"
                style={{ color: "white" }}
                onClick={() => {
                    Object.keys(localLayers).map((item) =>
                    setUploadedLocalLayers((prev) => ({
                      ...prev,
                      [item]: localLayers[item],
                    }))
                  );
                  handleModalClose();
                }}
              >
                <Queue /> &nbsp;&nbsp;Yerel Katmanları (
                {Object.keys(localLayers).length.toString()}) Ekle
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      {/* --- Buton Özellikleri --- */}
      <Stack
        sx={{
          position: "absolute",
          left: `0px`,
          // top: 140,
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          boxShadow: 0,
          background: "#fff",
          zIndex: Z_TOOLBAR,
        }}
      >
        <ToolbarButton
          title={t("AddLocalLayer")}
          icon={<Queue />}
          //onClick={handleUploadLayer}
          onClick={handleModalOpen}
        />
      </Stack>
    </div>
  );
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: "10px",  
};

export default UploadLayerButton;
