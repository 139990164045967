import { useState, useLayoutEffect } from "react";

export function useScreen() {
	const [screenSize, setScreenSize] = useState({
		isMobile: false,
		width: 1920,
		height: 1080,
	});

	useLayoutEffect(() => {
		const updateScreen = (evt) => {
			let isLandscape = false;
			const orientation =
				(window.screen.orientation || {}).type ||
				window.screen.mozOrientation ||
				window.screen.msOrientation;

			// orientation API supported
			if (orientation) {
				isLandscape = orientation.includes("landscape");
			} else {
				isLandscape = window.innerWidth > window.innerHeight;
			}

			//for getting real device width (for isMobile)
			if (
				!isLandscape ? window.screen.width < 700 : window.screen.height < 700
			) {
				setScreenSize({
					isMobile: true,
					width: window.innerWidth,
					height: window.innerHeight,
					isLandscape,
				});
			} else {
				setScreenSize({
					isMobile: false,
					width: window.innerWidth,
					height: window.innerHeight,
					isLandscape,
				});
			}
		};

		window.addEventListener("resize", updateScreen);
		window.addEventListener("orientationchange", updateScreen);

		// for test environment isMobile should stay false (as in PC)
		if (process.env.NODE_ENV !== "test") {
			updateScreen();
		}

		return () => {
			window.removeEventListener("resize", updateScreen);
			window.removeEventListener("orientationchange", updateScreen);
		};
	}, []);
	return screenSize;
}
