import { useContext, useState } from "react";
import {
	Menu,
	IconButton,
	MenuItem,
	ListItemIcon,
	Divider,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Logout from "@mui/icons-material/Logout";
import FolderIcon from "@mui/icons-material/Folder";
import CategoryIcon from "@mui/icons-material/Category";
import { clearCredentials, getCredentials } from "../utils/auth";
import { redirectUrl } from "../config";
import { TableContext } from "../context/tableContext";
import PermMediaIcon from '@mui/icons-material/PermMedia';
import { changeLanguage } from "i18next";
import { useTranslation } from 'react-i18next';
import trFlag from '../assets/logo/turkey.png'
import enFlag from '../assets/logo/united-kingdom.png'
const UserMenu = ({ handleChangeProject }) => {
	const { t, i18n } = useTranslation();
	const [anchorEl, setAnchorEl] = useState(null);
	const userName = getCredentials()?.userName;
	const open = Boolean(anchorEl);

	const { selectedRow } = useContext(TableContext);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const onClickLanguage = (ln) => {
		changeLanguage(ln)
	}

	const handleClose = () => {
		setAnchorEl(null);
	};

	const logOut = () => {
		window.open("/giris", "_self")
		clearCredentials();
	};

	const redirectProfile = () => {
		window.open(redirectUrl.userMaganerUrl + "/kayit", "_self");
	};

	const redirectCatalog = () => {
		window.open(redirectUrl.catalogUrl);
	};

	const redirectCebs = () => {
		window.open(redirectUrl.cebsUrl);
	};

	return (
		<>
			<IconButton
				size="large"
				variant="contained"
				sx={{
					position: "absolute",
					right: selectedRow ? 350 : 0,
					my: 1,
					mx: 2,
					zIndex: 1,
					color: "black",
				}}
				onClick={handleClick}
				aria-controls={open ? "account-menu" : undefined}
				aria-haspopup="true"
				aria-expanded={open ? "true" : undefined}
			>
				<AccountCircleIcon fontSize="large" />
			</IconButton>
			<Menu
				id="account-menu"
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				onClick={handleClose}
				PaperProps={{
					elevation: 0,
					sx: {
						overflow: "visible",
						filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
						mt: 1.5,
						p: 1,
						fontSize: "10px",
						"&:before": {
							content: '""',
							display: "block",
							position: "absolute",
							top: 0,
							right: 14,
							width: 10,
							height: 10,
							bgcolor: "background.paper",
							transform: "translateY(-50%) rotate(45deg)",
							zIndex: 0,
						},
						"& .MuiMenuItem-root": {
							fontSize: "small",
						},
					},
				}}
				transformOrigin={{ horizontal: "right", vertical: "top" }}
				anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
			>
				<MenuItem onClick={redirectProfile}>
					<ListItemIcon>
						<AccountCircleIcon color="primary" />
					</ListItemIcon>
					{userName}
				</MenuItem>
				<Divider />
				{/* <Typography
					variant="subtitle1"
					align="center"
					sx={{ display: "block", fontSize: "small" }}
				>
					Uygulamalar
				</Typography> */}
				<MenuItem onClick={redirectCatalog}>
					<ListItemIcon>
						<FolderIcon fontSize="small" color="primary" />
					</ListItemIcon>
					{t("Catolog")}
				</MenuItem>
				<MenuItem onClick={redirectCebs}>
					<ListItemIcon>
						<CategoryIcon fontSize="small" color="primary" />
					</ListItemIcon>
					Cebs
				</MenuItem>
				<Divider />
				{/*Dil butonları eklenecek*/}
				<MenuItem sx={{justifyContent:'space-around'}}>
						<IconButton onClick={()=>onClickLanguage("tr")} sx={{paddingLeft:'8px !important'}}><img src={trFlag}  style={{ width: '28px', height: '28px',marginLeft:'5px' }} title={t("ChangeLanguageTr")}></img></IconButton>
						<IconButton onClick={()=>onClickLanguage("en")} sx={{paddingLeft:'8px !important'}}><img src={enFlag}  style={{ width: '28px', height: '28px',marginLeft:'5px' }}  title={t("ChangeLanguageEn")}></img></IconButton>
				</MenuItem>
				<MenuItem onClick={handleChangeProject}>
					<ListItemIcon>
						<PermMediaIcon fontSize="small" color="primary" />
					</ListItemIcon>
					{t("ChangeProject")}
				</MenuItem>
				<MenuItem onClick={logOut}>
					<ListItemIcon>
						<Logout fontSize="small" color="primary" />
					</ListItemIcon>
					{t("SignOut")}
				</MenuItem>
			</Menu>
		</>
	);
};

export default UserMenu;
