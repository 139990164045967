import React, { useState, useEffect, useContext } from "react";
import SvgIcon from "@mui/material/SvgIcon";
import TreeItem, { treeItemClasses } from "@mui/lab/TreeItem";
import { alpha, styled } from "@mui/material/styles";
import { useTranslation } from 'react-i18next';
import {
  LayersOutlined,
  LightMode,
  Visibility,
  VisibilityOffOutlined,
  TableChartOutlined,
  PhotoOutlined,
  Lock,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Input,
  Slider,
  Tooltip,
  Typography,
} from "@mui/material";
import { PANEL_BP_WIDTH } from "../../constants/constants";
import { getServiceDescriptionById } from "../../services/getServiceDescription";
import {
  handleExtentInteraction,
  handlePointerInteraction,
} from "../map/mapView";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import SearchIcon from "@mui/icons-material/Search";
import { LeftPanelContext } from "../../context/leftPanelContext";
import { TableContext } from "../../context/tableContext";
import { MainContext } from "../../context/mainContext";
import { DeleteForever } from "@mui/icons-material";
import { deleteLocalLayerFromIndexDB, getLocalLayers } from "../../services/getField";

export const MinusSquare = (props) => {
  return (
    <SvgIcon
      fontSize="inherit"
      sx={{ width: props.size || 14, height: props.size || 14 }}
      color="info"
      {...props}
    >
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
};
export const PlusSquare = (props) => {
  return (
    <SvgIcon
      fontSize="inherit"
      sx={{ width: props.size || 14, height: props.size || 14 }}
      color="info"
      {...props}
    >
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
};

export const LayerIcon = (props) => {
  return (
    <LayersOutlined
      sx={{
        mr: 0,
        pr: 0,
        width: props.size || 16,
        height: props.size || 16,
      }}
      {...props}
    />
  );
};

export const StyledTreeItem = styled((props) => <TreeItem {...props} />)(
  ({ theme }) => ({
    [`& .${treeItemClasses.content}`]: {
      width: "calc(100% - 20px)",
    },
    [`& .${treeItemClasses.expanded}`]: {
      width: "calc(100% - 20px)",
    },
    [`& .${treeItemClasses.iconContainer}`]: {
      "& .close": {
        opacity: 0.3,
      },
    },
    [`& .${treeItemClasses.group}`]: {
      marginLeft: 15,
      paddingLeft: 7,
      borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
    [`& .${treeItemClasses.label}`]: {
      fontSize: "15px !important",
      textAlign: "left",
    },
  })
);

export default function TreeRenderer({
  nodes,
  dragWidth,
  onToggleChange,
  onOpacityChange,
  toggleLayerTable,
}) {
  const { t, i18n } = useTranslation();
  const [serviceType, setServiceType] = useState();
  const [deleteType, setDeleteType] = useState(); // Yerel Katmanlar için Delete İkonu
  const [isUnauthorizedLayer, setIsUnauthorizedLayer] = useState(false); // Yetkisiz Katman
  const [utilTab, setUtilTab] = useState(false);  

  const {
    serviceDescriptionId,
    setSearchArea,
    setLayerSelect,
    layerSelect,
    isOpenSearchArea,
    setIsOpenSearchArea,
    toggle,
    opacity,
  } = useContext(LeftPanelContext);
  const { uploadedAllLocalLayers,
    setUploadedAllLocalLayers,isOpenLayerTable } = useContext(TableContext);
  const { setLegendPanelSRID } = useContext(MainContext);  

  useEffect(async () => {
    // Kullanıcının katman üzerindeki operationType yetki değerine göre tablo özelliği gelecek
    !nodes?.items && nodes.operationType > 2 && setServiceType(true)
    !nodes?.items && nodes.operationType===null && setDeleteType(true)
    !nodes?.items && nodes.operationType===0 && setIsUnauthorizedLayer(true)
    
    // Eski veersiyonda servis type a göre tablo özelliği ekleniyordu.
    // !nodes?.items &&
    //   getServiceDescriptionById(nodes?.id).then(
    //     (node) =>
    //       [64, 128, 512, 1024].includes(node?.serviceType) &&
    //       setServiceType(true)
    //   );
  }, []);

  return (
    <>
      {Array.isArray(nodes.items) ? (
        <StyledTreeItem
          key={nodes.id}
          nodeId={String(nodes?.id)}
          label={
            <span
              style={{
                fontSize: dragWidth < PANEL_BP_WIDTH ? 13 : 15,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {dragWidth < PANEL_BP_WIDTH && nodes.title.length > 23
                ? nodes.title.slice(0, 20) + "..."
                : nodes.title}
            </span>
          }
          endIcon={<></>}
        >
          {Array.isArray(nodes.items)
            ? nodes.items.map((node) => (
                <TreeRenderer
                  key={node?.id}
                  nodes={node}
                  dragWidth={dragWidth}
                  onToggleChange={onToggleChange}
                  onOpacityChange={onOpacityChange}
                  toggleLayerTable={toggleLayerTable}
                />
              ))
            : null}
        </StyledTreeItem>
      ) : (
        <StyledTreeItem
          key={nodes?.id}
          nodeId={String(nodes?.id)}
          label={
            <Box
              style={{
                /* "> *": { pr: 2 }, */
                display: "inline-flex",
                marginLeft: -9,
                alignItems: "center",
              }}
            >
              {!utilTab ? (
                <>
                {
                  deleteType &&
                <IconButton
                  title={t("RemoveLocalLayer")}
                  onClick={() => {
                    deleteLocalLayerFromIndexDB(nodes.id);
                    let draftLocalLayers = uploadedAllLocalLayers;                    
                    draftLocalLayers.map((item,index) => (item.id == nodes.id && draftLocalLayers.splice(index,1)))
                    setUploadedAllLocalLayers(draftLocalLayers)
                  }}                  
                  sx={{
                    color: "primary",                                   
                    borderRadius:"90%",                    
                  }}
                >
                  <DeleteForever fontSize="small" style={{color:"red"}}  />{" "}
                </IconButton>
                }
                  {toggle?.[nodes?.id] ? (
                    <IconButton
                      title={t("TurnoffLayerVisibility")}
                      onClick={() => {
                        onToggleChange(nodes?.id, false);
                        handleExtentInteraction(false);
                        handlePointerInteraction(false);
                        setSearchArea();
                        setIsOpenSearchArea(false);
                      }}
                      sx={{
                        color: "primary",
                      }}
                      disabled={isUnauthorizedLayer}
                    >
                      
                      {isUnauthorizedLayer 
                        ? <Lock fontSize="small" style={{color:"rgba(0, 0, 0, 0.6)"}}/>
                        : <Visibility fontSize="small" color= "primary"/>
                      }
                    </IconButton>
                  ) : (
                    <IconButton
                    title={t("TurnonLayerVisibility")}
                      onClick={() => {
                        onToggleChange(nodes?.id, true);
                        handleExtentInteraction(false);
                        handlePointerInteraction(false);
                        setSearchArea();
                        setIsOpenSearchArea(false);
                      }}
                      sx={{
						            borderRadius:"90%",
                      }}
                      disabled={isUnauthorizedLayer}
                    >
                      {isUnauthorizedLayer 
                        ? <Lock fontSize="small" style={{color:"rgba(0, 0, 0, 0.6)"}}/>
                        : <VisibilityOffOutlined fontSize="small" style={{color:"rgba(0, 0, 0, 0.6)"}}/>
                      }
                      
                    </IconButton>
                  )}

                  <Typography
                    variant={"h5"}
                    sx={{
                      alignSelf: "center",
                      fontSize: dragWidth < PANEL_BP_WIDTH ? 12 : 13,
                      pb: 0.5,
                      width: "90%",
                      maxHeight: 30,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: "vertical",
                      // whiteSpace: dragWidth < PANEL_BP_WIDTH ? "wrap" : "nowrap",
                      display:
                        dragWidth < PANEL_BP_WIDTH ? "-webkit-box" : "block",
                    }}
                  >
                    {nodes.title}
                  </Typography>
                  {toggle?.[nodes?.id] && ( //+
                    <IconButton onClick={() => setUtilTab(true)} sx={{ mx: 1 }}>
                      <ChevronRightIcon fontSize="small" color="action" />
                    </IconButton>
                  )}
                </>
              ) : (
                toggle?.[nodes?.id] && //+
                utilTab && (
                  <>
                    <IconButton
                      onClick={() => setUtilTab(false)}
                      sx={{ mx: 1 }}
                    >
                      <ChevronLeftIcon fontSize="small" color="action" />
                    </IconButton>
                    <Slider
                      step={10}
                      min={10}
                      max={100}
                      value={opacity?.[nodes?.id] || 100} //+
                      onChange={
                        (evt, val) => onOpacityChange(nodes?.id, val) //+
                      }
                      size="small"
                      aria-label="Opaklık"
                      valueLabelDisplay="off"
                      sx={{ width: 30, mr: 1 }}
                    />
                    <Input
                      size="small"
                      disableUnderline
                      color="primary"
                      startAdornment={
                        <LightMode
                          color="primary"
                          fontSize="13"
                          sx={{ pr: 1 }}
                        />
                      }
                      value={opacity?.[nodes?.id] || 100} //+
                      onChange={(e) => {
                        const value = +e.target.value;
                        onOpacityChange(
                          //+
                          nodes?.id,
                          value < 10 ? 10 : value > 100 ? 100 : value
                        );
                      }}
                      // sx={{ mx: 1, pr: 1 }}
                      inputProps={{
                        step: 10,
                        min: 0,
                        max: 100,
                        type: "number",
                        color: "rgb(65,159,202)",
                        sx: {
                          width: 45,
                          textDecoration: "underline",
                          fontSize: 14,
                          // "&::before": {borderBottom: "none !important"}, "&::after": {borderBottom: "none !important" },
                        },
                      }}
                    />
                    {serviceType && (
                      <>
                        <Tooltip title={t("Table")}>
                          <Checkbox
                            icon={
                              <TableChartOutlined
                                fontSize="small"
                                color="action"
                              />
                            }
                            checkedIcon={
                              <TableChartOutlined
                                fontSize="small"
                                color="primary"
                              />
                            }
                            checked={isOpenLayerTable && nodes?.id === serviceDescriptionId}
                            onChange={() => {
                              toggleLayerTable(nodes?.id);
                              handleExtentInteraction(false);
                              handlePointerInteraction(false);
                              setSearchArea();
                            }}
                            sx={{
                              ":hover": {
                                height: "fit-content",
                                alignSelf: "center",
                                bgcolor: "gray.light",
                              },
                            }}
                          />
                        </Tooltip>
                        <Tooltip title={t("Search")}>
                          <Checkbox
                            icon={
                              <SearchIcon
                                fontSize="small"
                                color="action"
                              ></SearchIcon>
                            }
                            checkedIcon={
                              <SearchIcon
                                fontSize="small"
                                color="primary"
                              ></SearchIcon>
                            }
                            checked={isOpenSearchArea && nodes?.id === serviceDescriptionId}
                            onChange={() => {
                              setIsOpenSearchArea(!isOpenSearchArea);
                              setLayerSelect({
                                serviceDescriptionId: nodes?.id,
                                state: true,
                              });
                              !isOpenLayerTable && toggleLayerTable(nodes?.id);
                              handleExtentInteraction(false);
                              handlePointerInteraction(false);
                              setSearchArea();
                            }}
                            sx={{
                              ":hover": {
                                height: "fit-content",
                                alignSelf: "center",
                                bgcolor: "gray.light",
                              },
                            }}
                          />
                        </Tooltip>
                      </>
                    )}
                    {!deleteType && <Tooltip title={t("Legend")}>
                      <IconButton
                        onClick={() => {
                          setLegendPanelSRID(nodes?.id);
                        }}
                      >
                        <PhotoOutlined fontSize="small" color="action" />
                      </IconButton>
                    </Tooltip>}
                  </>
                )
              )}
            </Box>
          }
        />
      )}
    </>
  );
}
