import { useContext, useEffect, useRef, useState } from "react";
import { olMap } from "../components/map/mapView";
import MapContext from "../components/map/mapContext";
import MapComponent from "../components/map/map";
import SearchAreaToolbar from "../components/searchAreaToolbar";
import SearchModeToolbar from "../components/searchModeToolbar";
import SearchPanel from "../components/searchPanel";
import TablePanel from "../components/tablePanel";
import { PANEL_HEIGHT, PANEL_WIDTH } from "./../constants/constants";
import Signin from "../pages/signin";
import { Skeleton } from "@mui/material";
import { isLogin } from "../utils/auth";
import MeasureToolbar from "../components/measureToolbar";
import PrintButton from "../components/printButton";
import UploadLayerButton from "../components/uploadLayerButton";
import DataToolbar from "../components/dataToolbar";
import { getFieldsWithRelatedData } from "../services/getField";
import UserMenu from "../components/userMenu";
import RigthPanel from "../components/rightPanel";
import ProjectModal from "./project";
import { LeftPanelContext } from "../context/leftPanelContext";
import { TableContext } from "../context/tableContext";
import { MainContext } from "../context/mainContext";
import RightPanelToolbar from "../components/rightPanelActions/rightPanelToolbar";
import IframeClickOnMapButton from "../components/iframeClickOnMapButton";
import { searchMode } from "../config";
import MapCoordinatePickerButton from "../components/mapCoordinatePickerButton";
import MapCoordinatePicker from "../components/mapCoordinatePicker";
// import db from "./../services/database";
import AlertBox from "../components/alertBox/alertBox";
import SearchPanelOpenCloseButton from "../components/SearchPanelOpenCloseButton";
import { initXDB } from "../services/database";

export default function MapApp() {
  const [map, setMap] = useState();
  const {
    isOpenLayerTable,
    setIsOpenLayerTable,
    selectedRow,
    setSelectedRow,
    setFields,
  } = useContext(TableContext);
  const {
    setServiceDescriptionId,
    serviceDescriptionId,
    setLayerSelect,
    layerSelect,
    setIsOpenSearchArea,
    isMapCoordinatePickerOpen,
  } = useContext(LeftPanelContext);
  const {
    isSearchPanelOpen,
    setIsSearchPanelOpen,
    isOpenLoginModal,
    setIsOpenLoginModal,
    setIsOpenProjectModal,
    selectedProject,
    setSelectedProject,
    rightPanelAction,
    setRightPanelAction,
    isIFrame,
  } = useContext(MainContext);

  const [isLoading, setIsLoading] = useState(true);

  const mapRef = useRef();
  olMap.setTarget(mapRef.current);

  useEffect(() => {
    setIsOpenLoginModal(!isLogin());
  }, []);

  useEffect(() => {
    setMap(olMap);
  }, []);

  const toggleLayerTable = (serviceDescriptionId) => {
    setLayerSelect({ serviceDescriptionId: "", state: true });
    if (isOpenLayerTable) {
      setFields([]);
      setIsLoading(true);
      setIsOpenSearchArea(false);
    }

    !isOpenLayerTable &&
      getFieldsWithRelatedData(serviceDescriptionId).then((dbFields) => {
        setFields(dbFields);
        setIsLoading(false);
      });
    // console.log(isOpenLayerTable);
    setSelectedRow();
    setIsOpenLayerTable(!isOpenLayerTable);
    setServiceDescriptionId(serviceDescriptionId);
  };

  const handleModal = () => {
    setIsOpenLoginModal(true);
  };

  const [dragWidth, setDragWidth] = useState(
    localStorage.getItem("dragWidth") || PANEL_WIDTH
  );
  const [dragHeight, setDragHeight] = useState(
    localStorage.getItem("dragHeight") || PANEL_HEIGHT
  );

  const handleProjectModal = async () => {
    if (isLogin() && selectedProject.id === 0) {
      setIsOpenProjectModal(true);
      await initXDB();
      return;
    }
    setIsOpenProjectModal(false);
  };

  useEffect(() => {
    handleProjectModal();
  }, [isOpenLoginModal]);

  useEffect(() => {
    if (isSearchPanelOpen)
      setDragWidth(localStorage.getItem("dragWidth") || PANEL_WIDTH);
    else setDragWidth(0);
  }, [isSearchPanelOpen]);

  const handleChangeProject = () => {
    // setSelectedProject({ id: 0, name: "", bmapid: 0 })
    setIsOpenProjectModal(true);
  };

  return (
    <MapContext.Provider value={{ map }}>
      <SearchPanel
        dragWidth={dragWidth}
        dragHeight={dragHeight}
        onDragWidthChange={setDragWidth}
        toggleLayerTable={toggleLayerTable}
        handleChangeProject={handleChangeProject}
        isSearchPanelOpen={isSearchPanelOpen}
      />

      {(selectedRow || Object.keys(rightPanelAction).length !== 0) && (
        <RigthPanel />
      )}

      {isOpenLayerTable && layerSelect.state && !isLoading && (
        <TablePanel
          dragWidth={dragWidth}
          dragHeight={dragHeight}
          onDragHeightChange={setDragHeight}
          serviceDescriptionId={serviceDescriptionId}
        />
      )}
      {!isIFrame && <SearchModeToolbar dragWidth={dragWidth} />}
      <RightPanelToolbar />

      {searchMode === "kgm" && <IframeClickOnMapButton dragWidth={dragWidth} />}

      <MapCoordinatePickerButton dragWidth={dragWidth} />
      <PrintButton dragWidth={dragWidth} />
      {/* <UploadLayerButton dragWidth={dragWidth} /> */}
      <MeasureToolbar dragWidth={dragWidth} />

      <DataToolbar dragWidth={dragWidth} />
      {/* <DownloadLayerButton dragWidth={dragWidth} /> */}

      <SearchAreaToolbar dragWidth={dragWidth} dragHeight={dragHeight} />
      <ProjectModal />
      {/* <Signin /> */}

      {isMapCoordinatePickerOpen && <MapCoordinatePicker />}

      <PrintButton dragWidth={dragWidth} />

      <SearchPanelOpenCloseButton
        dragWidth={dragWidth}
        isSearchPanelOpen={isSearchPanelOpen}
        setIsSearchPanelOpen={setIsSearchPanelOpen}
      />

      <AlertBox />

      {!isIFrame && <UserMenu handleChangeProject={handleChangeProject} />}
      <MapComponent
        map={map}
        mapRef={mapRef}
        dragWidth={dragWidth}
        dragHeight={dragHeight}
      >
        {/* map components */}
      </MapComponent>
    </MapContext.Provider>
  );
}
