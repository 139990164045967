import { Visibility } from "@mui/icons-material";
import { VisibilityOff } from "@mui/icons-material";
import { Button } from "@mui/material";
import { Grid } from "@mui/material";
import { handleListItemLayersFWD, handleListItemLayersIRI, handleListItemLayersSKS, handleOnClickLayerButton } from "./rightPanelUtils/rightPanelMeasurementFunctions";

export const RightPanelMeasurementSKSListButton = ({  
  listObject,
  measurementFilesLayers,
  setMeasurementFilesLayers,
  searchValue
}) => {  
  return (
    <div>
          <Button
            variant="outlined"
            color={
              measurementFilesLayers[listObject.id]?.isSKSLayerOpen
                ? "success"
                : "error"
            }
            sx={{ ...buttonStyles }}
            onClick={() => {
              handleListItemLayersSKS(
                listObject?.id,                
                measurementFilesLayers,
                setMeasurementFilesLayers
              );
              handleOnClickLayerButton(
                "SKS-layer",
                listObject?.id,
                measurementFilesLayers,
                setMeasurementFilesLayers,
                undefined,
                undefined,
                searchValue
                
              );
            }}
            endIcon={
              !measurementFilesLayers[listObject.id]?.isSKSLayerOpen ? (
                <VisibilityOff
                  style={{ ...iconInbuttonStyles }}
                ></VisibilityOff>
              ) : (
                <Visibility style={{ ...iconInbuttonStyles }}></Visibility>
              )
            }
          >
            SKS
          </Button>
    </div>
  );
};

const buttonStyles = {
  height: "2em",
  width: "100%",
  mt: 0.5,
};

const iconInbuttonStyles = {
  height: "0.75em",
  width: "auto",
};
