import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { defaultLanguage } from './config';
const languages = ["tr","en"];

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: defaultLanguage,
    lng:defaultLanguage,
    debug: false,
    react:{

      useSuspense: false,
    },
    interpolation: {
      escapeValue: false,
    }
  });
export default i18n;
