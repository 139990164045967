import Dexie from "dexie";

const dbName = "ViewerProduct-webgis";
const db = new Dexie(dbName);

export const getAllServiceDescription = async () => {
	await db.open();
	try {
		const table = db.table("ServiceDescription");
		const data = await table.toArray();

		return data;
	} catch (err) {
		throw err;
	}
};

export const getServiceDescriptionById = async (id) => {
	
	await db.open();
	try {
		const table = db.table("ServiceDescription");
		const data = await table.get(parseInt(id));
		return data;
	} catch (err) {
		throw err;
	}
};

export const getServiceDescriptionByServiceType = async (serviceType) => {
	await db.open();
	try {
		const table = db.table("ServiceDescription");
		const data = await table
			.where("serviceType")
			.anyOf(serviceType)
			.sortBy("name");
		return data;
	} catch (err) {
		throw err;
	}
};
