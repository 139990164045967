import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@mui/material/styles";
import { mainTheme } from "./utils/theme";
import { MainContextProvider } from "./context/mainContext";
import { LeftPanelContextProvider } from "./context/leftPanelContext";
import { TableContextProvider } from "./context/tableContext";
import { BrowserRouter, Routes, Route, Redirect } from "react-router-dom";
import Signin from "./pages/signin";
import { MessageServiceContextProvider } from "./context/messageServiceContext";
import i18n from "./i18n";

const Root = () => {

  return (
    <div className="App">
      <React.StrictMode>
        <MainContextProvider>
          <LeftPanelContextProvider>
            <TableContextProvider>
              <MessageServiceContextProvider>
                <ThemeProvider theme={mainTheme}>
                  <BrowserRouter basename={`/`}>
                    <Routes>
                      <Route path={`/`} element={<App />}></Route>
                    </Routes>
                    <Routes>
                      <Route path={`/giris`} element={<Signin />}></Route>
                    </Routes>
                  </BrowserRouter>
                </ThemeProvider>
              </MessageServiceContextProvider>
            </TableContextProvider>
          </LeftPanelContextProvider>
        </MainContextProvider>
      </React.StrictMode>
    </div>
  );
};

ReactDOM.render(<Root />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
