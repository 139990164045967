import Dexie from "dexie";

const dbName = "ViewerProduct-webgis";
const db = new Dexie(dbName);

export const getAllCoordinateSystem = async () => {
	await db.open();

	try {
		const table = db.table("CoordinateSystem");
		return table.toArray();
	} catch (err) {
		throw err;
	}
};

export const getCoordinateSystemByWkid = async (wkid) => {
	await db.open();

	try {
		const table = db.table("CoordinateSystem");
		return table.get(wkid);
	} catch (err) {
		throw err;
	}
};
