import React, { useContext, useEffect, useRef, useState } from "react";
import MapContext from "./mapContext";
import { olMap } from "./mapView";
import CircularProgress from '@mui/material/CircularProgress';
import { TableContext } from "../../context/tableContext";
import { useTranslation } from 'react-i18next';

const MapComponent = ({ children, map, mapRef, dragWidth, dragHeight }) => {
	const { t, i18n } = useTranslation();
	const { isOpenLayerTable } = useContext(TableContext)
	useEffect(()=>{
		if(map){
			map.getControls().getArray()[1].placeholder_ = t("Coordinates");
		}
	},[map, i18n.language])
	return (
		<div ref={mapRef} className="ol-map">
			{children}
			<CircularProgress
				id="progress1"
				sx={{ ...circleStyles, left: `${parseInt(dragWidth) + 5}px`, bottom: isOpenLayerTable ? `${parseInt(dragHeight) + 5}px` : 50 }}
				size={35}
				color="primary" />

		</div>
	);
};

const circleStyles = {
	position: "absolute",
	zIndex: 100
}

export default MapComponent;
