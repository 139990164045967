import {
  userApiUrl,
  appName,
  checkTokenValidityApi,
  getTokenDetailsApi,
} from "../config";
import axios from "axios";

// ⚠️ kullanıcı login olduğunda cookie ye token kayıt olur. Local storedan değil cookie den tokena erişilecek

export const clearCredentials = () => {
  const userId = localStorage.getItem(appName + ".LastUserId");
  localStorage.removeItem(appName + ".Credentials");
  axios
    .get(userApiUrl + "remove-token", {
      params: { t: getToken(),i:userId },
      withCredentials: true,
    })
    .then((res) => window.location.reload(true))
    .catch((err) => {
      throw err;
    });
  // window.open("/giris", "_self");
};

export const getCredentials = () => {
  let credentials = JSON.parse(localStorage.getItem(appName + ".Credentials"));

  if (credentials) {
    return credentials;
  } else {
    return (credentials = undefined);
  }
};

export const saveCredentials = (credentials) => {
  localStorage.setItem(appName + ".Credentials", JSON.stringify(credentials));
};

export const getToken = () => {
  const decodedCookies = decodeURIComponent(document.cookie);
  const cookies = decodedCookies.split(";");
  const credentials = cookies
    .map((cookie) => cookie.split("="))
    .find((x) => x[0] === "_pst_");
  const token = credentials?.[1];
  return token;
};

export const isLogin = () => {
  const token = getToken();

  if (token) {
    return true;
  } else {
    return false;
  }
};

export const validateToken = async (token) => {
  if (token) {
    try {
      const url = checkTokenValidityApi + token;
      axios(url)
        .then((res) => {
          localStorage.setItem(appName + ".LastUserId", parseInt(res.data));
          
          const url = getTokenDetailsApi + token;
          axios(url).then((res) => {            
            sessionStorage.setItem(appName + ".UserRoles", res.data?.userRoles);            
          });
          
        })
        .catch((err) => {});
    } catch (error) {
      throw error;
    }
  }
};

export const login = () => {
  const islogin = isLogin();
  const token = getToken();

  if (islogin) {
    validateToken(token);
  } 
};
