import { createTheme } from "@mui/material/styles";
import {
	lightBlue,
	cyan,
	deepOrange,
	grey as gray,
} from "@mui/material/colors";
import { trTR } from "@mui/material/locale";

export const mainTheme = createTheme(
	{
		typography: {
			fontFamily:
				"-apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif",
			fontSize: 14,
			h4: {
				fontFamily: "Roboto",
				fontSize: 24,
				paddingTop: 4,
				paddingBottom: 12,
			},
			h5: {
				fontFamily: "Roboto",
				fontSize: 18,
				paddingTop: 4,
				paddingBottom: 12,
			},
			h6: {
				fontFamily: "Roboto",
				fontSize: 14,
				paddingTop: 4,
				paddingBottom: 12,
			},
		},
		palette: {
			primary: {
				light: lightBlue[200],
				main: lightBlue[400],
				dark: lightBlue[600],
			},
			secondary: {
				light: deepOrange[200],
				main: deepOrange[400],
				dark: deepOrange[600],
			},
			cyan: {
				light: cyan[200],
				main: cyan[400],
				dark: cyan[600],
			},
			gray: {
				light: gray[300],
				main: gray[400],
				dark: gray[600],
			},
			white: {
				main: "#fff",
			},
		},
		breakpoints: {
			values: {
				xs: 1, // default: 0
				sm: 700, // default: 600
				md: 960, // default: 960
				lg: 1280, // default: 1280
				xl: 1920, // default: 1920
			},
		},
	},
	trTR
);
