import Dexie from "dexie";

const dbName = "ViewerProduct-webgis";
const db = new Dexie(dbName);

export const getServiceLegendBySid = async (serviceDescriptionId) => {
	await db.open();
	try {
		const table = db.table("ServiceLegend");
		const data = await table.get({ sid: serviceDescriptionId });
		return data;
	} catch (err) {
		throw err;
	}
};
