import React, { useState, useEffect, useContext } from "react";
import { Autocomplete, TextField, InputAdornment } from "@mui/material";
import { roadApi, map, kgmCatalogLayers } from "../../../config";
import axios from "axios";
import {
  deleteLayer,
  flashLayer,
  getLayer,
} from "../../../utils/getFeatureLayer";
import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { LeftPanelContext } from "../../../context/leftPanelContext";
import { Clear, Inventory2, LegendToggle } from "@mui/icons-material";
import { FormControl } from "@mui/material";
import { RadioGroup } from "@mui/material";
import { FormControlLabel } from "@mui/material";
import { Radio } from "@mui/material";
import { Button } from "@mui/material";
import { getServiceDescriptionById } from "../../../services/getServiceDescription";
import {
  addWMSLayer,
  handlePointerInteraction,
  pointerInteraction,
  olMap,
} from "../../map/mapView";
import { ShowChart } from "@mui/icons-material";
import { MainContext } from "../../../context/mainContext";
import { Visibility } from "@mui/icons-material";
import { VisibilityOff } from "@mui/icons-material";
import { ButtonGroup } from "@mui/material";
import { Image } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { Avatar } from "@mui/material";
import { Modal } from "@mui/material";
import { getServiceLegendBySid } from "../../../services/getServiceLegend";
import { Close } from "@mui/icons-material";
import { Search } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { RIGHT_PANEL_ACTIONS } from "../../../constants/constants";
import { useTranslation } from 'react-i18next';
import { Tooltip } from "@mui/material";
import { removeAllPMSAnalysisLayers } from "../../rightPanelActions/rightPanelPMSAnalysis/rightPanelPMSAnalysisLayers";
import removeAllRightPanelMeasurementLayers from "../../rightPanelActions/rightPanelMeasurementComponents/rightPanelUtils/rightPanelMeasurementFunctions";
import { removeInventoryLayer } from "../../rightPanelActions/rightPanelInventoryComponents/rightPanelInventoryData";

export default function UnstyledSelectsMultiple({ title }) {
  const {
    rightPanelAction,
    setRightPanelAction,
    isRightOverlayOpen,
    setIsRightOverlayOpen,
    setRightPanelQueryOnMap,
    rightPanelQueryOnMap,
    isIFrame,
    iFrameRegion,
    setIFrameRegion,
  } = useContext(MainContext);
  const {
    searchValue,
    setSearchValue,
    isInventoryCommonLayerOpen,
    setIsInventoryCommonLayerOpen,
  } = useContext(LeftPanelContext);
  const { t, i18n } = useTranslation();

  const [region, setRegion] = useState([]);
  const [kkno, setKKNO] = useState([]);

  const [selectedDistrictNo, setSelectedDistrictNo] = useState();

  const [selectedKKNO, setSelectedKKNO] = useState();
  const [selectedKKNOData, setSelectedKKNOData] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [direction, setDirection] = useState(1);
  const [isInventoryCutLayerOpen, setIsInventoryCutLayerOpen] = useState(false);

  const [legendModalOpen, setLegendModalOpen] = useState(false);
  const [legendInfo, setLegendInfo] = useState({});

  const ReportButtonTitle =  t("ReportButtonExplain");
  const InventorySectionButtonTitle = t("SectionButtonExplain")
  const MeasurementButtonTitle = t("MeasurementButtonExplain")
  

  useEffect(() => {
    axios
      .get(roadApi.bolgeUrl)
      .then((res) => setRegion(res.data))
      .catch((err) => {
        throw err;
      });
  }, []);

  const handleChange = async (val, title) => {
    setRightPanelAction({});
    deleteLayer("inventory-cut-layer");
    setIsInventoryCutLayerOpen(false);
    deleteLayer("inventory-common-layer");
    setIsInventoryCommonLayerOpen(false);
    setIsLoading(true);
    if (title === "region") {
      setSelectedKKNO();
      if (val) {
        axios
          .get(
            roadApi.bolgeUrl + "/" + val?.id + "/simple-geometry/" + map.wkid
          )
          .then((res) => {
            getLayer(res.data, val?.ba, "yol-layer");
            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
            throw err;
          });
        axios
          .get(roadApi.karayoluUrlAll + val?.bn)
          .then((res) => setKKNO(res.data))
          .catch((err) => {
            throw err;
          });
        setSearchValue((prev) => ({
          ...prev,
          kkno: undefined,
          [title]: val?.bn,
        }));
      } else {
        setSearchValue({ ...searchValue, region: undefined });
        setKKNO([]);
        setIsLoading(false);
      }
    }
    if (title === "kkno") {
      setSelectedKKNO(val);      
      if (val) {
        var data = await getServiceDescriptionById(
          kgmCatalogLayers.kgmKarayolLayer
        );

        deleteLayer("yol-layer");
        data.visible = true;
        var layer = addWMSLayer(data, "id=" + val?.id);
        layer.className_ = "yol-layer";
        const layerList = olMap.getLayers();
        layer && layerList.push(layer);

        // To Layer Flash
        axios
          .get(roadApi.karayoluUrl + val?.id + "/simple-geometry/" + map.wkid) //
          .then((res) => {
            setSelectedKKNOData(res.data);
            flashLayer(res.data);
            // getLayer(res.data, val?.ba, "yol-layer"); //Vector Layer Oluşturur
            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
            throw err;
          });

        setSearchValue((prev) => ({ ...prev, [title]: val?.id }));
      } else {
        setSearchValue((prev) => ({ ...prev, [title]: undefined }));
        setIsLoading(false);
      }
    }
  };

  const getLabel = (option) => {
    if (typeof option === "string") {
      return option;
    } else {
      return (
        option?.ba || option?.kkno + " - " +  (option?.gidis ? "GİDİŞ" : "DÖNÜŞ") + " - " + option?.tnm?.replace(/  +/g, " ")
      );
    }
  };

  const handleOnClickInventoryCut = async (onDirectionChange) => {
    setIsLoading(true);
    if ((selectedKKNO && !isInventoryCutLayerOpen) || onDirectionChange) {
      var data = await getServiceDescriptionById(
        kgmCatalogLayers.kgmInventeroyCutLayer.wmsId
      );
      if (data) {
        data.visible = true;
        var layer = addWMSLayer(
          data,
          "karayolu_id=" + selectedKKNO?.id + " AND istikamet_id=" + (selectedKKNO?.gidis ? 1 : 2)
        );
        layer.className_ = "inventory-cut-layer";
        const layerList = olMap.getLayers();
        layer && layerList.push(layer);
      }
      if (!isInventoryCutLayerOpen) {
        setIsInventoryCutLayerOpen(!isInventoryCutLayerOpen);
      }
    } else {
      deleteLayer("inventory-cut-layer");
      setIsInventoryCutLayerOpen(!isInventoryCutLayerOpen);
    }
    setIsLoading(false);
  };

  const handleOnClickInventoryCommon = async () => {
    setIsLoading(true);

    if (isIFrame) setRightPanelQueryOnMap(false); //iframe olarak yüklenmişse haritada sorgulama kapar

    if (!isInventoryCommonLayerOpen) {
      if (isIFrame) setRightPanelQueryOnMap(true); // iframe olarak yüklenmişse haritada sorgulama açar

      var data = await getServiceDescriptionById(
        kgmCatalogLayers.kgmInventeroyCommonLayer.wmsId
      );
      if (data) {
        data.visible = true;
        var layer = addWMSLayer(data, "bolge_no=" + searchValue?.region,searchValue);
        layer.className_ = "inventory-common-layer";
        const layerList = olMap.getLayers();
        layer && layerList.push(layer);
      }
      if (!isInventoryCommonLayerOpen) {
        setIsInventoryCommonLayerOpen(!isInventoryCommonLayerOpen);
      }
    } else {
      deleteLayer("inventory-common-layer");
      setIsInventoryCommonLayerOpen(!isInventoryCommonLayerOpen);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    removeAllPMSAnalysisLayers();
    removeInventoryLayer();
    removeAllRightPanelMeasurementLayers();
  }, [rightPanelAction]);
  

  return (
    <>
      {!isIFrame && (
        <Autocomplete
        closeText={t("Close")}
        openText= {t("Open")}
          //disableClearable={true}
          clearOnEscape
          clearIcon={
            <Clear
            titleAccess={t("Clean")}
              onClick={() => {
                handleChange("", "region");
                handleChange("", "kkno");
                deleteLayer("yol-layer");
              }}
            />
          }
          forcePopupIcon={true}
          freeSolo
          readOnly={false}
          fullWidth
          id="combo-box-demo"
          options={region}
          sx={{}}
          getOptionLabel={(option) => getLabel(option)}
          isOptionEqualToValue={(option, value) => option.bolgeAd === value}
          value={!searchValue?.region ? "" : null}
          onChange={(evt, v) => handleChange(v, "region")}
          ListboxProps={{
            style: {
              maxHeight: 140,
              border: "1px solid #039be5",
              fontSize: 13,
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <Typography
                      variant="body1"
                      sx={{ fontSize: "14px !important" }}
                    >
                      {t("Region")}:
                    </Typography>
                  </InputAdornment>
                ),
                sx: { fontSize: "13px !important" },
              }}
            />
          )}
        />
      )}

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Autocomplete
        closeText={t("Close")}
        openText= {t("Open")}
          //disableClearable={true}
          clearOnEscape
          clearIcon={
            
            <Clear
            titleAccess={t("Clean")}
              onClick={() => {
                handleChange("", "kkno");
                deleteLayer("yol-layer");
              }}
            />
          }
          forcePopupIcon={searchValue?.["region"] === undefined ? false : true}
          freeSolo
          readOnly={searchValue?.["region"] === undefined ? true : false}
          fullWidth
          id="combo-box-demo"
          options={kkno}
          sx={selectedKKNO ? { width: "90%" } : {}}
          getOptionLabel={(option) => getLabel(option)}
          isOptionEqualToValue={(option, value) => option.ad === value}
          value={!searchValue?.kkno ? "" : null}
          onChange={(evt, v) => handleChange(v, "kkno")}
          ListboxProps={{
            style: {
              maxHeight: 140,
              border: "1px solid #039be5",
              fontSize: 13,
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                sx: { fontSize: "13px !important" },
                readOnly: searchValue?.["region"] === undefined ? true : false,
                startAdornment: (
                  <InputAdornment position="start">
                    <Typography
                      variant="body1"
                      sx={{ fontSize: "14px !important" }}
                    >
                      KKNO:
                    </Typography>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        {selectedKKNO ? (
          <Search
            color="primary"
            style={{ cursor: "pointer", margin: "5px" }}
            onClick={() => flashLayer(selectedKKNOData)}
          />
        ) : (
          ""
        )}
      </div>

      
      <Grid container spacing={1} item marginTop={2} margin={1} style={{justifyItems:"center"}}>
          
          {searchValue?.region && !searchValue?.kkno && <Grid  xs={2}></Grid>}
          {/* Rapor */}
          {searchValue?.region && 
            <Grid  xs={searchValue?.kkno ? 6 : 8}>
              <Tooltip arrow placement="right" title={ReportButtonTitle} >
                <Button
                  variant="outlined"
                  color={isInventoryCommonLayerOpen ? "secondary" : "primary"}
                  style={{width:"80%"}}
                  endIcon={
                    <div style={{display: "flex"}}>
                        <Avatar
                          onClick={() => {
                            handleOnClickInventoryCommon();
                          }}
                          sx={{
                            backgroundColor: "white !important",
                          }}
                        >
                          {isInventoryCommonLayerOpen ? (
                            <Visibility
                              color="secondary"
                            ></Visibility>
                          ) : (
                            <VisibilityOff
                              color="primary"
                            ></VisibilityOff>
                          )}
                        </Avatar>
                        <Avatar
                          sx={{
                            backgroundColor: "white !important",
                          }}
                          onClick={async () => {
                            await getServiceLegendBySid(
                              kgmCatalogLayers.kgmInventeroyCommonLayer.wmsId
                            ).then((legend) => legend && setLegendInfo(legend));
                            setLegendModalOpen(true);
                          }}
                        >
                          <Image
                            color="primary"
                          />
                        </Avatar>
                    </div>}
                >
                   {t("Report")}
                </Button>
              </Tooltip>
            </Grid>
          }
          {searchValue?.region && !searchValue?.kkno && <Grid  xs={2}></Grid>}

          {/* Kesim */}
          {searchValue?.kkno && 
            <Grid  xs={6}>
              {/* Kesim */}
              <Tooltip arrow placement="right" title={InventorySectionButtonTitle} >
                <Button
                  variant="outlined"
                  color={isInventoryCutLayerOpen ? "secondary" : "primary"}
                  style={{width:"80%"}}
                  endIcon={
                    <div style={{display:"flex"}}>
                      <Avatar
                        onClick={() => handleOnClickInventoryCut(false)}
                        sx={{
                          backgroundColor: "white !important",
                        }}
                      >
                        {isInventoryCutLayerOpen ? (
                          <Visibility
                            color="secondary"
                          ></Visibility>
                        ) : (
                          <VisibilityOff
                            color="primary"
                          ></VisibilityOff>
                        )}
                      </Avatar>
                      <Avatar
                        sx={{
                          background: "white !important",
                        }}
                        onClick={async () => {
                          await getServiceLegendBySid(
                            kgmCatalogLayers.kgmInventeroyCutLayer.wmsId
                          ).then((legend) => legend && setLegendInfo(legend));
                          setLegendModalOpen(true);
                        }}
                      >
                        <Image
                          color="primary"
                        />
                      </Avatar>
                    </div>
                  }
                >
                  {t("Section")}
                </Button>
              </Tooltip>
            </Grid>
          }

          {/* Ölçüm */}
          {searchValue?.kkno && <Grid  xs={2}></Grid>}
          {searchValue?.kkno && 
            <Grid  xs={8}>
              <Tooltip arrow placement="right" title={MeasurementButtonTitle} >
              <Button
                variant="outlined"
                color={
                  rightPanelAction?.title === RIGHT_PANEL_ACTIONS[1]
                    ? "secondary"
                    : "primary"
                }
                style={{width:"80%",marginTop:"0.5em"}}
                onClick={async () => {
                  if (Object.keys(rightPanelAction).length === 0) {
                    await setRightPanelAction({
                      title: RIGHT_PANEL_ACTIONS[1],
                      kkno: selectedKKNO,
                      kknoData: selectedKKNOData,
                      direction: selectedKKNO?.gidis ? 1 : 2,
                    });
                  } else {
                    await setIsRightOverlayOpen(false);
                    await handlePointerInteraction(false);
                    await setRightPanelAction({});
                  }
                }}
                endIcon={<Avatar
                  sx={{
                    backgroundColor: "white !important",
                  }}
                >
                  <ShowChart
                    color={
                      rightPanelAction?.title === RIGHT_PANEL_ACTIONS[1]
                        ? "secondary"
                        : "primary"
                    }
                  ></ShowChart>
                </Avatar>}
              >
                {t(RIGHT_PANEL_ACTIONS[1])}
              </Button>
            </Tooltip>
            </Grid>
          }
          {searchValue?.kkno && <Grid  xs={2}></Grid>}

          {/* Envanter Tabaka */}
          {searchValue?.region && 
            <Grid  xs={6}>
              <Tooltip arrow placement="right" title={MeasurementButtonTitle} >
              <Button
                variant="outlined"
                color={
                  rightPanelAction?.title === RIGHT_PANEL_ACTIONS[2]
                    ? "secondary"
                    : "primary"
                }
                style={{width:"80%",marginTop:"0.5em"}}
                onClick={async () => {
                  if (Object.keys(rightPanelAction).length === 0) {
                    await setRightPanelAction({
                      title: RIGHT_PANEL_ACTIONS[2],
                      kkno: selectedKKNO,
                      kknoData: selectedKKNOData,
                      direction: selectedKKNO?.gidis ? 1 : 2,
                    });
                  } else {
                    await setIsRightOverlayOpen(false);
                    await handlePointerInteraction(false);
                    await setRightPanelAction({});
                  }
                }}
                endIcon={<Avatar
                  sx={{
                    backgroundColor: "white !important",
                  }}
                >
                  <Inventory2
                    color={
                      rightPanelAction?.title === RIGHT_PANEL_ACTIONS[2]
                        ? "secondary"
                        : "primary"
                    }
                  ></Inventory2>
                </Avatar>}
              >
                {t(RIGHT_PANEL_ACTIONS[2])}
              </Button>
            </Tooltip>
            </Grid>
          }

          {searchValue?.region && 
            <Grid  xs={6}>
              <Tooltip arrow placement="right" title={MeasurementButtonTitle} >
              <Button
                variant="outlined"
                color={
                  rightPanelAction?.title === RIGHT_PANEL_ACTIONS[3]
                    ? "secondary"
                    : "primary"
                }
                style={{width:"80%",marginTop:"0.5em"}}
                onClick={async () => {
                  if (Object.keys(rightPanelAction).length === 0) {
                    await setRightPanelAction({
                      title: RIGHT_PANEL_ACTIONS[3],
                      kkno: selectedKKNO,
                      kknoData: selectedKKNOData,
                      direction: selectedKKNO?.gidis ? 1 : 2,
                    });
                  } else {
                    await setIsRightOverlayOpen(false);
                    await handlePointerInteraction(false);
                    await setRightPanelAction({});
                  }
                }}
                endIcon={<Avatar
                  sx={{
                    backgroundColor: "white !important",
                  }}
                >
                  <LegendToggle
                    color={
                      rightPanelAction?.title === RIGHT_PANEL_ACTIONS[3]
                        ? "secondary"
                        : "primary"
                    }
                  ></LegendToggle>
                </Avatar>}
              >
                {t(RIGHT_PANEL_ACTIONS[3])}
              </Button>
            </Tooltip>
            </Grid>
          }
        </Grid>



      {isLoading && (
        <Box sx={{ mt: 1 }}>
          {" "}
          <CircularProgress />
        </Box>
      )}
      {legendModalOpen && <div
        // open={legendModalOpen}
        // onClose={() => setLegendModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={{ ...modalBoxStyle }}>
          <IconButton
            sx={{
              position: "absolute",
              right: "0",
              marginRight: "5px",
              marginTop: "5px"
            }}
            onClick={() => setLegendModalOpen(false)}
          >
            <Close></Close>
          </IconButton>
          <img
            src={legendInfo.lgnd}
            width="100%"
            height="auto"
            alt="legend-1"
            loading="lazy"
            style={{ objectFit: "cover" }}
          />
        </Box>
      </div>}
    </>
  );
}

// const buttonStyles = {
//   justifyContent: "center !important",
//   fontSize: "14px",
//   fontWeight: "bold",
//   alignItems: "center",
//   m: 0,
// };

const modalBoxStyle = {
  position: "absolute",
  top: "0%",
  left: "10%",
  width: 300,
  height: "auto",
  backgroundColor: "white",
  bordeRadius: "10px !important",
  boxShadow: 24,
  p: 4,
  boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
};
