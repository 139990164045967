// Verilen bir tree yi dönerek içerisindeki tüm katmanları objeler halinde liste içerisinde return eder
export const getAllLayersFromTree = (tree) => {
  var currentAllSelectedNode = [tree.id];
  var treeLayers = [];

  // Treede bulunan Tüm Katmanları Dönen Döngü
  const getItem = (data) => {
    if (data?.items) {
      if (currentAllSelectedNode.includes(data?.id)) {
        data.items.map((x) => {
          currentAllSelectedNode.push(x?.id);
          if (x.items) {
            getItem(x);
          } else {
            treeLayers.push(x);
          }
        });
      }
    }
  };
  getItem(tree);
  return treeLayers;
};
