//* KULLANICI KATMAN İŞLEMLERİNİ KAYIT EDER
import { getCredentials } from "./auth";
import { appName } from "../config";
export const setSessionLayer = (layers, opacity) => {
	const user = JSON.parse(localStorage.getItem(appName + ".LastUserId"));
	const prevUser = getSessionLayer();
	if (user) {
		localStorage.setItem(
			"Layers",
			btoa(JSON.stringify({ ...prevUser.toggle, [user]: layers }))
		);
		localStorage.setItem(
			"Opacity",
			btoa(JSON.stringify({ ...prevUser.opacity, [user]: opacity }))
		);
	}
};

export const getSessionLayer = () => {
	let layers = localStorage.getItem("Layers");
	let opacity = localStorage.getItem("Opacity");
	if (layers) {
		layers = JSON.parse(atob(layers));
	}

	if (opacity) {
		opacity = JSON.parse(atob(opacity));
	}

	return {
		toggle: layers,
		opacity: opacity,
	};
};
