import { Box, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { useTranslation } from 'react-i18next';
const DisplayRow = ({ selectedRow, fields, setSelectedRow }) => {
  const { t, i18n } = useTranslation();
  if (selectedRow.status === "table") {
    return (
      <Box sx={{ width: "100%", mt: 1, p: 1 }}>
        <Table sx={{ width: "95%" }}>
          <TableBody>
            {fields.map(
              (field, index) =>
                (field.alias
                  ? field.alias !== "Shape"
                  : field.fieldName !== "shape") && (
                  <TableRow
                    sx={{
                      backgroundColor: index % 2 === 0 && "rgba(0, 0, 0, 0.04)",
                    }}
                    key={index}
                  >
                    <TableCell
                      variant="head"
                      sx={{
                        fontWeight: "bold",
                        p: 1,
                        borderRight: "1px solid rgba(224, 224, 224, 1)",
                      }}
                    >
                      {field.alias ? field.alias : field.fieldName}
                    </TableCell>
                    <TableCell sx={{ p: 1 }}>
                      {selectedRow?.data[field.fieldName]
                        ? selectedRow?.data[field.fieldName]
                        : "-"}
                    </TableCell>
                  </TableRow>
                )
            )}
          </TableBody>
        </Table>
      </Box>
    );
  } else if (selectedRow.status === "parcel") {
    const columns = [
      { title: "tzref", value: t("DeedFloorNumber") },
      { title: "nitelik", value: t("Qualification") },
      { title: "talan", value: t("DeedArea") },
      { title: "kalan", value: t("CadastralArea") },
      { title: "ilce", value: t("District") },
      { title: "mahalle", value: t("Neighborhood") },
    ];
    return (
      <Box sx={{ width: "100%", mt: 1, p: 1 }}>
        <Table sx={{ width: "95%" }}>
          <TableBody>
            {columns.map((item, index) => (
              <TableRow
                sx={{
                  backgroundColor: index % 2 === 0 && "rgba(0, 0, 0, 0.04)",
                }}
                key={index}
              >
                <TableCell
                  variant="head"
                  sx={{
                    fontWeight: "bold",
                    p: 1,
                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                  {item?.value}
                </TableCell>
                <TableCell sx={{ p: 1 }}>
                  {["talan", "kalan"].includes(item.title)
                    ? selectedRow?.data[item.title]?.toFixed(2) + " m2"
                    : selectedRow?.data[item.title]}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    );
  }
};

export default DisplayRow;
