import Dexie from "dexie";
import { identityTransform } from "ol/proj";

const dbName = "ViewerProduct-webgis";
const db = new Dexie(dbName);
db.open();

const convertToType = (value, type) => {
	switch (type) {
		case dataType.Short:
		case dataType.Long:
			return Number(parseInt(value));
		case dataType.Double:
			return Number(value);
		case dataType.Text:
			return String(value);
		default:
			return value;
	}
};

export const getFieldsByServiceDescriptionId = async (serviceDescriptionId) => {			
	try {
		const table = db.table("Field");
		const data = await table
			.where("serviceDescriptionId")
			.equals(serviceDescriptionId)
			.sortBy("sequence");
		return data;		
	} catch (err) {
		throw err;
	}
};

export const getFieldsWithRelatedData = async (serviceDescriptionId) => {		
	try {
		serviceDescriptionId = parseInt(serviceDescriptionId);
		const fieldTable = db.table("Field");
		const domainTable = db.table("Domain");
		const domainValueTable = db.table("DomainValue");
		const fields = await fieldTable
			.where("serviceDescriptionId")
			.equals(serviceDescriptionId)
			.sortBy("sequence");

		for (let i = 0; i < fields.length; i++) {
			let field = fields[i];

			if (field.domainId) {
				field.domain = await domainTable.get(field.domainId);
				field.domain.values = [];
				const domainValues = await domainValueTable
					.where("domainId")
					.equals(field.domainId)
					.toArray();

				for (let j = 0; j < domainValues.length; j++) {
					let domainValue = domainValues[j];
					domainValue.key = convertToType(
						domainValue.key,
						field.domain.keyType
					);
					domainValue.value = convertToType(
						domainValue.value,
						field.domain.valueType
					);
					domainValue.foreignKey = convertToType(
						domainValue.foreignKey,
						field.domain.foreignKeyType
					);
					field.domain.values.push(domainValue);
				}
			}

			if (field.foreignFieldId) {
				field.foreignField = await fieldTable.get(field.foreignFieldId);
				field.foreignRelatedDomainData = [];
			}
		}
		return fields;
	} catch (err) {
		throw err;
	}
};


export const addLocalLayerToIndexDB = async (id,title,color,layerObject) => {
	try {
		const table = db.table("LocalLayers");		
		table.add({
			id:id,
			title:title,
			color:color,
			layerObject:layerObject	
		})
		const tableData = await table.toArray();
	} catch (err) {
		throw err;
	}
};

export const deleteLocalLayerFromIndexDB = async (id) => {
	try {
		db.table("LocalLayers").delete(id)
	} catch (err) {
		throw err;
	}
};

export const getLocalLayers = async () => {	
	try {
		const table = db.table("LocalLayers");
		return await table.toArray()		
	} catch (err) {
		throw err;
	}
};

export const getLocalLayersMaxId = async () => {
	try {
		const table = db.table("LocalLayers");
		const tableData = await table.toArray()		
		let id = 10000;
		tableData.forEach(item => item?.id>id ? (id = item.id) : "")
		return id
	} catch (err) {		
		throw err;
	}
};

const dataType = {
	None: -1,
	Short: 0,
	Long: 1,
	Double: 2,
	Text: 3,
};
