import { useState } from "react";
import { Stack, Typography } from "@mui/material";
import { Z_TOOLBAR } from "../constants/constants";
import Timeline from "@mui/icons-material/Timeline";
import ToolbarButton from "./common/toolbarButton";

import SquareFootIcon from "@mui/icons-material/SquareFoot";
import ArchitectureIcon from "@mui/icons-material/Architecture";
import { olMap } from "./map/mapView";
import { cleanInteractions, cleanupDraw } from "../utils/measure";
import MeasureButton from "@terrestris/react-geo/dist/Button/MeasureButton/MeasureButton";
import ToggleGroup from "@terrestris/react-geo/dist/Button/ToggleGroup/ToggleGroup";
import { ImportExport } from "@mui/icons-material";
import { UploadButton } from "@terrestris/react-geo";
import UploadLayerButton from "./uploadLayerButton";
import DownloadLayerButton from "./downloadLayerButton";
import { useTranslation } from 'react-i18next';

const DataToolbar = ({ dragWidth }) => {
	const { t, i18n } = useTranslation();
	const [isOpen, setIsOpen] = useState(false);
	const [toolName, setToolName] = useState("");
	const [deselectAll, setDeselectAll] = useState(false);

	const handleOpen = () => {
		setIsOpen(!isOpen);
	};

	
	return (
		<>
			<Stack
				sx={{
					position: "absolute",
					left: `${dragWidth}px`,
					// left:"500px",
					top: 140,
					borderTopRightRadius: 8,
					borderBottomRightRadius: 0,
					boxShadow: 0,
					background: "#fff",
					zIndex: Z_TOOLBAR,
				}}
			>
				<ToolbarButton								
					title={t("DataTools")}
					icon={<ImportExport />}
					onClick={handleOpen}
				/>
			</Stack>
			{isOpen && (
				<Stack
					sx={{
						...dataTabStyles,
						left: `${+dragWidth + 59}px`,
						zIndex: Z_TOOLBAR,
					}}
				>
					<ToggleGroup
						className="measureToggleGroupStyles"
						selectedName={toolName}
						onChange={(props) => {
							props.name !== toolName ? setToolName(props.name) : setToolName();
						}}
						deselectAll={deselectAll}
						orientation="horizontal"
					>
						<UploadLayerButton ></UploadLayerButton>
						<DownloadLayerButton></DownloadLayerButton>
					</ToggleGroup>
				</Stack>
			)}
		</>
	);
};

const dataTabStyles = {
	position: "absolute",
	top: 140,
	backgroundColor: "#fff",
	borderRadius: 0,
	boxShadow: 3,
	width: 60,
};

export default DataToolbar;

