import { CropSquare } from "@mui/icons-material";
import { HighlightAlt } from "@mui/icons-material";
import { WrongLocation } from "@mui/icons-material";
import { AddLocationAlt } from "@mui/icons-material";
import { AdbSharp } from "@mui/icons-material";
import { Edit } from "@mui/icons-material";
import { Button, ButtonGroup, Fab } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import {
  extentInteraction,
  handleExtentInteraction,
  handlePointerInteraction,
  olMap,
  pointerInteraction,
  visibleLayer,
} from "../components/map/mapView";
import {
  deleteLayer,
  deleteLayerByAWordIn,
  flashLayer,
  getLayer,
  getLayerNoZoom,
} from "../utils/getFeatureLayer";
import { getFeatureByWhereClause } from "../utils/webgisApiUtils/getFeatureByWhereClause";
import { getServiceDescriptionById } from "../services/getServiceDescription";
import WKT from "ol/format/WKT";
import Point from "ol/geom/Point";
import Polygon, { fromExtent } from "ol/geom/Polygon";
import { baseLayerApiUrl, dataSourceApiUrl, map, megsisIntegrationApiUrl } from "../config";
import axios from "axios";
import VectorSource from "ol/source/Vector";
import { sendWindowMessage } from "./messageServiceContext";
import PointerInteraction from "ol/interaction/Pointer";
import { EditLocation } from "@mui/icons-material";
import { EditLocationAlt } from "@mui/icons-material";
import { MainContext } from "./mainContext";
import { LeftPanelContext } from "./leftPanelContext";
import Draw, { createBox, createRegularPolygon } from "ol/interaction/Draw";
import VectorLayer from "ol/layer/Vector";
import Interaction from "ol/interaction/Interaction";
import { getToken } from "../utils/auth";
import GeoJSON from "ol/format/GeoJSON";

var draftIslemType = null;
var draftSelectedData = null;
var draftKomsuAda = null;
var draftTasariParselRefs = null;
var catalogLayers = {};

axios?.get("/islemType.json").then((res) => {
  draftIslemType = res?.data?.islemType;
  draftSelectedData = res?.data?.selectedData;
  draftKomsuAda = res?.data?.komsuAda;
  draftTasariParselRefs = res?.data?.tasariParselRefs
  catalogLayers = res?.data?.catalogLayers;
});

const megsisIslemResponse = "megsisIslemResponse";

const megsisIslemResponseExtentMessage = "Extent";
const megsisIslemResponseAddedDataMessage = "AddedData";
const megsisIslemResponseRemovedDataMessage = "RemovedData";
const megsisIslemResponseRemoveAllDataMessage = "AllDataRemoved";

const parselLayerName = "parselLayer";

// const komsuParsel = "komsuParsel"; // parsel geometri check servisini çalıştırır
const komsuAda = "komsuAda"; // parsel geometri check isleminde komsu ada kuralını da ekler
const tasariParselRefs = "tasariParselRefs"; // parsel geometri check islemi oncesi tasari parselleri
const tasariParselColor = "rgb(0,212,255)";

const extentLayerClassName = "megsis-extent";
const extentInteractionClassName = "megsis-extent-interaction";
const extentLayerStyleName = "draft-polygon";
const islemLayerStyleName = "draft-islem-polygon";

const MegsisMessageServiceIslemButtons = ({ request }) => {
  const { setAlertBoxSituation } = useContext(MainContext);
  const { setToggle } = useContext(LeftPanelContext);

  const drawInteractionRef = useRef(null);

  const [selectedButton, setSelectedButton] = useState(null);
  const [extent, setExtent] = useState({});
  const [extentCheck, setExtentCheck] = useState(false);
  const [selectedCatalogLayers, setSelectedCatalogLayers] = useState([]);


  // Check Komsu Parsel Islem..
  const isKomsuAda = request?.parameters?.[komsuAda] || draftKomsuAda || false;
  const selectedTasariParselRefs = request?.parameters?.[tasariParselRefs] || draftTasariParselRefs || false;
  //

  const islemType = draftIslemType || request?.parameters?.islem || "6";

  const selectedData =
    draftSelectedData || request?.parameters?.selectedData || [];

  const isThereExtentRule = ["1", "2", "3", "6"].includes(islemType);
  const isParselAdaSecim = ["4"].includes(islemType);
  const isPaftaSecim = ["5"].includes(islemType);
  const isKomsuParsel = ["7"].includes(islemType) || false;


  const handleClickDrawExtent = () => {
    removeMapInteractions(drawInteractionRef);
    if (selectedButton === 1) {
      setSelectedButton(null);
      setAlertBoxSituation({
        isOpen: true,
        type: "info",
        message: "Sınır çizme aracı kapatıldı!",
      });
    } else {
      setSelectedButton(1);
      setAlertBoxSituation({
        isOpen: true,
        type: "info",
        message: "Sınır çizme aracı çalıştırıldı!",
      });
      openDrawExtentFunc(
        islemType,
        selectedCatalogLayers,
        setExtent,
        drawInteractionRef
      );
    }
  };

  const handleClickSelectOnMap = () => {
    removeMapInteractions();
    if (selectedButton === 2) {
      setSelectedButton(null);
      setAlertBoxSituation({
        isOpen: true,
        type: "info",
        message: "Haritadan seçme/kaldırma aracı kapatıldı!",
      });
    } else {
      setSelectedButton(2);
      setAlertBoxSituation({
        isOpen: true,
        type: "info",
        message: "Haritadan seçme/kaldırma aracı çalıştırıldı!",
      });
      startPointerInteractionForCatalogLayers(
        selectedCatalogLayers,
        extent,
        isThereExtentRule,
        setAlertBoxSituation
      );
    }
  };

  const handleClickSelectParselOnMap = () => {
    removeMapInteractions();
    if (selectedButton === 2) {
      setSelectedButton(null);
      setAlertBoxSituation({
        isOpen: true,
        type: "info",
        message: "Haritadan seçme/kaldırma aracı kapatıldı!",
      });
    } else {
      setSelectedButton(2);
      setAlertBoxSituation({
        isOpen: true,
        type: "info",
        message: "Haritadan seçme/kaldırma aracı çalıştırıldı!",
      });
      startPointerInteractionForCatalogLayers(
        [catalogLayers.parsel],
        extent,
        isThereExtentRule,
        setAlertBoxSituation,
        isKomsuParsel,
        isKomsuAda,
        selectedTasariParselRefs
      );
    }
  };

  const handleClickSelectAdaOnMap = () => {
    removeMapInteractions();
    if (selectedButton === 3) {
      setSelectedButton(null);
      setAlertBoxSituation({
        isOpen: true,
        type: "info",
        message: "Haritadan seçme/kaldırma aracı kapatıldı!",
      });
    } else {
      setSelectedButton(3);
      setAlertBoxSituation({
        isOpen: true,
        type: "info",
        message: "Haritadan seçme/kaldırma aracı çalıştırıldı!",
      });
      startPointerInteractionForCatalogLayers(
        [catalogLayers.ada],
        extent,
        isThereExtentRule,
        setAlertBoxSituation
      );
    }
  };

  const handleClickRemoveOnMap = () => {
    removeAllCatalogLayerVectors(selectedCatalogLayers);
    setAlertBoxSituation({
      isOpen: true,
      type: "info",
      message: "Seçili veriler kaldırıldı",
    });
  };

  useEffect(() => {
    isExtentCheckFunc(extent, setExtentCheck);
  }, [extent]);

  useEffect(() => {
    islemType === "6" && handleClickDrawExtent();
    setSelectedCatalogLayersFunc(
      islemType,
      request?.parameters,
      setSelectedCatalogLayers,
      setToggle
    );
    firstIslemZoomToGeometry(request?.parameters);
  }, []);

  useEffect(() => {
    if (Object.keys(selectedData).length > 0)
      addSelectedDataOnMap(selectedData);
  }, [selectedData]);

  useEffect(() => {
    if (Object.keys(selectedTasariParselRefs).length > 0)
      addSelectedTasariParselRefs(selectedTasariParselRefs);
  }, [selectedTasariParselRefs]);

  return (
    <div style={style}>
      <ButtonGroup aria-label="outlined primary button group">
        <Button variant="text" sx={{ mr: 1 }} disabled>
          Megsis
        </Button>
        {isThereExtentRule && (
          <Fab
            variant="extended"
            color={selectedButton === 1 ? "warning" : "primary"}
            sx={{ mr: 1 }}
            onClick={handleClickDrawExtent}
            disabled={!isThereExtentRule}
          >
            <HighlightAlt sx={{ mr: 1 }} />
            Sınır Çiz
          </Fab>
        )}

        {isThereExtentRule && islemType !== "6" && (
          <Fab
            variant="extended"
            color={selectedButton === 2 ? "warning" : "primary"}
            sx={{ mr: 1 }}
            onClick={handleClickSelectOnMap}
            disabled={!extentCheck || islemType === "6"}
          >
            <EditLocationAlt sx={{ mr: 1 }} />
            Haritada Seç / Kaldır
          </Fab>
        )}

        {(isParselAdaSecim || isKomsuParsel) && (
          <Fab
            variant="extended"
            color={selectedButton === 2 ? "warning" : "primary"}
            sx={{ mr: 1 }}
            onClick={handleClickSelectParselOnMap}
          >
            <EditLocationAlt sx={{ mr: 1 }} />
            Parsel Seç / Kaldır
          </Fab>
        )}

        {isParselAdaSecim &&  (
          <Fab
            variant="extended"
            color={selectedButton === 3 ? "warning" : "primary"}
            sx={{ mr: 1 }}
            onClick={handleClickSelectAdaOnMap}
          >
            <EditLocationAlt sx={{ mr: 1 }} />
            Ada Seç / Kaldır
          </Fab>
        )}

        {isPaftaSecim && (
          <Fab
            variant="extended"
            color={selectedButton === 2 ? "warning" : "primary"}
            sx={{ mr: 1 }}
            onClick={handleClickSelectOnMap}
          >
            <EditLocationAlt sx={{ mr: 1 }} />
            Pafta Seç / Kaldır
          </Fab>
        )}

        {islemType !== "6" && (
          <Fab
            variant="extended"
            // color={selectedButton === 4 ? "warning" : "primary"}
            onClick={handleClickRemoveOnMap}
          >
            <WrongLocation sx={{ mr: 1 }} />
            Seçilenleri Kaldır
          </Fab>
        )}
      </ButtonGroup>
    </div>
  );
};

export default MegsisMessageServiceIslemButtons;

// Buton stilleri
const style = {
  position: "absolute",
  zIndex: "9999",
  backgroundColor: "white",
  padding: "10px",
  borderRadius: "25px",
  right: "5%",
  top: "1%",
};

// harita tıklama işlevlerini kaldırır
const removeMapInteractions = (drawInteractionRef) => {
  olMap?.removeInteraction(drawInteractionRef?.current);
  handlePointerInteraction(false);
};

// seçili katman vector verilerinin tümünü haritadan kaldırır
const removeAllCatalogLayerVectors = (selectedCatalogLayers = []) => {
  selectedCatalogLayers?.map((selectedCatalogLayer) =>
    deleteLayerByAWordIn(selectedCatalogLayer?.name)
  );
  sendWindowMessage(
    megsisIslemResponse,
    megsisIslemResponseRemoveAllDataMessage,
    {}
  );
};

// sınırı çizilen extenti wkt ye çevirir
const extentToWKT = (extent) => {
  var wkt = "POLYGON((";
  wkt = wkt + extent?.minX + " " + extent?.minY + ",";
  wkt = wkt + extent?.minX + " " + extent?.maxY + ",";
  wkt = wkt + extent?.maxX + " " + extent?.maxY + ",";
  wkt = wkt + extent?.maxX + " " + extent?.minY + ",";
  wkt = wkt + extent?.minX + " " + extent?.minY;
  wkt = wkt + "))";
  return wkt;
};

// extentin doğruluğunu inceler
const isExtentCheckFunc = (extent, setExtentCheck) => {
  if (
    !extent.minX ||
    !extent.minY ||
    !extent.maxX ||
    !extent.maxY ||
    extent.minX === extent.maxX ||
    extent.minY === extent.maxY
  )
    setExtentCheck(false);
  else setExtentCheck(true);
};

// haritada katmanları görünür yapar
const visibleAndSetToogleLayer = (layerCatalogId, setToggle) => {
  visibleLayer(parseInt(layerCatalogId));

  setToggle((prev) => ({
    ...prev,
    [parseInt(layerCatalogId)]: true,
  }));
};

// İşlem başlangıcında veriye zoomlama
const firstIslemZoomToGeometry = async (parameters) => {
  var conditionKey = null;
  var conditionValue = null;
  if (parameters?.parselRef) {
    conditionKey = "parsel";
    conditionValue = parameters.parselRef;
  } else if (parameters?.adaNoRef) {
    conditionKey = "ada";
    conditionValue = parameters.adaNoRef;
  } else if (parameters?.mahalleRef) {
    conditionKey = "mahalle";
    conditionValue = parameters.mahalleRef;
  } else if (parameters?.kasabaRef) {
    conditionKey = "kasaba";
    conditionValue = parameters.kasabaRef;
  } else if (parameters?.ilceRef) {
    conditionKey = "ilce";
    conditionValue = parameters.ilceRef;
  }

  if (conditionKey && conditionKey) {
    const primaryColumKey = "ref";

    const zoomGeometry = await getFeatureByWhereClause(
      true,
      [],
      catalogLayers[conditionKey]?.catalogId,
      `${primaryColumKey} = ${conditionValue}`
    );
    if (zoomGeometry?.[0]?.geometry) flashLayer(zoomGeometry?.[0]?.geometry);
  }
};

// startPointerInteractionForCatalogLayers(selectedCatalogLayers);
// İşlem Tipine göre catalog katmanlarının seçilmesi ve haritaya eklenmesi
const setSelectedCatalogLayersFunc = (
  islemType,
  parameters,
  setSelectedCatalogLayers,
  setToggle
) => {
  switch (islemType) {
    case "0":
      setSelectedCatalogLayers([catalogLayers.bolge]);
      visibleAndSetToogleLayer(catalogLayers.bolge.catalogId, setToggle);
      break;
    case "1":
      setSelectedCatalogLayers([catalogLayers.dere]);
      visibleAndSetToogleLayer(catalogLayers.dere.catalogId, setToggle);
      break;
    case "2":
      setSelectedCatalogLayers([catalogLayers.yol]);
      visibleAndSetToogleLayer(catalogLayers.yol.catalogId, setToggle);
      break;
    case "3":
      setSelectedCatalogLayers([catalogLayers.dere, catalogLayers.yol]);
      visibleAndSetToogleLayer(catalogLayers.dere.catalogId, setToggle);
      visibleAndSetToogleLayer(catalogLayers.yol.catalogId, setToggle);
      break;
    case "4":
      setSelectedCatalogLayers([catalogLayers.ada, catalogLayers.parsel]);
      visibleAndSetToogleLayer(catalogLayers.ada.catalogId, setToggle);
      visibleAndSetToogleLayer(catalogLayers.parsel.catalogId, setToggle);
      break;
    case "5":
      setSelectedCatalogLayers([catalogLayers.pafta]);
      visibleAndSetToogleLayer(catalogLayers.pafta.catalogId, setToggle);
      break;
    case "7":
      setSelectedCatalogLayers([catalogLayers.parsel]);
      visibleAndSetToogleLayer(catalogLayers.parsel.catalogId, setToggle);
      break;
    default:
      break;
  }
};

// Haritadan tıklama işlemleri

const isPointInsideExtent = (pointX, pointY, extent) => {
  if (
    pointX > extent?.minX &&
    pointY > extent?.minY &&
    pointX < extent?.maxX &&
    pointY < extent?.maxY
  )
    return true;
  else return false;
};

const startPointerInteractionForCatalogLayers = (
  selectedCatalogLayers,
  extent,
  isThereExtentRule,
  setAlertBoxSituation,
  isKomsuParsel = false,
  isKomsuAda = false,
  selectedTasariParselRefs = []
) => {
  handlePointerInteraction();
  pointerInteraction.listeners_ = [];
  pointerInteraction.on("change:coords", (evt) => {
    // console.log(evt.target.coordinate);
    const coordinates = evt.target.coordinate;
    var isPointİnside;
    if (isThereExtentRule) {
      isPointİnside = isPointInsideExtent(
        coordinates?.[0],
        coordinates?.[1],
        extent
      );
    } else isPointİnside = true;

    if (isPointİnside) {
      selectedCatalogLayers.map((catalogLayer) =>
        fetchDataFromPoint(
          coordinates,
          catalogLayer,
          setAlertBoxSituation,
          "point",
          isKomsuParsel,
          isKomsuAda,
          selectedTasariParselRefs,
        )
      );
    } else {
      setAlertBoxSituation({
        isOpen: true,
        type: "error",
        message: "Lütfen çizilen sınır içerisinden seçim yapınız...",
      });
    }
    handlePointerInteraction(false);
    handlePointerInteraction();
  });
};

const getOptions = async (type, coordinates, catalogLayer) => {
  const service = await getServiceDescriptionById(
    parseInt(catalogLayer.catalogId)
  );
  const options = {};
  options.layerId = 1;
  options.serviceDescriptionId = parseInt(catalogLayer.catalogId);
  options.whereClause = "";
  // options.buffer = 1;
  options.bufferUnit = "m";
  options.tolerance = 0.1; //0.01
  options.geometry =
    type === "polygon"
      ? new WKT().writeGeometry(new Polygon([coordinates]))
      : new WKT().writeGeometry(new Point(coordinates));
  options.geometryColumn = "shape";
  options.wkid = map.wkid;
  options.toWKID = service.wkid;
  options.outputWKID = map.wkid;
  options.returnFields = true;
  options.returnGeometry = true;
  options.spatialRelationType = 5;

  return options;
};

const fetchDataFromPoint = async (
  coordinates,
  catalogLayer,
  setAlertBoxSituation,
  geometryType = "point",
  isKomsuParsel = false,
  isKomsuAda = false,
  selectedTasariParselRefs = [],
) => {

  const postOptions = await getOptions(geometryType, coordinates, catalogLayer);
  axios(dataSourceApiUrl.spatialFilterApiUrl, {
    method: "post",
    data: postOptions,
  })
    .then(async (res) => {
      if (res.data.length > 0) {
        const data = res.data[0];
        const dataAttributesObject = data?.attributes;

        const features = new WKT().readFeatures(data.geometry);

        const source = new VectorSource({
          features: features,
          crossOrigin: "Anonymous",
        });

        const primaryColumKey = catalogLayer.primaryColumKey;
        const titleColumnName = catalogLayer.titleColumnName;
        const color = catalogLayer.color;

        var responseParameters = {};
        responseParameters[primaryColumKey] =
          dataAttributesObject[primaryColumKey];
        responseParameters[titleColumnName] =
          dataAttributesObject[titleColumnName]?.toString();

        // flashLayer(data.geometry)

        const removeLayer = olMap
          .getLayers()
          .getArray()
          .filter((x) => x.className_ === catalogLayer.name + dataAttributesObject[catalogLayer.primaryColumKey]);

        // Check Islem Parsel Geometry ozel durumu

        if (catalogLayer.name === parselLayerName) {

          const mapParselRefLayers = olMap
            .getLayers()
            .getArray()
            .filter((x) => x.className_.includes(catalogLayer.name));

          const selectedParselRefLayers = mapParselRefLayers.map((x) => parseInt(x?.["className_"]?.replace(parselLayerName, "")))

          var draftSelectedParselRefs = selectedParselRefLayers;

          if (isKomsuParsel && selectedTasariParselRefs?.includes(dataAttributesObject[primaryColumKey]?.toString())) {
            setAlertBoxSituation({
              isOpen: true,
              type: "error",
              message: "Lütfen işleme uygun parsel Seç / Kaldır işlemi yapınız!",
            });
            return;
          }
          if (removeLayer.length > 0) {
            draftSelectedParselRefs = draftSelectedParselRefs.filter(item => item !== dataAttributesObject[primaryColumKey])
          }
          else {
            draftSelectedParselRefs.push(dataAttributesObject[primaryColumKey])
          }
          if (
            isKomsuParsel &&
            catalogLayer.name === parselLayerName
          ) {

            var isChecked;
            if (isKomsuAda) isChecked = await checkIslemParselGeometryWKomsuAdaFunc(draftSelectedParselRefs);
            else isChecked = await checkIslemParselGeometryFunc(draftSelectedParselRefs);

            if (!isChecked) {
              setAlertBoxSituation({
                isOpen: true,
                type: "error",
                message: "Lütfen işleme uygun parsel Seç / Kaldır işlemi yapınız!",
              });
              return;
            }
            else {
              // setSelectedParselRefs(draftSelectedParselRefs)
            }
          }
        }

        var isAddedFeature;
        if (removeLayer.length > 0) {
          deleteLayer(catalogLayer.name +
            dataAttributesObject[catalogLayer.primaryColumKey]);
          isAddedFeature = false;
        } else {
          addFeatureToMap(data.geometry,
            dataAttributesObject[catalogLayer.titleColumnName]?.toString(),
            catalogLayer.name +
            dataAttributesObject[catalogLayer.primaryColumKey],
            color)
          isAddedFeature = true;
        }
        setAlertBoxSituation({
          isOpen: true,
          type: "info",
          message: isAddedFeature
            ? "Seçili veri eklendi!"
            : "Seçili veri kaldırıldı!",
        });

        responseParameters["layerName"] = catalogLayer?.name;
        sendWindowMessage(
          megsisIslemResponse,
          isAddedFeature
            ? megsisIslemResponseAddedDataMessage
            : megsisIslemResponseRemovedDataMessage,
          responseParameters
        );
      }
    })
    .catch((err) => {
      throw err;
    });
};



const addFeatureToMap = (geometry, name, className, color) => {
  getLayerNoZoom({
    source: geometry,
    name: name,
    className: className,
    styleName: islemLayerStyleName,
    color: color,
  });
  return true;
}

/////// Extent çizimi için

const openDrawExtentFunc = (
  islemType,
  selectedCatalogLayers,
  setExtent,
  drawInteractionRef
) => {
  const draw = new Draw({
    className: extentInteractionClassName,
    source: null,
    type: "Circle", // İstediğiniz geometri türünü seçebilirsiniz
    geometryFunction: createBox(),
  });

  draw.on("drawend", (event) => {
    const feature = event.feature;
    const geometry = feature.getGeometry();
    const geometryExtent = geometry.getExtent();
    if (
      geometryExtent?.[0] &&
      geometryExtent?.[1] &&
      geometryExtent?.[2] &&
      geometryExtent?.[3] &&
      geometryExtent?.[0] !== geometryExtent?.[2] &&
      geometryExtent?.[1] !== geometryExtent?.[3]
    ) {
      const draftExtent = {
        minX: geometryExtent?.[0],
        minY: geometryExtent?.[1],
        maxX: geometryExtent?.[2],
        maxY: geometryExtent?.[3],
      };

      const extentWKT = extentToWKT(draftExtent);
      setExtent(draftExtent);
      islemType !== "6" && removeAllCatalogLayerVectors(selectedCatalogLayers);
      deleteLayer(extentLayerClassName);
      getLayerNoZoom({
        source: extentWKT,
        name: "",
        className: extentLayerClassName,
        styleName: extentLayerStyleName,
      });
      sendWindowMessage(megsisIslemResponse, megsisIslemResponseExtentMessage, {
        minX: geometryExtent[0],
        minY: geometryExtent[1],
        maxX: geometryExtent[2],
        maxY: geometryExtent[3],
      });
    }
  });

  drawInteractionRef.current = draw;
  olMap.addInteraction(draw);
};

// Add selected data

const addSelectedDataOnMap = (selectedData) => {
  for (const catalogLayer in selectedData) {
    if (selectedData[catalogLayer]?.length > 0) {
      const catalogLayerName = Object.keys(catalogLayers).filter(
        (key) => catalogLayers[key].name === catalogLayer
      )[0];

      const wfsId = catalogLayers[catalogLayerName].catalogIdWFS;
      const titleColumnName = catalogLayers[catalogLayerName].titleColumnName;
      const primaryColumKey = catalogLayers[catalogLayerName].primaryColumKey;
      const color = catalogLayers[catalogLayerName].color;

      const typeName = catalogLayers[catalogLayerName].typeName;

      const filterExpression = `${primaryColumKey} IN (${selectedData[
        catalogLayer
      ].join(",")})`;

      const wfsUrl = baseLayerApiUrl + wfsId;

      const wfsParams = {
        service: "WFS",
        version: "2.0.0",
        request: "GetFeature",
        typeName: typeName,
        outputFormat: "application/json",
        CQL_FILTER: filterExpression,
        t: getToken(),
      };
      axios
        .get(wfsUrl, {
          params: wfsParams,
        })
        .then((res) => {
          const sourceEPSG = res?.data?.crs?.properties?.name;
          if (res?.data?.features && res?.data?.features?.length > 0) {
            const geoJSON = new GeoJSON();
            const features = geoJSON.readFeatures(res.data);

            features.forEach((feature) => {
              if (sourceEPSG)
                feature.getGeometry().transform(sourceEPSG, map.epsg);

              const properties = feature?.getProperties();
              const wkt = new WKT().writeGeometry(feature.getGeometry());
              const name = properties?.[titleColumnName]?.toString();
              const className =
                catalogLayers[catalogLayerName].name +
                properties?.[primaryColumKey];

              getLayerNoZoom({
                source: wkt,
                name: name,
                className: className,
                styleName: islemLayerStyleName,
                color: color,
              });
            });
          }
        })
        .catch((error) => {
          console.error("WFS sorgusu başarısız oldu: ", error);
        });
    }
  }
};

const addSelectedTasariParselRefs = (selectedData) => {

  const catalogLayer = parselLayerName

  const catalogLayerName = Object.keys(catalogLayers).filter(
    (key) => catalogLayers[key].name === catalogLayer
  )[0];

  const wfsId = catalogLayers[catalogLayerName].catalogIdWFS;
  const titleColumnName = catalogLayers[catalogLayerName].titleColumnName;
  const primaryColumKey = catalogLayers[catalogLayerName].primaryColumKey;
  const color = tasariParselColor;

  const typeName = catalogLayers[catalogLayerName].typeName;

  const filterExpression = `${primaryColumKey} IN (${selectedData.join(",")})`;

  const wfsUrl = baseLayerApiUrl + wfsId;

  const wfsParams = {
    service: "WFS",
    version: "2.0.0",
    request: "GetFeature",
    typeName: typeName,
    outputFormat: "application/json",
    CQL_FILTER: filterExpression,
    t: getToken(),
  };
  axios
    .get(wfsUrl, {
      params: wfsParams,
    })
    .then((res) => {
      const sourceEPSG = res?.data?.crs?.properties?.name;
      if (res?.data?.features && res?.data?.features?.length > 0) {
        const geoJSON = new GeoJSON();
        const features = geoJSON.readFeatures(res.data);

        features.forEach((feature) => {
          if (sourceEPSG)
            feature.getGeometry().transform(sourceEPSG, map.epsg);

          const properties = feature?.getProperties();
          const wkt = new WKT().writeGeometry(feature.getGeometry());
          const name = properties?.[titleColumnName]?.toString();
          const className =
            catalogLayers[catalogLayerName].name +
            properties?.[primaryColumKey];

          getLayerNoZoom({
            source: wkt,
            name: name,
            className: className,
            styleName: islemLayerStyleName,
            color: color,
          });
        });
      }
    })
    .catch((error) => {
      console.error("WFS sorgusu başarısız oldu: ", error);
    });
};

// Check Parsel Islem Geometry ozel durumu
const checkIslemParselGeometryFunc = async (
  parselRefArray,
) => {
  try {
    const queryBody = {
      "parselRefs": parselRefArray
    }
    const response = await axios.post(megsisIntegrationApiUrl.checkIslemParselGeometryApiUrl, queryBody)
    const data = await response.data;
    return data
  }
  catch (error) {
    return false
  }
}

const checkIslemParselGeometryWKomsuAdaFunc = async (
  parselRefArray,
) => {
  try {
    const queryBody = {
      "parselRefs": parselRefArray
    }
    const response = await axios.post(megsisIntegrationApiUrl.checkIslemParselGeometryWKomsuAdaApiUrl, queryBody)
    const data = await response.data;
    return data
  }
  catch (error) {
    return false
  }
}