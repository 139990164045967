import { Stack } from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import ToolbarButton from "./common/toolbarButton";
import { Z_TOOLBAR } from "../constants/constants";
import { TravelExplore } from "@mui/icons-material";
import { t } from "i18next";
import { useContext } from "react";
import { LeftPanelContext } from "../context/leftPanelContext";

const MapCoordinatePickerButton = ({ dragWidth }) => {

    const { isMapCoordinatePickerOpen,setIsMapCoordinatePickerOpen } = useContext(LeftPanelContext);

    const handleClickButton = () => {
        setIsMapCoordinatePickerOpen(!isMapCoordinatePickerOpen)
    }
	return (
		<Stack
			sx={{
				position: "absolute",
				left: `${dragWidth}px`,
				top: 241,
				borderTopRightRadius: 0,
				borderBottomRightRadius: 8,
				boxShadow: 3,
				background: "#fff",
				zIndex: Z_TOOLBAR,
			}}
		>
			<ToolbarButton
				title={t("GoToCoordinate")}
				icon={<TravelExplore color={isMapCoordinatePickerOpen ? "secondary" : "primary"}/>}
				onClick={handleClickButton}
			/>
		</Stack>
	);
};
export default MapCoordinatePickerButton;
