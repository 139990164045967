import { Stack } from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import { olMap } from "./map/mapView";
import { saveAs } from "file-saver";
import ToolbarButton from "./common/toolbarButton";
import { Z_TOOLBAR } from "../constants/constants";
import { useTranslation } from 'react-i18next';

const PrintButton = ({ dragWidth }) => {
	const { t, i18n } = useTranslation();
	const handlePrint = (mimeType = "image/png", qualityArgument) => {
		olMap.once("rendercomplete", function () {
			const mapCanvas = document.createElement("canvas");
			const size = olMap.getSize();
			mapCanvas.width = size[0];
			mapCanvas.height = size[1];
			const mapContext = mapCanvas.getContext("2d");
			Array.prototype.forEach.call(
				document.querySelectorAll(
					".ol-layers div canvas,.react-geo-measure-tooltip.react-geo-measure-tooltip-static"
				), //.ol-layer canvas
				function (canvas, all) {
					if (canvas.width > 200) {
						const opacity = canvas.parentNode.style.opacity;
						mapContext.globalAlpha = opacity === "" ? 1 : Number(opacity);
						const transform = canvas.style.transform;

						const matrix = transform
							.match(/^matrix\(([^\(]*)\)$/)[1]
							.split(",")
							.map(Number);

						CanvasRenderingContext2D.prototype.setTransform.apply(
							mapContext,
							matrix
						);
						mapContext.drawImage(canvas, 0, 0);

						const measureOverlays = [
							...document.querySelectorAll(
								".ol-overlay-container.ol-selectable"
							),
						].filter((overlay) =>
							overlay.firstChild.className.includes(
								"react-geo-measure-tooltip-static"
							)
						);

						measureOverlays.forEach((overlay) => {
							const overlayTransformPxArr = overlay.style.transform
								.split(") ")[1]
								.split(",")
								.map((px) => px.match(/\d/g).join(""));

							const textWidth = mapContext.measureText(overlay.innerText).width;

							mapContext.fillStyle = "rgba(255, 255, 255, 0.5)";
							mapContext.fillRect(
								overlayTransformPxArr[0] - 5,
								overlayTransformPxArr[1] - 12,
								textWidth > 60 ? textWidth + 20 : 60,
								17
							);

							mapContext.font = "12px Arial";
							mapContext.fillStyle = "black";
							mapContext.fillText(
								overlay.innerText,
								overlayTransformPxArr[0],
								overlayTransformPxArr[1]
							);
						});
					}
				}
			);

			if (navigator.msSaveBlob) {
				navigator.msSaveBlob(mapCanvas.msToBlob(), "Webgis-Viewer.png");
			} else {
				mapCanvas.toBlob(
					function (blob) {
						saveAs(blob, "Webgis-Viewer.png");
					},
					mimeType,
					qualityArgument
				);
			}
		});
		olMap.renderSync();
	};

	return (
		<Stack
			sx={{
				position: "absolute",
				left: `${dragWidth}px`,
				top: 205,
				borderTopRightRadius: 0,
				borderBottomRightRadius: 0,
				// boxShadow: 3,
				background: "#fff",
				zIndex: Z_TOOLBAR,
			}}
		>
			<ToolbarButton
				title={t("Save")}
				icon={<SaveIcon />}
				onClick={handlePrint}
			/>
		</Stack>
	);
};
export default PrintButton;
