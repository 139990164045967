import { Fragment, useContext, useEffect, useState } from "react";
import { LeftPanelContext } from "../../../context/leftPanelContext";
import { deleteLayer } from "../../../utils/getFeatureLayer";
import { addWMSLayer, olMap } from "../../map/mapView";

export const RightPanelInventoryData = ({ time }) => {
  
  const {searchValue} = useContext(LeftPanelContext);
  
  const handleChangeTime = async (timeRequest) => {
    await deleteLayer(envanterTabakaLayerSettings.className);
    if (time?.value) await addWMSLayerFunc(timeRequest);
  }

  const addWMSLayerFunc = (timeRequest) => {
    let cqlFilter =
    envanterTabakaLayerSettings.cqlFilterRegionColumnName +
      " = " +
      searchValue.region +
      " ";
    if (timeRequest)
      cqlFilter =
        cqlFilter +
        " AND " +
        envanterTabakaLayerSettings.cqlFilterTimeColumnName +
        " > " +
        timeRequest;


    const layer = addWMSLayer(envanterTabakaLayerSettings, cqlFilter, undefined);
    const layerList = olMap.getLayers();
    layer.className_ = envanterTabakaLayerSettings.className;
    layer && layerList.push(layer);
  };

  useEffect(() => {
    let timeRequest;
    let currentDate = new Date();
    switch (time?.value?.toString()) {
      case "1": // 1 Tümü
        timeRequest = false
        break; // Tümü için filtre gereksinimi yok !!!
      case "2": // 2 Son 1 ay
        currentDate.setMonth(currentDate.getMonth() - 1);
        timeRequest = currentDate.toISOString().split('T')[0];
        break;
      case "3": // 3 Son 6 ay
        currentDate.setMonth(currentDate.getMonth() - 6);
        timeRequest = currentDate.toISOString().split('T')[0];
        break;
      case "4": // 4 Son 1 yıl
        currentDate.setMonth(currentDate.getMonth() - 12);
        timeRequest = currentDate.toISOString().split('T')[0];
        break;
      default:
        break;
    }
    handleChangeTime(timeRequest);
  }, [time]);

  return (
    <Fragment>
    </Fragment>
  );
};

export const envanterTabakaLayerSettings = {
  className: "PMS-Envanter-Tabaka-LAYER",
  title: "Envanter Tabaka",
  key: 1,
  id: "268",
  mapServiceLayerName: "uys-v_envanter_tabaka_shape",
  isOpen: false,
  cqlFilterRegionColumnName: "bolge_no",
  cqlFilterTimeColumnName: "last_edit_time",
};


export const removeInventoryLayer = () => { deleteLayer(envanterTabakaLayerSettings.className) }