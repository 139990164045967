export const dxf2Geojson = (
  event,
  fileCoordinateSystem,
  localLayerName,
  localLayersId,
  setLocalLayersId
) => {
  const datas = event?.entities;

  const layers = {};

  const tables = event?.tables?.layer?.layers;

  const guide = {} // LocalLayersId ile Tablo adlarının eşleşebilmesi için klavuz

  var currentFileLayerId = localLayersId;


  //Tablolar için feature collectionların oluşturulması
  Object?.keys(tables).map((table) => {
    layers[currentFileLayerId.toString()] = {
      type: "FeatureCollection",
      name: localLayerName + (localLayerName && "_") + table,
      crs: {
        type: "name",
        properties: {
          name: "urn:ogc:def:crs:EPSG::" + fileCoordinateSystem.toString(),
        },
      },
      features: [],
    };    
    guide[table] = currentFileLayerId.toString()
    currentFileLayerId = (parseInt(currentFileLayerId) + 1).toString(); //currentFileLayerId değeri state değişkeni olmadığından daha hızlı büyüyor.
    setLocalLayersId((parseInt(currentFileLayerId) + 1).toString()); 
    
  });

  

  //Dataların oluşturulan tabloların içerisine eklenmesi
  datas?.map((data, index) => {
    
    const dataGeometry = [];
    var geometryType = "";

    if (data?.type.includes("POINT")) {
      geometryType = "Point";
      dataGeometry.push(data?.position.x);
      dataGeometry.push(data?.position.y);
    } else if (data?.type.includes("POLYGON")) {
      geometryType = "Polygon";
      data?.vertices?.map((vertice) =>
        dataGeometry.push([vertice.x, vertice.y])
      );
    } else {
      geometryType = "LineString";
      data?.vertices?.map((vertice) =>
        dataGeometry.push([vertice.x, vertice.y])
      );
    }

    if (dataGeometry !== []) {
      const featureLayer = {
        type: "Feature",
        properties: {
          id: index,
          Tabaka_Ad: data?.["layer"],
          Geometri_Tip: data?.type,
        },
        geometry: { type: geometryType, coordinates: dataGeometry },
      };

      if (dataGeometry?.length > 0) {
        layers[guide[data?.["layer"]]].features.push(featureLayer);
      }
    }
  });

  
  //Datası olmayan tabakaların silinmesi
  Object.keys(layers).map((layer) => {
    if (layers[layer].features.length < 1) {
      delete layers[layer];
    }
  });
  console.log(layers)
  return layers;
};
