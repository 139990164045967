import {
  DataGridPro,trTR,enUS,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";
import Snackbar from "@mui/material/Snackbar";
import { GRID_LOCALE_TEXT } from "../constants/constants";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { baseApiUrl, dataSourceApiUrl, map } from "../config";
import { flashLayer } from "../utils/getFeatureLayer";
import { LeftPanelContext } from "../context/leftPanelContext";
import { TableContext } from "../context/tableContext";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  handleExtentInteraction,
  handlePointerInteraction,
} from "./map/mapView";
import { useTranslation } from 'react-i18next';


export default function DataTable({ loading = false, tabValue }) {
	const {i18n } = useTranslation();
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [visibleColumns, setVisibleColumns] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [sort, setSort] = useState([]);
  const [filter, setFilter] = useState({});
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [language,setLanguage] = useState({});

  const { serviceDescriptionId, setSearchArea, setIsOpenSearchArea } =
    useContext(LeftPanelContext);
  const {
    setSelectedRow,
    filterData,
    filterAttributes,
    fields,
    tableKeyColumn,
    setTableKeyColumn,
    isThereIdColumn,
    setIsThereIdColumn,
    setIsOpenLayerTable,
    setFields,
  } = useContext(TableContext);
  const getDomainValue = (params, field) => {
    let domain;
    field?.domain?.values?.map((value) =>
      params.row[field.fieldName] == value.key ? (domain = value.value) : null
    );
    return domain;
  };

useEffect(()=>{
  if (i18n.language==="tr") {
    setLanguage(trTR.components.MuiDataGrid.defaultProps.localeText)
  }else if(i18n.language==="en"){
    setLanguage(enUS.components.MuiDataGrid.defaultProps.localeText)
  }
},[i18n.language])

  const fetchData = (page, pageSize, filter, sort) => {
    setFetchLoading(true);
    var optionVisibleColumns = visibleColumns // Primary key sütunu görülebilir değilse bile tablo için görülebilir yapılmak zorunda
    if (!visibleColumns.includes(tableKeyColumn)) { 
      optionVisibleColumns.push(tableKeyColumn);
    }
    const option = {
      visibleColumns: [optionVisibleColumns.join(",")],
      request: {
        take: pageSize,
        skip: page * pageSize || 0,
        page: page,
        pageSize: pageSize,
        sort: sort,
        filter: filter,
      },
    };

    axios(dataSourceApiUrl.pagedSidApiUrl + serviceDescriptionId, {
      method: "post",
      data: option,
    })
      .then((res) => {
        "";
        // Tablonun öznitelik değerlerinin boş gelmesi durumunda hata oluşur. Bunu durumu engellemek için if koşulu eklendi.                   
        if (Object.keys(res.data?.Data?.[0])?.length == 0) {
          setData([
            {
              id: "Bu Tablonun Öznitelik Tablosunda Bir Sorun Oluştu. Lütfen Yönetici İle İletişime Geçiniz...",
            },
          ]);
          setRowCount(1);
          setFetchLoading(false);
        } else {
          if (res.data?.Data?.[0]?.id) {
            setIsThereIdColumn(true);
          } else {
            setIsThereIdColumn(false);
          } // Eğer Id adında bir kolon mevcut degilse... (tablo yapısı bir tane id kolonu istiyor MUI de)

          res.data?.Data.forEach(
            (item, index) => (item.id = item[tableKeyColumn])            
          );
          
          setData(res.data?.Data);
          setRowCount(res.data?.Count);
          setFetchLoading(false);
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  useEffect(() => {
    isLoading && fetchData(page, pageSize);
    // const element = document.querySelector("#json-data-1 > div.MuiDataGrid-root.css-gebrn1-MuiDataGrid-root > div.MuiDataGrid-main.css-204u17-MuiDataGrid-main > div:nth-child(3)");
    // if (element?.innerHTML === "MUI X: Missing license key") element.innerHTML = "";
  }, [isLoading,serviceDescriptionId]);

  useEffect(() => {
    !isLoading &&
      fields?.forEach((field, index) => {
        if (field?.keyColumn == true) {
          // Anahtar Kolonun Belirlenmesi
          setTableKeyColumn(field.fieldName);
        }
        if (field?.visible && !field?.geometryColumn) {
          if (field.domain) {
            setColumns((prev) => [
              ...prev,
              {
                field: field.fieldName,
                headerName: field.alias || field.fieldName,
                valueGetter: (params) => getDomainValue(params, field),
                minWidth: 120,
                flex: 1,
              },
            ]);
            setVisibleColumns((prev) => [...prev, field.fieldName]);
          } else {
            setColumns((prev) => [
              ...prev,
              {
                field: field.fieldName,
                headerName: field.alias || field.fieldName,
                minWidth: 120,
                flex: 1,
              },
            ]);
            setVisibleColumns((prev) => [...prev, field.fieldName]);
          }
        }
      });

    setIsLoading(true);
  }, []);

  const handleSort = (model) => {
    const query = [{ field: model[0].field, dir: model[0].sort }];
    setSort(query);
    fetchData(page, pageSize, filter, query);
  };

  const handleFilter = (model) => {
    const filters = [];
    model.items.forEach((item) =>
      item.operatorValue.toLowerCase() === "isanyof"
        ? filters.push({
            operator:
              item.operatorValue.toLowerCase() === "equals"
                ? "eq"
                : item.operatorValue.toLowerCase(),
            values: item.value || "",
            value: "",
            field: item.columnField,
          })
        : filters.push({
            operator:
              item.operatorValue.toLowerCase() === "equals"
                ? "eq"
                : item.operatorValue.toLowerCase(),
            value: item.value || "",
            field: item.columnField,
          })
    );
    const query = { filters: filters, logic: model.linkOperator };
    setFilter(query);
    fetchData(page, pageSize, query, sort);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
    fetchData(newPage, pageSize, filter, sort);
  };

  const handlePageSize = (newPageSize) => {
    setPageSize(newPageSize);
    fetchData(page, newPageSize, filter, sort);
  };

  const handleClickCell = (param) => {
    setSelectedRow({ data: param.row, status: "table" });
  };

  const handleDoubleClickCell = (param) => {
    const request = {
      serviceDescriptionId: serviceDescriptionId,
      whereClause: `${tableKeyColumn} = ${param.id}`,
      outputWKID: map.wkid,
      returnFields: true,
      subFields: [],
    };
    filterData.find(
      (data) => data.oid === param.row.id && flashLayer(data.geometry)
    );
    axios
      .post(baseApiUrl + "/data-source/feature/filter/query", request)
      .then((res) => {
        if (res.data) {
          flashLayer(res.data[0]?.geometry);
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer
        sx={{
          position: "absolute",
          right: 0,
          top: "-35px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
        <IconButton
          aria-label="close"
          sx={{ padding: 0, borderRadius: 0, marginLeft: "5px" }}
          onClick={() => {
            handleExtentInteraction(false);
            handlePointerInteraction(false);
            setSearchArea();
            setFields([]);
            setIsOpenSearchArea(false);
            setSelectedRow();
            setIsOpenLayerTable(false);
          }}
        >
          <CloseIcon />
        </IconButton>
      </GridToolbarContainer>
    );
  };

  if (tabValue === 0) {
    return (
      <DataGridPro
        rows={data}
        columns={columns}
        loading={fetchLoading}
        localeText={language}
        onSortModelChange={handleSort}
        onFilterModelChange={handleFilter}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSize}
        onCellClick={handleClickCell}
        onCellDoubleClick={handleDoubleClickCell}
        pagination
        pageSize={pageSize}
        page={page}
        rowCount={rowCount}
        density="compact"
        filterMode="server"
        paginationMode="server"
        sortingMode="server"
        disableSelectionOnClick={true}
        showCellRightBorder
        showColumnRightBorder
        disableExtendRowFullWidth
        components={{ Toolbar: CustomToolbar }}
      />
    );
  } else {
    return (
      <DataGridPro
        rows={filterAttributes.length > 0 && filterAttributes}
        columns={columns}
        localeText={language}
        pagination
        density="compact"
        onCellClick={handleClickCell}
        onCellDoubleClick={handleDoubleClickCell}
        showCellRightBorder
        showColumnRightBorder
        disableExtendRowFullWidth
        components={{ Toolbar: CustomToolbar }}
      />
    );
  }
}
