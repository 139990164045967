import Dexie from "dexie";

const dbName = "ViewerProduct-webgis";
const db = new Dexie(dbName);

export const getAllProjectPreview = async () => {
    await db.open();

    try {
        const table = db.table("ProjectService");
        return table.toArray();
    } catch (err) {
        throw err;
    }
};

export const getProjectPreviewById = async (projectId) => {
    await db.open();

    try {
        const table = db.table("ProjectService");
        return table.get({ projectId: projectId });
    } catch (err) {
        throw err
    }
}

export const setProjectPreview = async (projectId, preview) => {
    await db.open();

    try {
        const table = db.table("ProjectService");
        if (projectId !== 0) {
            getProjectPreviewById(projectId).then(x => {
                if (x) {
                    table.where("projectId").equals(projectId).delete();
                }
                table.add({ projectId: projectId, preview: preview })
            })
        }
        return;
    } catch (err) {
        throw err
    }
}