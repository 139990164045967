import { Stack } from "@mui/material";
import {
  handlePointerInteraction,
  olMap,
  pointerInteraction,
} from "./map/mapView";
import { CircularProgress } from "@mui/material";
import ToolbarButton from "./common/toolbarButton";
import { Z_TOOLBAR } from "../constants/constants";
import { QueryStats } from "@mui/icons-material";
import { useContext, useEffect, useState } from "react";
import { LeftPanelContext } from "../context/leftPanelContext";
import { MainContext } from "../context/mainContext";
import { ImageWMS } from "ol/source";
import { baseLayerApiUrl, kgmCatalogLayers, redirectUrl } from "../config";
import { getToken } from "../utils/auth";
import { sendWindowMessage } from "../context/messageServiceContext";
import { useTranslation } from 'react-i18next';


const IframeClickOnMapButton = ({ dragWidth }) => {
  const { t, i18n } = useTranslation();
  const { isRightOverlayOpen, isIFrame } = useContext(MainContext);
  const {
    isInventoryCommonLayerOpen,
    setIsInventoryCommonLayerOpen,
    searchValue,
  } = useContext(LeftPanelContext);

  const [loading, setLoading] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);

  const handleClickOnMap = async (evt) => {
    const wmsSource = new ImageWMS({
      url:
      baseLayerApiUrl +
        kgmCatalogLayers.kgmInventeroyCommonLayer.wmsId +
        "?",
      params: {
        LAYERS: kgmCatalogLayers.kgmInventeroyCommonLayer.layerName,
        VERSION: "1.1.0",
        CQL_FILTER: "bolge_no=" + searchValue?.region,
        t: getToken(),
      },
      serverType: "geoserver",
      crossOrigin: "anonymous",
      buffer: "50",
    });

    const url = await wmsSource.getFeatureInfoUrl(
      evt.target.coordinate,
      olMap.getView().getResolution(),
      olMap.getView().getProjection(),
      {
        INFO_FORMAT: "application/json",
      }
    );

    setLoading(true);
    if (url) {
      const response = await fetch(url);
      const html = await response.text();
      const responseJSON = await JSON.parse(html);
      if (responseJSON.features.length !== 0) {
        const parameters = {
          kkno: {
            id: parseInt(responseJSON?.features[0]?.properties?.id),
            dgr: responseJSON?.features[0]?.properties?.kkno,
            istikamet:responseJSON?.features[0]?.properties?.istikamet
          },
          bolge: {
            bn: responseJSON?.features[0]?.properties?.bolge_no,
            ba: responseJSON?.features[0]?.properties?.bolge_ad,
            id: responseJSON?.features[0]?.properties?.bolge_id,
          },
        };

        if (isIFrame) {
          sendWindowMessage(
            "openEnvanterGirisiUstYapiTabakalari",
            "",
            parameters
          );
        } else {
          window.open(
            redirectUrl.uysURL + "/envantergirisi/ustyapi-tabakalari",
            JSON.stringify(parameters)
          );
        }
      } else {
        alert(
          "Veri Bulunamadı...\nLütfen Bir envanter rapor geometrisinin üzerine tıklayın."
        );
      }
    } else {
      alert(
        "Veri Bulunamadı...\nLütfen Bir envanter rapor geometrisinin üzerine tıklayın."
      );
    }
    setLoading(false);
  };

  const handleClickOnToolbarButton = () => {
    setLoading(false);
    pointerInteraction.listeners_ = [];
    if (!buttonClicked) {
      handlePointerInteraction(true);
      pointerInteraction.on("change:coords", handleClickOnMap);
    } else {
      handlePointerInteraction(false);
    }

    setButtonClicked(!buttonClicked);
  };

  useEffect(() => {
    handlePointerInteraction(false);
    setButtonClicked(false)    
  }, [isInventoryCommonLayerOpen,searchValue]);

  return (
    <Stack
      sx={{
        position: "absolute",
        left: `${dragWidth}px`,
        top: 50,
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
        boxShadow: 3,
        background: "#fff",
        zIndex: Z_TOOLBAR,
        minWidth: "40",
      }}
    >
      <ToolbarButton
        disabled={!isInventoryCommonLayerOpen || isRightOverlayOpen}
        color={buttonClicked ? "secondary" : "primary"}
        title={t("QueryInventoryReport")}
        icon={
          loading ? (
            <CircularProgress size={20} color="secondary" />
          ) : (
            <QueryStats />
          )
        }
        onClick={handleClickOnToolbarButton}
      />
    </Stack>
  );
};
export default IframeClickOnMapButton;
