import { Button, CircularProgress, FormControl, InputLabel, NativeSelect } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { kgmCatalogLayers, uysApiUrl } from "../../../config";
import {
  handlePointerInteraction,
  olMap,
  pointerInteraction,
} from "../../map/mapView";
import { List } from "@mui/material";
import { ListItem } from "@mui/material";
import { Info } from "@mui/icons-material";
import { Avatar } from "@mui/material";
import { ListItemAvatar } from "@mui/material";
import { ListItemText } from "@mui/material";
import axios from "axios";
import { Tooltip } from "@mui/material";
import { Typography } from "@mui/material";
import { Overlay } from "ol";
import { getServiceLegendBySid } from "../../../services/getServiceLegend";
import { RightPanelMeasurementTabsModal } from "./rightPanelMeasurementTabsModal";
import { RightPanelMeasurementRSPListButton } from "./rightPanelMeasurementTabsRSPListButton";
import { RightPanelMeasurementFIRIListButton } from "./rightPanelMeasurementTabsFIRIListButton";
import RightPanelOverlay from "../rightPanelOverlay";
import { RightPanelMeasurementFWDListButton } from "./rightPanelMeasurementTabsFWDListButton";
import { RightPanelMeasurementTabsDropsAnalyzedFWDListButton } from "./rightPanelMeasurementTabsDropsAnalyzedFWDListButton";
import removeAllRightPanelMeasurementLayers from "./rightPanelUtils/rightPanelMeasurementFunctions";
import { useTranslation } from "react-i18next";
import { RightPanelMeasurementSKSListButton } from "./rightPanelMeasurementTabsSKSListButton";
import {LeftPanelContext} from "../../../context/leftPanelContext"

const RightPanelMeasurementTabs = ({
  measurementType,
  rightPanelAction,
  rightPanelQueryOnMap,
  setRightPanelQueryOnMap,
  isRightOverlayOpen,
  setIsRightOverlayOpen,
  isLoadingTab,
  setIsLoadingTab,
}) => {
  const { t, i18n } = useTranslation();

  const {searchValue} = useContext(LeftPanelContext)

  const [measurementFiles, setMeasurementFiles] = useState([]); // Ölçümler
  const [measurementFilesLayers, setMeasurementFilesLayers] = useState({}); // Katman Görünürlükleri için obje
  const [selectedMeasurementFileId, setSelectedMeasurementFileId] =
    useState(""); // Seçilen RSP Dosyası

  const [filteredRSPtasks, setFilteredRSPtasks] = useState([]);
  const [filteredRSPtaskLayers, setFilteredRSPtasksLayers] = useState({});

  const [dropsAnalyzedFWD, setDropsAnalyzedFWD] = useState([]);
  const [dropsAnalyzedFWDLayers, setDropsAnalyzedFWDLayers] = useState({});

  const [coordinateOnMap, setCoordinateOnMap] = useState([]);

  const [legendModalOpen, setLegendModalOpen] = useState(false);
  const [legendInfo, setLegendInfo] = useState({});

  const [yearFilter,setYearFilter] = useState("-1");
  const [yearFilterOptions,setYearFilterOptions] = useState([]);

  
  const handleSelectYearFilter = (e) => {
    setSelectedMeasurementFileId("")
    const value = e.target.value;
    setYearFilter(value);
  }

  // Tooltip İçeriği
  const RSPToolTipTitle = (data) => {
    const dateValueArray = data?.olcum_tarih?.split(" ")[0]?.split("/");
    return (
      <>
        <div style={{ ...toolTipStyles }}>
          {dateValueArray[1] +
            "/" +
            dateValueArray[0] +
            "/" +
            dateValueArray[2]}
        </div>
        <hr />
        <table>
          <tr>
            <th>{t("Beginning")}</th>
            <td>{(parseFloat(data?.baslangic_metre) * 1000)?.toFixed(2)} m</td>
          </tr>
          <tr>
            <th>{t("Finish")}</th>
            <td>{(parseFloat(data?.bitis_metre) * 1000)?.toFixed(2)} m</td>
          </tr>
        </table>
      </>
    );
  };

  // Overlay Kaldır
  const HandleOverlayRemove = () => {
    olMap.overlays_.array_.length = 0;
  };

  // ÖlçümleriN Çekilmesi
  useEffect(() => {
    removeAllRightPanelMeasurementLayers(); // Tüm Ölçüm katmanları haritadan kaldırılır
    if (Object.keys(rightPanelAction).length !== 0) {
      setFilteredRSPtasks([]);
      setFilteredRSPtasksLayers({});
      setSelectedMeasurementFileId("");
      setIsLoadingTab(true);
      axios
        .post(uysApiUrl + "/olcum/paged", {
          rowsPerPage: 50,
          page: 0,
          filter: "",
          karayoluIds: [rightPanelAction?.kkno?.id],
          olcumTurId: measurementType, // OlcumTurId
          filterList: {},
          sort: {
            name: "id",
            direction: "asc",
          },
        })
        .then((res) => {
          var draftLayersObject = {};
          setMeasurementFiles(res.data.data);
          if (measurementType === 2) {
            // FWD Ölçümleri için
            res.data.data.map((data, index) => {
              draftLayersObject[data.id] = {
                isFWDLayerOpen: false,
              };
            });
          } else {
            // RSP Ölçümleri için
            res.data.data.map((data, index) => {
              draftLayersObject[data.id] = {
                isIRILayerOpen: false,
              };
            });
          }
          setIsLoadingTab(false);
          setMeasurementFilesLayers(draftLayersObject);
        })
        .catch((err) => {
          setIsLoadingTab(false);
          throw err;
        });
    }
  }, [rightPanelAction, measurementType]);

  // Right Panelin Açılması Durumu
  useEffect(() => {
    if (Object.keys(rightPanelAction).length === 0) {
      setIsRightOverlayOpen(false);
      HandleOverlayRemove();
      handlePointerInteraction(false);
      setCoordinateOnMap([]);
    }
  }, [rightPanelAction]);

  // FIRI Ölçümlerinin veya Analiz Edilen FWD'lerin Çekilmesi
  useEffect(() => {
    setFilteredRSPtasks([]);
    setFilteredRSPtasksLayers({});
    setDropsAnalyzedFWD([]);
    setDropsAnalyzedFWDLayers({});
    if (selectedMeasurementFileId && measurementType === 1) {
      // FIRI Ölçümleri (RSP)
      axios
        .post(
          uysApiUrl +
            "/rsp/iri/filtre/gorev/" +
            selectedMeasurementFileId +
            "/paged",
          {
            rowsPerPage: 50,
            page: 0,
            filter: "",
            filterList: {},
            sort: {
              name: "id",
              direction: "asc",
            },
          }
        )
        .then((res) => {
          var draftLayersObject = {};
          setFilteredRSPtasks(res.data.data);
          res.data.data.map((data, index) => {
            draftLayersObject[data.id] = {
              isFIRILayerOpen: false,
            };
          });
          setFilteredRSPtasksLayers(draftLayersObject);
        })
        .catch((err) => {
          throw err;
        });
    }
    if (selectedMeasurementFileId && measurementType === 2) {
      // Analiz Edilen FWDler (FWD)

      axios
        .post(
          uysApiUrl + "/fwd/get/analyze/paged/" + selectedMeasurementFileId,
          {
            rowsPerPage: 50,
            page: 0,
            filter: "",
            filterList: {},
            sort: {
              name: "id",
              direction: "asc",
            },
          }
        )
        .then((res) => {
          var draftLayersObject = {};
          setDropsAnalyzedFWD(res.data.data);
          res.data.data.map((data, index) => {
            draftLayersObject[data.id] = {
              isDropsAnalyzedFWDLayerOpen: false,
            };
          });
          setDropsAnalyzedFWDLayers(draftLayersObject);
        })
        .catch((err) => {
          throw err;
        });
    }
  }, [selectedMeasurementFileId]);

  // Haritaya Tıklama
  useEffect(() => {
    pointerInteraction.listeners_ = []; // Eğer pointerInteraction da sıkışma/karışıklık olursa kaldırılabilir
    if (rightPanelQueryOnMap) {
      handlePointerInteraction(true);
      const overlay = new Overlay({
        element: document.getElementById("right-panel-overlay"),
        autoPan: {
          animation: {
            duration: 250,
          },
        },
      });
      pointerInteraction.on("change:coords", async (evt) => {
        await overlay.setPosition(evt.target.coordinate);
        let coordinateX = evt.target.coordinate[0];
        let coordinateY = evt.target.coordinate[1];
        await setCoordinateOnMap(evt.target.coordinate);
        olMap
          .getView()
          .fit([coordinateX, coordinateY, coordinateX, coordinateY], {
            duration: 500,
            padding: [0, 500, 400, 0],
          });
        await olMap.addOverlay(overlay);
      });
      setIsRightOverlayOpen(true);
    } else {
      setIsRightOverlayOpen(false);
      HandleOverlayRemove();
      handlePointerInteraction(false);
      setCoordinateOnMap([]);
    }
    return () => {
      pointerInteraction.listeners_ = [];
    };
  }, [rightPanelQueryOnMap]);

  useEffect(()=>{
    const optionYears = [];
    measurementFiles.map((item)=>{
      if (item.olcum_tarih) return (optionYears.push(item.olcum_tarih?.split(" ")[0].split("/")[2]))
    })
    const setOptionYears = [...new Set(optionYears)]
    setYearFilterOptions(setOptionYears);
  },[measurementFiles])

  return (
    <div>
      <RightPanelOverlay
        rightPanelAction={rightPanelAction}
        isRightOverlayOpen={isRightOverlayOpen}
        setIsRightOverlayOpen={setIsRightOverlayOpen}
        setRightPanelQueryOnMap={setRightPanelQueryOnMap}
        coordinateOnMap={coordinateOnMap}
        measurementType={measurementType}
      ></RightPanelOverlay>

      <FormControl fullWidth style={{padding:"0.25em"}}>
          <NativeSelect
            value={yearFilter}
            onChange={handleSelectYearFilter}
          >
            <option value={"-1"}>Tümü</option>
            {yearFilterOptions.map((year)=>
              <option value={year}>{year}</option>
              )
            }
          </NativeSelect>
      </FormControl>

      <div>
        {measurementFiles.length !== 0 && (
          <Typography color="primary" style={{ ...titleStyles }}>
            {t("MeasurementFiles")} ({measurementFiles.length})
          </Typography>
        )}
        {isLoadingTab ? (
          <CircularProgress />
        ) : (
          <List style={{ maxHeight: "13em", overflow: "auto" }}>
            {measurementFiles.length !== 0 &&
              measurementFiles.map((data, index) => 
              {
                if (yearFilter!=="-1") if (!data.olcum_tarih.includes(yearFilter)) return;
                return (
                <ListItem
                  secondaryAction={
                    measurementType === 1 ? (
                      <RightPanelMeasurementRSPListButton
                        listObject={data}
                        measurementFilesLayers={measurementFilesLayers}
                        setMeasurementFilesLayers={setMeasurementFilesLayers}
                        searchValue={searchValue}
                      />
                    ) : measurementType === 2 ? (
                      <RightPanelMeasurementFWDListButton
                        listObject={data}
                        measurementFilesLayers={measurementFilesLayers}
                        setMeasurementFilesLayers={setMeasurementFilesLayers}
                        searchValue={searchValue}
                      />
                    ) : (
                      <RightPanelMeasurementSKSListButton
                        listObject={data}
                        measurementFilesLayers={measurementFilesLayers}
                        setMeasurementFilesLayers={setMeasurementFilesLayers}
                        searchValue={searchValue}
                      />
                    )
                  }
                  key={index}
                  sx={{ ...listItemStyles }}
                >
                  <ListItemAvatar>
                    <Tooltip title={RSPToolTipTitle(data)} placement="left">
                      <Avatar sx={{ backgroundColor: "#ffffff00" }}>
                        <Button
                          onClick={async () => {
                            await getServiceLegendBySid(
                              kgmCatalogLayers.kgmRSPFIRILayer.wmsId
                            ).then((legend) => legend && setLegendInfo(legend));
                            setLegendModalOpen(true);
                          }}
                        >
                          <Info color="primary" />
                        </Button>
                      </Avatar>
                    </Tooltip>
                  </ListItemAvatar>
                  <Button
                    style={{ margin: 0, padding: 0, textAlign: "left" }}
                    onClick={() => setSelectedMeasurementFileId(data.id)}
                  >
                    <ListItemText
                      primary={
                        <Tooltip title={data?.ad} placement="top">
                          <Typography
                            color={
                              selectedMeasurementFileId === data?.id
                                ? "secondary"
                                : "primary"
                            }
                            style={{ ...listItemTextPrimaryStyles }}
                          >
                            {data?.ad}
                          </Typography>
                        </Tooltip>
                      }
                    />
                  </Button>
                </ListItem>
              )})}
          </List>
        )}
      </div>

      {/* Fırı Ölçümleri */}
      <div>
        {filteredRSPtasks.length !== 0 && (
          <>
            <Typography color="secondary" style={{ ...titleStyles }}>
              {t("FilteredIRITasks")} ({filteredRSPtasks.length})
            </Typography>
            <List style={{ maxHeight: "13em", overflow: "auto" }}>
              {filteredRSPtasks.map((data, index) => (
                <ListItem
                  secondaryAction={
                    <RightPanelMeasurementFIRIListButton
                      listObject={data}
                      measurementFilesLayers={measurementFilesLayers}
                      setMeasurementFilesLayers={setMeasurementFilesLayers}
                      filteredRSPtaskLayers={filteredRSPtaskLayers}
                      setFilteredRSPtasksLayers={setFilteredRSPtasksLayers}
                      searchValue={searchValue}
                    />
                  }
                  key={index}
                  sx={{ ...listItemStyles }}
                >
                  <ListItemAvatar>
                    <Tooltip
                      title={
                        <div style={{ ...toolTipStyles }}>
                          {data?.last_edit_time.split(" ")[0]}
                        </div>
                      }
                      placement="left"
                    >
                      <Avatar sx={{ backgroundColor: "#ffffff00" }}>
                        <Button
                          onClick={async () => {
                            await getServiceLegendBySid(
                              kgmCatalogLayers.kgmRSPFIRILayer.wmsId
                            ).then((legend) => legend && setLegendInfo(legend));
                            setLegendModalOpen(true);
                          }}
                        >
                          <Info color="secondary" />
                        </Button>
                      </Avatar>
                    </Tooltip>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Tooltip title={data?.ad} placement="top">
                        <div style={{ ...listItemTextPrimaryStyles }}>
                          {data?.ad}
                        </div>
                      </Tooltip>
                    }
                    secondary={
                      t("ReportingInterval") +
                      ": " +
                      data?.reporting_interval_meters +
                      " " +
                      t("meter")
                    }
                  />
                </ListItem>
              ))}
            </List>
          </>
        )}
      </div>

      {/* Analiz Edilmiş FWD Ölçümleri */}
      <div>
        {dropsAnalyzedFWD.length !== 0 && (
          <>
            <Typography color="secondary" style={{ ...titleStyles }}>
              {t("AnalyzedFWD")} ({dropsAnalyzedFWD.length})
            </Typography>
            <List style={{ maxHeight: "13em", overflow: "auto" }}>
              {dropsAnalyzedFWD.map((data, index) => (
                <ListItem
                  secondaryAction={
                    <RightPanelMeasurementTabsDropsAnalyzedFWDListButton
                      listObject={data}
                      measurementFilesLayers={measurementFilesLayers}
                      setMeasurementFilesLayers={setMeasurementFilesLayers}
                      dropsAnalyzedFWDLayers={dropsAnalyzedFWDLayers}
                      setDropsAnalyzedFWDLayers={setDropsAnalyzedFWDLayers}
                      searchValue={searchValue}
                    />
                  }
                  key={index}
                  sx={{ ...listItemStyles }}
                >
                  <ListItemAvatar>
                    <Tooltip
                      title={
                        <div style={{ ...toolTipStyles }}>
                          {data?.last_edit_time.split(" ")[0]}
                        </div>
                      }
                      placement="left"
                    >
                      <Avatar sx={{ backgroundColor: "#ffffff00" }}>
                        <Button
                          // onClick={async () => {
                          //   await getServiceLegendBySid(
                          //     kgmCatalogLayers.kgmRSPFIRILayer.wmsId
                          //   ).then((legend) => legend && setLegendInfo(legend));
                          //   setLegendModalOpen(true);
                          // }}
                          disabled
                        >
                          <Info color="secondary" />
                        </Button>
                      </Avatar>
                    </Tooltip>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Tooltip title={data?.ad} placement="top">
                        <div style={{ ...listItemTextPrimaryStyles }}>
                          {data?.ad}
                        </div>
                      </Tooltip>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </>
        )}
      </div>

      {/* Ölçüm Mevcut Değil Yazıları */}
      <div>
        {measurementFiles.length === 0 && (
          <span>{t("NoMeasurementSystem")}</span>
        )}
      </div>
      <div>
        {filteredRSPtasks.length === 0 &&
          selectedMeasurementFileId &&
          measurementType === 1 && (
            <span>Kayıtlı IRI Görevi Mevcut Değil.</span>
          )}
      </div>
      <div>
        {dropsAnalyzedFWD.length === 0 &&
          selectedMeasurementFileId &&
          measurementType === 2 && (
            <span>Kayıtlı Analiz Edilmiş FWD Mevcut Değil.</span>
          )}
      </div>

      {/* Lejant Modalı */}
      <RightPanelMeasurementTabsModal
        legendModalOpen={legendModalOpen}
        setLegendModalOpen={setLegendModalOpen}
        legendInfo={legendInfo}
      />
    </div>
  );
};

const titleStyles = {
  fontSize: "17px",
  margin: "1em",
  fontWeight: "bold",
  borderBottomStyle: "solid",
};

const listItemStyles = {
  borderBottomStyle: "solid",
  borderColor: "#f1f1f1",
};

const listItemTextPrimaryStyles = {
  // fontSize: "15px",
  overflow: "hidden",
  maxWidth: "12em",
};

const iconInbuttonStyles = {
  height: "0.75em",
  width: "auto",
};

const toolTipStyles = {
  textAlign: "center",
  fontWeight: "bold",
  fontSize: "13px",
};

const buttonStyles = {
  height: "2em",
  width: "100%",
  mt: 0.5,
};

export default RightPanelMeasurementTabs;
