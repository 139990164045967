export const ServiceType = {
	None: 0,
	WMTS: 2,
	Google: 4,
	OSM: 8,
	BingMap: 16,
	WMS: 32,
	QueryableWMS: 64,
	EditableWMS: 128,
	ArcgisRest: 256,
	QueryableArcgisRest: 512,
	EditableArcgisRest: 1024,
	BasicAuthWMTS: 4096,
	BasicAuthWMS: 8192,
	BasicAuthArcgisRest: 16384,
	BasicAuthWFS: 32768,
	PublicWMTS: 65536,
	PublicWMS: 131072,
	PublicWFS: 262144,
};
