import axios from "axios";
import { appName } from "../config";
import { clearCredentials, getToken } from "../utils/auth";
import { useTranslation } from "react-i18next";

// axios.defaults.headers.common["token"] = JSON.parse(
// 	localStorage.getItem(appName + ".Credentials")
// )?.token;

axios.defaults.headers.common["token"] = getToken();
axios.defaults.headers.common["userId"] = localStorage.getItem(
  appName + ".Credentials"
)?.userId
  ? localStorage.getItem(appName + ".Credentials")?.userId
  : localStorage.getItem(appName + ".LastUserId");

axios.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const expectedError =
      error?.response &&
      error?.response?.status >= 400 &&
      error?.response?.status < 500;

    if (!expectedError) {
      const { t, i18n } = useTranslation();
      console.log(t("UnexpectedError"));
    }

    if (error?.response?.status === 401) {
      clearCredentials();
      delete axios.defaults.headers.common["token"];
      delete axios.defaults.headers.common["userId"];
    }
    return Promise.reject(error);
  }
);

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
};
