import kbbLogo from "./assets/logo/kbb.png";
import mbbLogo from "./assets/logo/mbb.png";
import kgmLogo from "./assets/logo/kgm.png";
import kktcLogo from "./assets/logo/kktc.png";

import kktcLoginPicture1 from "./assets/pictures/login_kktc_1.jpg";
import kktcLoginPicture2 from "./assets/pictures/login_kktc_2.jpg";
import kktcLoginPicture3 from "./assets/pictures/login_kktc_1.jpg";
import kktcLoginPicture4 from "./assets/pictures/login_kktc_2.jpg";

import kgmLoginPicture1 from "./assets/pictures/login_kgm1.jpg";
import kgmLoginPicture2 from "./assets/pictures/login_kgm2.jpg";
import kgmLoginPicture3 from "./assets/pictures/login_kgm3.jpg";

import viewerProductLogo from "./assets/logo/viewerProduct.png";

const kktcLoginPictures = [
  kktcLoginPicture1,
  kktcLoginPicture2,
  kktcLoginPicture3,
  kktcLoginPicture4,
];
const kgmLoginPictures = [kgmLoginPicture1, kgmLoginPicture2, kgmLoginPicture3];

const Config = () => {
  let baseApiUrl,
    loginPictures,
    logo,
    defaultLanguage,
    siteName,
    appName,
    userApiUrl,
    province,
    searchMode,
    district,
    map,
    checkTokenValidityApi,
    getTokenDetailsApi,
    adresApiUrl,
    kadastroApiUrl,
    baseLayerApiUrl,
    parsel,
    dataSourceApiUrl,
    redirectUrl,
    serviceDirectory,
    roadApi,
    projectApi,
    poiApiUrl,
    exportDataApi,
    kgmCatalogLayers,
    kgmRegionRolesHead,
    exportDataUrl,
    captchaKey,
    uysApiUrl,
    megsisIntegrationApiUrl;
  const hostName = window.location.host;

  switch (hostName.toLowerCase()) {
    case "webgis-viewer.kocaeli.bel.tr":
      logo = kbbLogo;
      defaultLanguage = "tr";
      baseApiUrl = "//webgis-api.kocaeli.bel.tr";
      redirectUrl = {
        userMaganerUrl: "",
        catalogUrl: "//webgis-catalog.kocaeli.bel.tr/",
        cebsUrl: "//cebs.kocaeli.bel.tr/",
      };
      map = {
        epsg: "EPSG:5254",
        wkid: 5254,
        center: [501588.9, 4511119.42],
        zoom: 9,
        minZoom: 7,
        maxZoom: 19,
      };
      appName = "Viewer-kocaeli";
      province = { id: 41, ad: "Kocaeli" };
      searchMode = "other";
      baseLayerApiUrl = "//cbs-proxy.kocaeli.bel.tr/gisserver/token/services/";
      userApiUrl = `//cbs-sso.kocaeli.bel.tr/token/`;
      checkTokenValidityApi = `//cbs-sso.kocaeli.bel.tr/token/control-token-u?t=`;
      getTokenDetailsApi = `//cbs-sso.kocaeli.bel.tr/token/control-token-ud?t=`;
      adresApiUrl = {
        ilceApiUrl: `${baseApiUrl}/component/adres/ilce/${province?.id}`,
        mahalleApiUrl: `${baseApiUrl}/component/adres/mahalle/`,
        csbmApiUrl: `${baseApiUrl}/component/adres/csbm/`,
        numaratajApiUrl: `${baseApiUrl}/component/adres/numarataj/`,
        sgIlceApiUrl: `${baseApiUrl}/component/adres/ilce/`,
        sgMahalleApiUrl: `${baseApiUrl}/component/adres/mahalle/`,
        sgCsbmApiUrl: `${baseApiUrl}/component/adres/csbm/`,
        cpNumaratajApiUrl: `${baseApiUrl}/component/adres/numarataj/`,
      };
      kadastroApiUrl = {
        ilceApiUrl: `${baseApiUrl}/component/kadastro/ilce/${province?.id}`,
        mahalleApiUrl: `${baseApiUrl}/component/kadastro/mahalle/`,
        adaApiUrl: `${baseApiUrl}/component/kadastro/ada/`,
        numaratajApiUrl: `${baseApiUrl}/component/kadastro/numarataj/`,
        sgParselApiUrl: `${baseApiUrl}/component/kadastro/parsel/`,
      };
      serviceDirectory = {
        hierarchicalData: `${baseApiUrl}/service-directory/hierarchical-data`,
      };
      parsel = { id: 147, title: "Megsis - Parsel" };
      dataSourceApiUrl = {
        pagedSidApiUrl: `${baseApiUrl}/data-source/paged/`,
        spatialFilterApiUrl: `${baseApiUrl}/data-source/feature/filter/spatial`,
        queryFilterApiUrl: `${baseApiUrl}/data-source/feature/filter/query`,
      };
      megsisIntegrationApiUrl = {
        checkIslemParselGeometryApiUrl: `//megsis-cbs-api-test.tapu.icisleri.gov.ct.tr/harita-onizleme/check-islem-parsel-geometry`,
        checkIslemParselGeometryWKomsuAdaApiUrl: `//megsis-cbs-api-test.tapu.icisleri.gov.ct.tr/harita-onizleme/check-islem-parsel-geometry-w-komsu-ada`,
      };
      projectApi = {
        projecsUrl: `${baseApiUrl}/project/get`,
      };
      exportDataApi = {
        exportDataUrl: `${baseApiUrl}/geometry/export`,
      };
      break;

    case "localhost.kocaeli.bel.tr:3000":
    case "localhost.kocaeli.bel.tr:3001":
    case "webgis-viewer-test.kocaeli.bel.tr":
      logo = kbbLogo;
      defaultLanguage = "tr";
      baseApiUrl = "//webgis-api-test.kocaeli.bel.tr"; //"//webgis-api-test.kocaeli.bel.tr";
      redirectUrl = {
        userMaganerUrl: "",
        catalogUrl: "//webgis-catalog-test.kocaeli.bel.tr/",
        cebsUrl: "//cebs.kocaeli.bel.tr/",
      };
      map = {
        epsg: "EPSG:5254",
        wkid: 5254,
        center: [501588.9, 4511119.42],
        zoom: 9,
        minZoom: 7,
        maxZoom: 19,
      };
      appName = "Viewer-kocaeli";
      province = { id: 41, ad: "Kocaeli" };
      searchMode = "other";
      baseLayerApiUrl =
        "//cbs-proxy-test.kocaeli.bel.tr/gisserver/token/services/"; //"//cbs-proxy-test.kocaeli.bel.tr/gisserver/token/services/";
      userApiUrl = `//cbs-sso-test.kocaeli.bel.tr/token/`; //`//cbs-sso-test.kocaeli.bel.tr/token/`;
      checkTokenValidityApi = `//cbs-sso-test.kocaeli.bel.tr/token/control-token-u?t=`; // `//cbs-sso-test.kocaeli.bel.tr/token/control-token-u?t=`;
      getTokenDetailsApi = `//cbs-sso-test.kocaeli.bel.tr/token/control-token-ud?t=`;
      adresApiUrl = {
        ilceApiUrl: `${baseApiUrl}/component/adres/ilce/${province?.id}`,
        mahalleApiUrl: `${baseApiUrl}/component/adres/mahalle/`,
        csbmApiUrl: `${baseApiUrl}/component/adres/csbm/`,
        numaratajApiUrl: `${baseApiUrl}/component/adres/numarataj/`,
        sgIlceApiUrl: `${baseApiUrl}/component/adres/ilce/`,
        sgMahalleApiUrl: `${baseApiUrl}/component/adres/mahalle/`,
        sgCsbmApiUrl: `${baseApiUrl}/component/adres/csbm/`,
        cpNumaratajApiUrl: `${baseApiUrl}/component/adres/numarataj/`,
      };
      kadastroApiUrl = {
        ilceApiUrl: `${baseApiUrl}/component/kadastro/ilce/${province?.id}`,
        mahalleApiUrl: `${baseApiUrl}/component/kadastro/mahalle/`,
        adaApiUrl: `${baseApiUrl}/component/kadastro/ada/`,
        numaratajApiUrl: `${baseApiUrl}/component/kadastro/numarataj/`,
        sgParselApiUrl: `${baseApiUrl}/component/kadastro/parsel/`,
        parselDetailsApiUrl: `${baseApiUrl}/component/kadastro/parsel/query/geometry/location`,
      };
      poiApiUrl = `${baseApiUrl}/component/poi/`;
      serviceDirectory = {
        hierarchicalData: `${baseApiUrl}/service-directory/hierarchical-data`,
      };
      parsel = { id: 147, title: "Megsis - Parsel" };
      dataSourceApiUrl = {
        pagedSidApiUrl: `${baseApiUrl}/data-source/paged/`,
        spatialFilterApiUrl: `${baseApiUrl}/data-source/feature/filter/spatial`,
        queryFilterApiUrl: `${baseApiUrl}/data-source/feature/filter/query`,
      };
      megsisIntegrationApiUrl = {
        checkIslemParselGeometryApiUrl: `//megsis-cbs-api-test.tapu.icisleri.gov.ct.tr/harita-onizleme/check-islem-parsel-geometry`,
        checkIslemParselGeometryWKomsuAdaApiUrl: `//megsis-cbs-api-test.tapu.icisleri.gov.ct.tr/harita-onizleme/check-islem-parsel-geometry-w-komsu-ada`,
      };
      projectApi = {
        projecsUrl: `${baseApiUrl}/project/get`,
      };
      exportDataApi = {
        exportDataUrl: `${baseApiUrl}/geometry/export`,
      };
      break;

    case "cbs.mersin.bel.tr":
      logo = mbbLogo;
      defaultLanguage = "tr";
      baseApiUrl = "//cbs-api.mersin.bel.tr";
      redirectUrl = {
        userMaganerUrl: "//cbs-kullanici-yonetim.mersin.bel.tr",
        catalogUrl: "//webgis-catalog.mersin.bel.tr/",
        cebsUrl: "//cebs.mersin.bel.tr/",
      };
      map = {
        epsg: "EPSG:32636",
        wkid: 32636,
        center: [621701.86, 4077461.25],
        zoom: 9,
        minZoom: 7,
        maxZoom: 19,
      };
      appName = "Viewer-mersin";
      province = { id: 33, ad: "Mersin" };
      searchMode = "other";
      baseLayerApiUrl = "//cbs-proxy.mersin.bel.tr/gisserver/token/services/";
      userApiUrl = `//cbs-sso.mersin.bel.tr/token/`;
      checkTokenValidityApi = `//cbs-sso.mersin.bel.tr/token/control-token-u?t=`;
      getTokenDetailsApi = `//cbs-sso.mersin.bel.tr/token/control-token-ud?t=`;
      adresApiUrl = {
        ilceApiUrl: `${baseApiUrl}/component/adres/ilce/${province?.id}`,
        mahalleApiUrl: `${baseApiUrl}/component/adres/mahalle/`,
        csbmApiUrl: `${baseApiUrl}/component/adres/csbm/`,
        numaratajApiUrl: `${baseApiUrl}/component/adres/numarataj/`,
        sgIlceApiUrl: `${baseApiUrl}/component/adres/ilce/`,
        sgMahalleApiUrl: `${baseApiUrl}/component/adres/mahalle/`,
        sgCsbmApiUrl: `${baseApiUrl}/component/adres/csbm/`,
        cpNumaratajApiUrl: `${baseApiUrl}/component/adres/numarataj/`,
      };
      kadastroApiUrl = {
        ilceApiUrl: `${baseApiUrl}/component/kadastro/ilce/${province?.id}`,
        mahalleApiUrl: `${baseApiUrl}/component/kadastro/mahalle/`,
        adaApiUrl: `${baseApiUrl}/component/kadastro/ada/`,
        numaratajApiUrl: `${baseApiUrl}/component/kadastro/numarataj/`,
        sgParselApiUrl: `${baseApiUrl}/component/kadastro/parsel/`,
        parselDetailsApiUrl: `${baseApiUrl}/component/kadastro/parsel/query/geometry/location`,
      };
      serviceDirectory = {
        hierarchicalData: `${baseApiUrl}/service-directory/hierarchical-data`,
      };
      poiApiUrl = `${baseApiUrl}/component/poi/`;
      parsel = { id: 147, title: "Megsis - Parsel" };
      dataSourceApiUrl = {
        pagedSidApiUrl: `${baseApiUrl}/data-source/paged/`,
        spatialFilterApiUrl: `${baseApiUrl}/data-source/feature/filter/spatial`,
        queryFilterApiUrl: `${baseApiUrl}/data-source/feature/filter/query`,
      };
      megsisIntegrationApiUrl = {
        checkIslemParselGeometryApiUrl: `//megsis-cbs-api-test.tapu.icisleri.gov.ct.tr/harita-onizleme/check-islem-parsel-geometry`,
        checkIslemParselGeometryWKomsuAdaApiUrl: `//megsis-cbs-api-test.tapu.icisleri.gov.ct.tr/harita-onizleme/check-islem-parsel-geometry-w-komsu-ada`,
      };
      projectApi = {
        projecsUrl: `${baseApiUrl}/project/get`,
      };
      exportDataApi = {
        exportDataUrl: `${baseApiUrl}/geometry/export`,
      };
      break;

    case "localhost.mersin.bel.tr:3000":
    case "cbs-test.mersin.bel.tr":
      logo = mbbLogo;
      defaultLanguage = "tr";
      baseApiUrl = "//cbs-api-test.mersin.bel.tr";
      // baseApiUrl = "http://localhost:5216";
      redirectUrl = {
        userMaganerUrl: "//cbs-kullanici-yonetim-test.mersin.bel.tr",
        catalogUrl: "//webgis-catalog-test.mersin.bel.tr/",
        cebsUrl: "//cebs.mersin.bel.tr/",
      };
      map = {
        epsg: "EPSG:32636",
        wkid: 32636,
        center: [551701.86, 4077461.25],
        zoom: 10,
        minZoom: 7,
        maxZoom: 19,
      };
      appName = "Viewer-mersin";
      province = { id: 33, ad: "Mersin" };
      searchMode = "other";
      baseLayerApiUrl =
        "//cbs-proxy-test.mersin.bel.tr/gisserver/token/services/";
      userApiUrl = `//cbs-sso-test.mersin.bel.tr/token/`;
      checkTokenValidityApi = `//cbs-sso-test.mersin.bel.tr/token/control-token-u?t=`;
      getTokenDetailsApi = `//cbs-sso-test.mersin.bel.tr/token/control-token-ud?t=`;
      adresApiUrl = {
        ilceApiUrl: `${baseApiUrl}/component/adres/ilce/${province?.id}`,
        mahalleApiUrl: `${baseApiUrl}/component/adres/mahalle/`,
        csbmApiUrl: `${baseApiUrl}/component/adres/csbm/`,
        numaratajApiUrl: `${baseApiUrl}/component/adres/numarataj/`,
        sgIlceApiUrl: `${baseApiUrl}/component/adres/ilce/`,
        sgMahalleApiUrl: `${baseApiUrl}/component/adres/mahalle/`,
        sgCsbmApiUrl: `${baseApiUrl}/component/adres/csbm/`,
        cpNumaratajApiUrl: `${baseApiUrl}/component/adres/numarataj/`,
      };
      kadastroApiUrl = {
        ilceApiUrl: `${baseApiUrl}/component/kadastro/ilce/${province?.id}`,
        mahalleApiUrl: `${baseApiUrl}/component/kadastro/mahalle/`,
        adaApiUrl: `${baseApiUrl}/component/kadastro/ada/`,
        numaratajApiUrl: `${baseApiUrl}/component/kadastro/numarataj/`,
        sgParselApiUrl: `${baseApiUrl}/component/kadastro/parsel/`,
        parselDetailsApiUrl: `${baseApiUrl}/component/kadastro/parsel/query/geometry/location`,
      };
      serviceDirectory = {
        hierarchicalData: `${baseApiUrl}/service-directory/hierarchical-data`,
      };
      poiApiUrl = `${baseApiUrl}/component/poi/`;
      parsel = { id: 214, title: "CEBS - Parsel" };
      dataSourceApiUrl = {
        pagedSidApiUrl: `${baseApiUrl}/data-source/paged/`,
        spatialFilterApiUrl: `${baseApiUrl}/data-source/feature/filter/spatial`,
        queryFilterApiUrl: `${baseApiUrl}/data-source/feature/filter/query`,
      };
      megsisIntegrationApiUrl = {
        checkIslemParselGeometryApiUrl: `//megsis-cbs-api-test.tapu.icisleri.gov.ct.tr/harita-onizleme/check-islem-parsel-geometry`,
        checkIslemParselGeometryWKomsuAdaApiUrl: `//megsis-cbs-api-test.tapu.icisleri.gov.ct.tr/harita-onizleme/check-islem-parsel-geometry-w-komsu-ada`,
      };
      projectApi = {
        projecsUrl: `${baseApiUrl}/project/get`,
      };
      exportDataApi = {
        exportDataUrl: `${baseApiUrl}/geometry/export`,
      };
      break;

    case "uys-cbs.kgm.gov.tr":
      loginPictures = kgmLoginPictures;
      logo = kgmLogo;
      defaultLanguage = "tr";
      baseApiUrl = "//uys-cbs-api.kgm.gov.tr";
      redirectUrl = {
        userMaganerUrl: "//uys-um.kgm.gov.tr",
        catalogUrl: "//uys-catalog.kgm.gov.tr/",
        uysURL: "//uys-test.kgm.gov.tr",
        cebsUrl: "",
      };
      map = {
        epsg: "EPSG:100001",
        wkid: "100001",
        center: [-200000.67, 1600000.34],
        zoom: 7,
        minZoom: 6,
        maxZoom: 19,
      };
      appName = "UYS-CBS";
      searchMode = "kgm";
      baseLayerApiUrl = "//uys-proxy.kgm.gov.tr/gisserver/token/services/";
      userApiUrl = `//uys-sso.kgm.gov.tr/token/`;
      checkTokenValidityApi = `//uys-sso.kgm.gov.tr/token/control-token-u?t=`;
      getTokenDetailsApi = `//uys-sso.kgm.gov.tr/token/control-token-ud?t=`;
      serviceDirectory = {
        hierarchicalData: `${baseApiUrl}/service-directory/hierarchical-data`,
      };
      dataSourceApiUrl = {
        pagedSidApiUrl: `${baseApiUrl}/data-source/paged/`,
        spatialFilterApiUrl: `${baseApiUrl}/data-source/feature/filter/spatial`,
        queryFilterApiUrl: `${baseApiUrl}/data-source/feature/filter/query`,
      };
      megsisIntegrationApiUrl = {
        checkIslemParselGeometryApiUrl: `//megsis-cbs-api-test.tapu.icisleri.gov.ct.tr/harita-onizleme/check-islem-parsel-geometry`,
        checkIslemParselGeometryWKomsuAdaApiUrl: `//megsis-cbs-api-test.tapu.icisleri.gov.ct.tr/harita-onizleme/check-islem-parsel-geometry-w-komsu-ada`,
      };
      projectApi = {
        projecsUrl: `${baseApiUrl}/project/get`,
      };
      exportDataApi = {
        exportDataUrl: `${baseApiUrl}/geometry/export`,
      };

      kgmRegionRolesHead = `KGM.Bolge`;
      kgmCatalogLayers = {
        kgmInventeroyCommonLayer: {
          layerName: "uys-v_genel_envanter_shape",
          wmsId: 110,
        },
        kgmInventeroyCutLayer: {
          layerName: "uys-envanter_kesim",
          wmsId: 89,
        },
        kgmRSPFIRILayer: {
          layerName: "uys-rsp_firi",
          wmtsId: 99,
          wmsId: 67,
          wfsId: 92,
          wmsOverlayLayerName: "uys-v_rsp_firi_uys_cbs_overlay",
          wmsOverlayLayerId: 96,
        },
        kgmRSPIRILayer: {
          layerName: "uys-rsp_iri",
          wmtsId: 98,
          wmsId: 66,
          wfsId: 93,
          wmsOverlayLayerName: "uys-v_rsp_iri_uys_cbs_overlay",
          wmsOverlayLayerId: 95,
        },
        kgmRSPGPSLayer: {
          layerName: "uys-rsp_gps",
          wmtsId: 100,
          wmsId: 68,
          wfsId: 94,
          wmsOverlayLayerName: "uys-v_rsp_gps_uys_cbs_overlay",
          wmsOverlayLayerId: 97,
        },
        kgmFWDLayer: {
          layerName: "uys-fwd_station",
          wmtsId: "",
          wmsId: 91,
          wfsId: "",
          wmsOverlayLayerName: "uys-v_fwd_station_overlay",
          wmsOverlayLayerId: 101,
        },
        kgmDropsAnalyzedFWDLayer: {
          layerName: "uys-fwd_drops_analyzed",
          wmtsId: "",
          wmsId: 90,
          wfsId: "",
          wmsOverlayLayerName: "uys-v_fwd_drops_analyzed_overlay",
          wmsOverlayLayerId: 102,
        },
        kgmSKSLayer: {
          layerName: "uys-sks",
          wmtsId: "",
          wmsId: 215,
          wfsId: "",
          wmsOverlayLayerName: "uys-v_sks_uys_cbs_overlay",
          wmsOverlayLayerId: 252,
        },
        kgmSelectedKarayolLayer: 62,
        kgmKarayolLayer: 63,
      };

      break;

    case "localhost.kgm.gov.tr:3000":
    case "localhost.kgm.gov.tr:3001":
    case "uys-cbs-test.kgm.gov.tr":
      loginPictures = kgmLoginPictures;
      logo = kgmLogo;
      defaultLanguage = "tr";
      baseApiUrl = "//uys-cbs-api-test.kgm.gov.tr";
      uysApiUrl = "//uys-api-test.kgm.gov.tr";
      //baseApiUrl = "http://localhost:49154";
      redirectUrl = {
        userMaganerUrl: "//uys-um-test.kgm.gov.tr",
        catalogUrl: "//uys-catalog-test.kgm.gov.tr/",
        uysURL: "//uys-test.kgm.gov.tr",
        cebsUrl: "",
      };
      map = {
        // epsg: "EPSG:100001",
        // wkid: 100001,
        // center: [-200000.67, 1600000.34],
        // zoom: 7,
        epsg: "EPSG:3857",
        wkid: 3857,
        center: [3947819.636873, 4748879.693301],
        zoom: 7,
        minZoom: 6,
        maxZoom: 19,
      };
      appName = "UYS-CBS";
      baseLayerApiUrl = "//uys-proxy-test.kgm.gov.tr/gisserver/token/services/";
      searchMode = "kgm";
      userApiUrl = `//uys-sso-test.kgm.gov.tr/token/`;
      checkTokenValidityApi = `//uys-sso-test.kgm.gov.tr/token/control-token-u?t=`;
      getTokenDetailsApi = `//uys-sso-test.kgm.gov.tr/token/control-token-ud?t=`;
      serviceDirectory = {
        hierarchicalData: `${baseApiUrl}/service-directory/hierarchical-data`,
      };
      dataSourceApiUrl = {
        pagedSidApiUrl: `${baseApiUrl}/data-source/paged/`,
        spatialFilterApiUrl: `${baseApiUrl}/data-source/feature/filter/spatial`,
        queryFilterApiUrl: `${baseApiUrl}/data-source/feature/filter/query`,
      };
      megsisIntegrationApiUrl = {
        checkIslemParselGeometryApiUrl: `//megsis-cbs-api-test.tapu.icisleri.gov.ct.tr/harita-onizleme/check-islem-parsel-geometry`,
        checkIslemParselGeometryWKomsuAdaApiUrl: `//megsis-cbs-api-test.tapu.icisleri.gov.ct.tr/harita-onizleme/check-islem-parsel-geometry-w-komsu-ada`,
      };
      roadApi = {
        bolgeUrl: uysApiUrl + "/component/karayolu/bolge",
        karayoluUrlAll: uysApiUrl + "/component/karayolu/kkno/all/",
        karayoluUrl: uysApiUrl + "/component/karayolu/kkno/",
      };
      projectApi = {
        projecsUrl: `${baseApiUrl}/project/get`,
      };
      exportDataApi = {
        exportDataUrl: `${baseApiUrl}/geometry/export`,
      };

      kgmRegionRolesHead = `KGM.Bolge`;
      kgmCatalogLayers = {
        kgmInventeroyCommonLayer: {
          layerName: "uys-v_genel_envanter_shape",
          wmsId: 110,
        },
        kgmInventeroyCutLayer: {
          layerName: "uys-envanter_kesim",
          wmsId: 89,
        },
        kgmRSPFIRILayer: {
          layerName: "uys-rsp_firi",
          wmtsId: 99,
          wmsId: 67,
          wfsId: 92,
          wmsOverlayLayerName: "uys-v_rsp_firi_uys_cbs_overlay",
          wmsOverlayLayerId: 96,
        },
        kgmRSPIRILayer: {
          layerName: "uys-rsp_iri",
          wmtsId: 98,
          wmsId: 66,
          wfsId: 93,
          wmsOverlayLayerName: "uys-v_rsp_iri_uys_cbs_overlay",
          wmsOverlayLayerId: 95,
        },
        kgmRSPGPSLayer: {
          layerName: "uys-rsp_gps",
          wmtsId: 100,
          wmsId: 68,
          wfsId: 94,
          wmsOverlayLayerName: "uys-v_rsp_gps_uys_cbs_overlay",
          wmsOverlayLayerId: 97,
        },
        kgmFWDLayer: {
          layerName: "uys-fwd_station",
          wmtsId: "",
          wmsId: 91,
          wfsId: "",
          wmsOverlayLayerName: "uys-v_fwd_station_overlay",
          wmsOverlayLayerId: 101,
        },
        kgmDropsAnalyzedFWDLayer: {
          layerName: "uys-fwd_drops_analyzed",
          wmtsId: "",
          wmsId: 90,
          wfsId: "",
          wmsOverlayLayerName: "uys-v_fwd_drops_analyzed_overlay",
          wmsOverlayLayerId: 102,
        },
        kgmSKSLayer: {
          layerName: "uys-sks",
          wmtsId: "",
          wmsId: 215,
          wfsId: "",
          wmsOverlayLayerName: "uys-v_sks_uys_cbs_overlay",
          wmsOverlayLayerId: 252,
        },
        kgmSelectedKarayolLayer: 62,
        kgmKarayolLayer: 63,
      };

      break;
    case "megsis-webgis-viewer.tapu.icisleri.gov.ct.tr":
      loginPictures = kktcLoginPictures;
      logo = kktcLogo;
      captchaKey = "6Lc5jpglAAAAAAlj-yZpZ_R0SfJh_qutW5Uqih5U";
      defaultLanguage = "tr";
      baseApiUrl = "//megsis-webgis-api.tapu.icisleri.gov.ct.tr";
      province = { id: -1, ad: "KKTC" };
      parsel = { id: 2, title: "Megsis - Parsel" };
      //baseApiUrl = "http://localhost:49154";
      redirectUrl = {
        userMaganerUrl: "//megsis-webgis-viewer.tapu.icisleri.gov.ct.tr",
        catalogUrl: "//megsis-webgis-catalog.tapu.icisleri.gov.ct.tr/",
        uysURL: "",
        cebsUrl: "",
      };
      adresApiUrl = {
        ilceApiUrl: "", // `${baseApiUrl}/component/adres/ilce/${province?.id}`,
        mahalleApiUrl: "", //`${baseApiUrl}/component/adres/mahalle/`,
        csbmApiUrl: "", //`${baseApiUrl}/component/adres/csbm/`,
        numaratajApiUrl: "", //`${baseApiUrl}/component/adres/numarataj/`,
        sgIlceApiUrl: "", //`${baseApiUrl}/component/adres/ilce/`,
        sgMahalleApiUrl: "", // `${baseApiUrl}/component/adres/mahalle/`,
        sgCsbmApiUrl: "", // `${baseApiUrl}/component/adres/csbm/`,
        cpNumaratajApiUrl: "", //`${baseApiUrl}/component/adres/numarataj/`,
      };
      kadastroApiUrl = {
        ilceApiUrl: `${baseApiUrl}/component/kadastro/ilce/${province?.id}`,
        mahalleApiUrl: `${baseApiUrl}/component/kadastro/mahalle/`,
        adaApiUrl: `${baseApiUrl}/component/kadastro/ada/`,
        numaratajApiUrl: "", //`${baseApiUrl}/component/kadastro/numarataj/`,
        sgParselApiUrl: `${baseApiUrl}/component/kadastro/parsel/`,
      };
      map = {
        // epsg: "EPSG:100001",
        // wkid: 100001,
        // center: [-200000.67, 1600000.34],
        // zoom: 7,
        // epsg: "EPSG:3857",
        // wkid: 3857,
        // center: [3947819.636873, 4748879.693301],
        // zoom: 7,
        // minZoom: 6,
        // maxZoom: 19,
        epsg: "EPSG:4326",
        wkid: 4326,
        center: [33.3176, 35.2616],
        extent: [32.706, 35.003, 34.6191, 35.7108],
        zoom: 9.5,
        minZoom: 2,
        maxZoom: 21,
      };
      appName = "KKTC-WEBGIS-VIEWER";

      baseLayerApiUrl =
        "//megsis-proxy.icisleri.gov.ct.tr/gisserver/token/services/";
      searchMode = "kktc";
      userApiUrl = `//megsis-sso.tapu.icisleri.gov.ct.tr/token/`;
      checkTokenValidityApi = `//megsis-sso.tapu.icisleri.gov.ct.tr/token/control-token-u?f=true&t=`;
      getTokenDetailsApi = `//megsis-sso.tapu.icisleri.gov.ct.tr/token/control-token-ud?f=true&t=`;
      serviceDirectory = {
        hierarchicalData: `${baseApiUrl}/service-directory/hierarchical-data`,
      };
      dataSourceApiUrl = {
        pagedSidApiUrl: `${baseApiUrl}/data-source/paged/`,
        spatialFilterApiUrl: `${baseApiUrl}/data-source/feature/filter/spatial`,
        queryFilterApiUrl: `${baseApiUrl}/data-source/feature/filter/query`,
      };
      megsisIntegrationApiUrl = {
        checkIslemParselGeometryApiUrl: `${baseApiUrl}/harita-onizleme/check-islem-parsel-geometry`,
      };
      roadApi = {
        bolgeUrl: "",
        karayoluUrl: "",
      };
      projectApi = {
        projecsUrl: `${baseApiUrl}/project/get`,
      };
      exportDataApi = {
        exportDataUrl: `${baseApiUrl}/geometry/export`,
      };

      kgmRegionRolesHead = ``;
      kgmCatalogLayers = {};

      break;
    case "localhost.tapu.icisleri.gov.ct.tr:3000":
    case "localhost.tapu.icisleri.gov.ct.tr":
    case "localhost.icisleri.gov.ct.tr:3000":
    case "megsis-webgis-viewer-test.tapu.icisleri.gov.ct.tr":
      loginPictures = kktcLoginPictures;
      logo = kktcLogo;
      defaultLanguage = "tr";
      captchaKey = "6Lc5jpglAAAAAAlj-yZpZ_R0SfJh_qutW5Uqih5U";
      baseApiUrl = "//megsis-webgis-api-test.tapu.icisleri.gov.ct.tr";
      //baseApiUrl = "http://localhost:49154";
      province = { id: -1, ad: "KKTC" };
      parsel = { id: 2, title: "Megsis - Parsel" };
      redirectUrl = {
        userMaganerUrl: "//megsis-webgis-viewer-test.tapu.icisleri.gov.ct.tr",
        catalogUrl: "//megsis-webgis-catalog-test.tapu.icisleri.gov.ct.tr/",
        uysURL: "",
        cebsUrl: "",
      };
      adresApiUrl = {
        ilceApiUrl: "", // `${baseApiUrl}/component/adres/ilce/${province?.id}`,
        mahalleApiUrl: "", //`${baseApiUrl}/component/adres/mahalle/`,
        csbmApiUrl: "", //`${baseApiUrl}/component/adres/csbm/`,
        numaratajApiUrl: "", //`${baseApiUrl}/component/adres/numarataj/`,
        sgIlceApiUrl: "", //`${baseApiUrl}/component/adres/ilce/`,
        sgMahalleApiUrl: "", // `${baseApiUrl}/component/adres/mahalle/`,
        sgCsbmApiUrl: "", // `${baseApiUrl}/component/adres/csbm/`,
        cpNumaratajApiUrl: "", //`${baseApiUrl}/component/adres/numarataj/`,
      };
      kadastroApiUrl = {
        ilceApiUrl: `${baseApiUrl}/component/kadastro/ilce/${province?.id}`,
        mahalleApiUrl: `${baseApiUrl}/component/kadastro/mahalle/`,
        adaApiUrl: `${baseApiUrl}/component/kadastro/ada/`,
        numaratajApiUrl: "", //`${baseApiUrl}/component/kadastro/numarataj/`,
        sgParselApiUrl: `${baseApiUrl}/component/kadastro/parsel/`,
      };
      map = {
        // epsg: "EPSG:100001",
        // wkid: 100001,
        // center: [-200000.67, 1600000.34],
        // zoom: 7,
        // epsg: "EPSG:3857",
        // wkid: 3857,
        // center: [3947819.636873, 4748879.693301],
        // zoom: 7,
        // minZoom: 6,
        // maxZoom: 19,
        epsg: "EPSG:4326",
        wkid: 4326,
        center: [33.3176, 35.2616],
        extent: [32.706, 35.003, 34.6191, 35.7108],
        zoom: 9.5,
        minZoom: 2,
        maxZoom: 21,
      };
      appName = "KKTC-WEBGIS-VIEWER";
      baseLayerApiUrl =
        "//megsis-proxy-test.tapu.icisleri.gov.ct.tr/gisserver/token/services/";
      searchMode = "kktc";
      userApiUrl = `//megsis-sso-test.tapu.icisleri.gov.ct.tr/token/`;
      checkTokenValidityApi = `//megsis-sso-test.tapu.icisleri.gov.ct.tr/token/control-token-u?f=true&t=`;
      getTokenDetailsApi = `//megsis-sso-test.tapu.icisleri.gov.ct.tr/token/control-token-ud?f=true&t=`;
      serviceDirectory = {
        hierarchicalData: `${baseApiUrl}/service-directory/hierarchical-data`,
      };
      dataSourceApiUrl = {
        pagedSidApiUrl: `${baseApiUrl}/data-source/paged/`,
        spatialFilterApiUrl: `${baseApiUrl}/data-source/feature/filter/spatial`,
        queryFilterApiUrl: `${baseApiUrl}/data-source/feature/filter/query`,
      };
      megsisIntegrationApiUrl = {
        checkIslemParselGeometryApiUrl: `//megsis-cbs-api-test.tapu.icisleri.gov.ct.tr/harita-onizleme/check-islem-parsel-geometry`,
        checkIslemParselGeometryWKomsuAdaApiUrl: `//megsis-cbs-api-test.tapu.icisleri.gov.ct.tr/harita-onizleme/check-islem-parsel-geometry-w-komsu-ada`,
      };
      roadApi = {
        bolgeUrl: "",
        karayoluUrl: "",
      };
      projectApi = {
        projecsUrl: `${baseApiUrl}/project/get`,
      };
      exportDataApi = {
        exportDataUrl: `${baseApiUrl}/geometry/export`,
      };

      kgmRegionRolesHead = ``;
      kgmCatalogLayers = {};

      break;

    case "viewer-test.piri1513.com.tr":
      logo = "";
      defaultLanguage = "tr";
      baseApiUrl = "//webgis-api-test.piri1513.com.tr";
      redirectUrl = {
        userMaganerUrl: "",
        catalogUrl: "//catalog-test.piri1513.com.tr/",
        cebsUrl: "//",
      };
      map = {
        epsg: "EPSG:5254",
        wkid: 5254,
        center: [501588.9, 4511119.42],
        zoom: 9,
        minZoom: 7,
        maxZoom: 19,
      };
      appName = "Viewer-piri1513";
      province = { id: 41, ad: "Kocaeli" };
      searchMode = "other";
      baseLayerApiUrl =
        "//proxy-test.piri1513.com.tr/gisserver/token/services/";
      userApiUrl = `//sso-test.piri1513.com.tr/token/`;
      checkTokenValidityApi = `//sso-test.piri1513.com.tr/token/control-token-u?t=`;
      getTokenDetailsApi = `//sso-test.kocaeli.bel.tr/token/control-token-ud?t=`;
      adresApiUrl = {
        ilceApiUrl: `${baseApiUrl}/component/adres/ilce/${province?.id}`,
        mahalleApiUrl: `${baseApiUrl}/component/adres/mahalle/`,
        csbmApiUrl: `${baseApiUrl}/component/adres/csbm/`,
        numaratajApiUrl: `${baseApiUrl}/component/adres/numarataj/`,
        sgIlceApiUrl: `${baseApiUrl}/component/adres/ilce/`,
        sgMahalleApiUrl: `${baseApiUrl}/component/adres/mahalle/`,
        sgCsbmApiUrl: `${baseApiUrl}/component/adres/csbm/`,
        cpNumaratajApiUrl: `${baseApiUrl}/component/adres/numarataj/`,
      };
      kadastroApiUrl = {
        ilceApiUrl: `${baseApiUrl}/component/kadastro/ilce/${province?.id}`,
        mahalleApiUrl: `${baseApiUrl}/component/kadastro/mahalle/`,
        adaApiUrl: `${baseApiUrl}/component/kadastro/ada/`,
        numaratajApiUrl: `${baseApiUrl}/component/kadastro/numarataj/`,
        sgParselApiUrl: `${baseApiUrl}/component/kadastro/parsel/`,
        parselDetailsApiUrl: `${baseApiUrl}/component/kadastro/parsel/query/geometry/location`,
      };
      poiApiUrl = `${baseApiUrl}/component/poi/`;
      serviceDirectory = {
        hierarchicalData: `${baseApiUrl}/service-directory/hierarchical-data`,
      };
      parsel = { id: 147, title: "Megsis - Parsel" };
      dataSourceApiUrl = {
        pagedSidApiUrl: `${baseApiUrl}/data-source/paged/`,
        spatialFilterApiUrl: `${baseApiUrl}/data-source/feature/filter/spatial`,
        queryFilterApiUrl: `${baseApiUrl}/data-source/feature/filter/query`,
      };
      megsisIntegrationApiUrl = {
        checkIslemParselGeometryApiUrl: `//`,
        checkIslemParselGeometryWKomsuAdaApiUrl: `//`,
      };
      projectApi = {
        projecsUrl: `${baseApiUrl}/project/get`,
      };
      exportDataApi = {
        exportDataUrl: `${baseApiUrl}/geometry/export`,
      };
      break;

    default: // { id: 2058, ad: "Başiskele" };
      break;
  }

  return {
    logo,
    defaultLanguage,
    searchMode,
    map,
    baseApiUrl,
    appName,
    province,
    checkTokenValidityApi,
    getTokenDetailsApi,
    userApiUrl,
    district,
    adresApiUrl,
    kadastroApiUrl,
    serviceDirectory,
    baseLayerApiUrl,
    parsel,
    dataSourceApiUrl,
    redirectUrl,
    roadApi,
    projectApi,
    poiApiUrl,
    exportDataApi,
    exportDataUrl,
    kgmCatalogLayers,
    kgmRegionRolesHead,
    captchaKey,
    uysApiUrl,
    loginPictures,
    megsisIntegrationApiUrl,
  };
};

export const {
  logo,
  defaultLanguage,
  map,
  baseApiUrl,
  appName,
  checkTokenValidityApi,
  getTokenDetailsApi,
  userApiUrl,
  searchMode,
  province,
  district,
  adresApiUrl,
  kadastroApiUrl,
  serviceDirectory,
  baseLayerApiUrl,
  parsel,
  dataSourceApiUrl,
  redirectUrl,
  roadApi,
  projectApi,
  poiApiUrl,
  exportDataApi,
  exportDataUrl,
  kgmCatalogLayers,
  kgmRegionRolesHead,
  captchaKey,
  uysApiUrl,
  loginPictures,
  megsisIntegrationApiUrl,
} = Config();
