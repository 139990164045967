
import { olMap } from "../components/map/mapView";
import { setProjectPreview } from "../services/getProjectPreview";

export const ScreenshotProject = (selectedProject) => {
    const mapCanvas = document.createElement("canvas");
    const size = olMap.getSize();
    mapCanvas.width = size[0];
    mapCanvas.height = size[1];
    const mapContext = mapCanvas.getContext("2d");
    Array.prototype.forEach.call(
        document.querySelectorAll(
            ".ol-layers div canvas,.react-geo-measure-tooltip.react-geo-measure-tooltip-static"
        ),
        function (canvas, all) {
            if (canvas.width > 200) {
                const opacity = canvas.parentNode.style.opacity;
                mapContext.globalAlpha = opacity === "" ? 1 : Number(opacity);
                const transform = canvas.style.transform;

                const matrix = transform
                    .match(/^matrix\(([^\(]*)\)$/)[1]
                    .split(",")
                    .map(Number);

                CanvasRenderingContext2D.prototype.setTransform.apply(
                    mapContext,
                    matrix
                );
                mapContext.drawImage(canvas, 0, 0);

                const measureOverlays = [
                    ...document.querySelectorAll(
                        ".ol-overlay-container.ol-selectable"
                    ),
                ].filter((overlay) =>
                    overlay.firstChild.className.includes(
                        "react-geo-measure-tooltip-static"
                    )
                );

                measureOverlays.forEach((overlay) => {
                    const overlayTransformPxArr = overlay.style.transform
                        .split(") ")[1]
                        .split(",")
                        .map((px) => px.match(/\d/g).join(""));

                    const textWidth = mapContext.measureText(overlay.innerText).width;

                    mapContext.fillStyle = "rgba(255, 255, 255, 0.5)";
                    mapContext.fillRect(
                        overlayTransformPxArr[0] - 5,
                        overlayTransformPxArr[1] - 12,
                        textWidth > 60 ? textWidth + 20 : 60,
                        17
                    );

                    mapContext.font = "12px Arial";
                    mapContext.fillStyle = "black";
                    mapContext.fillText(
                        overlay.innerText,
                        overlayTransformPxArr[0],
                        overlayTransformPxArr[1]
                    );
                });
            }
        }
    );
    mapCanvas.toBlob(
        function (blob) {
            setProjectPreview(selectedProject.id, blob);
        }
    )
}
