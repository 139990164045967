import { TravelExplore } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Fab,
  Grid,
  TextField,
  Toolbar,
  Tooltip,
} from "@mui/material";
import { map } from "../config";
import { t } from "i18next";
import { useState } from "react";
import { olMap } from "./map/mapView";
import { CenterFocusWeak } from "@mui/icons-material";


const MapCoordinatePicker = () => {
  const currentMapWKID = map.wkid;
  const mapCenterCoordinates = map.center;
  const mapZoom = map.zoom;

  const xLabel = currentMapWKID === 4326 ? t("Lon") : "X";
  const yLabel = currentMapWKID === 4326 ? t("Lat") : "Y";

  const [valueX,setValueX] = useState(undefined)
  const [valueY,setValueY] = useState(undefined)

  const handleChangeValueX = (e) => {
    setValueX(e.target.value)
  }

  const handleChangeValueY = (e) => {
    setValueY(e.target.value)
  }

  const onClickZoomOnMap = () => {
    olMap.getView().animate({
      center: [valueX,valueY],
      zoom: 19,
      duration: 500, 
    });
  }

  const onClickZoomOnMapCenter = () => {
    olMap.getView().animate({
      center: mapCenterCoordinates,
      zoom: mapZoom,
      duration: 500, 
    });
  }

  return (
    <Box sx={{ flexGrow: 1 }} style={styles.mainBox}>
      <Grid container spacing={0}>
        <Grid item xs={1}>
            <Tooltip title={t("ZoomToNativeResolution")}>
              <Fab
                style={styles.centerButton}
                variant="contained"
                size="small"              
                color="primary"
                onClick={onClickZoomOnMapCenter}
              ><CenterFocusWeak /></Fab>
            </Tooltip>
            
          </Grid>
        <Grid item xs={10}>
          <AppBar position="static" style={styles.appBar}>
            <Toolbar style={{minHeight:"1em",margin:"0.25em"}}>
              <TextField
                id="outlined-number"
                placeholder={xLabel}
                type="number"
                variant="standard"
                size="small"
                onChange={handleChangeValueX}
              />
              <TextField
                id="outlined-number"
                placeholder={yLabel}
                type="number"
                variant="standard"
                size="small"
                style={{ marginLeft:"1em" }}
                onChange={handleChangeValueY}
              />
            </Toolbar>
          </AppBar>
        </Grid>
        <Grid item xs={1}>
          <Tooltip title={t("GoToCoordinate")}>
            <Fab
              style={styles.zoomButton}
              variant="contained"
              size="small"
              disabled={!valueX || !valueY || valueX?.length<2 || valueY.length<2}
              color="primary"
              onClick={onClickZoomOnMap}
            ><TravelExplore /></Fab>
          </Tooltip>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MapCoordinatePicker;

const styles = {
  mainBox: {
    position: "absolute",
    width: "30%",
    right: "25%",
    top: "1em",
    zIndex: "2",    
  },
  appBar: {
    backgroundColor: "white",    
  },
  zoomButton: {
    color: "white",
    height: "100%",
    borderRadius: "0em 2em 2em 0px",
  },
  centerButton: {
    color: "white",
    height: "100%",
    borderRadius: "2em 0em 0em 2em",
  },
};
