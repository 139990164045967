import { Grid } from "@mui/material";
import { Typography } from "@mui/material";
import { Box } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { Modal } from "@mui/material";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { projectApi } from "../config";
import { setProjectPreview, getAllProjectPreview } from "../services/getProjectPreview";
import { MainContext } from "../context/mainContext";
import { ScreenshotProject } from "../utils/screenshotProject";
import { getServiceDescriptionById } from "../services/getServiceDescription";
import { olMap, unvisibleLayer, visibleLayer } from "../components/map/mapView";
import { LeftPanelContext } from "../context/leftPanelContext";
import { deleteAllLayers } from "../utils/getFeatureLayer";
import { useTranslation } from 'react-i18next';

const ProjectModal = ({ }) => {
    const { t, i18n } = useTranslation();

    const [projects, setProjects] = useState([]);
    const [imgSource, setImageSoruce] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const { isOpenProjectModal, setIsOpenProjectModal, selectedProject, setSelectedProject } = useContext(MainContext);
    const {
        treeNodeIds, setToggle
    } = useContext(LeftPanelContext);

    useEffect(() => {
        if (isOpenProjectModal) {
            axios.get(projectApi?.projecsUrl).then(res => {
                setProjects(res.data);
                setIsLoading(false)
            }).catch(err => { throw err });

            if (selectedProject.id !== 0) {
                treeNodeIds?.map(layerNodeId => unvisibleLayer(layerNodeId))
                setToggle([])
                deleteAllLayers()
                // unvisibleLayer(selectedProject.bmapid)
                // getServiceDescriptionById(selectedProject.id).then(x => {
                //     if(x){
                //     }
                // })
            }
        }

    }, [isOpenProjectModal])

    useEffect(() => {
        if (projects.length > 0) {
            getAllProjectPreview().then(res => {
                if (res.length > 0) {
                    res.forEach(item => {
                        const reader = new FileReader()
                        if (reader) {
                            reader?.readAsDataURL(item?.preview)
                            reader.onloadend = () => {
                                setImageSoruce(prev => ({ ...prev, [item?.projectId]: reader.result }))
                            }
                            reader.onerror = () => { setImageSoruce(prev => ({ ...prev, [item?.projectId]: "projectPreview.png" })) };
                        }

                    })
                }
                else {
                    projects.forEach(item => {
                        setImageSoruce(prev => ({ ...prev, [item.id]: "projectPreview.png" }))
                    })
                }
            })
        }
    }, [projects])


    // useEffect(() => {
    //     if (selectedProject.id !== 0) {
    //         ScreenshotProject(selectedProject);
    //     }
    // }, [selectedProject])

    const handleSelectProject = (project) => {
        setSelectedProject(project);
        setIsOpenProjectModal(false);
        document.title = `Viewer - ${project.name}`;
    }

    return <Modal open={isOpenProjectModal}>
        <Box sx={{ ...boxStyle }}>
            <Grid sx={{ ...boxHeader }}>
                <Typography variant="h4">{t("OpenProject")}</Typography>
            </Grid>
            <Grid sx={{ ...boxBody }} container justifyContent={"space-evenly"} alignItems={"center"}>
                {isLoading ? <CircularProgress />
                    : projects.map(project => (
                        <Box sx={{ ...projectBox }} key={project.id} onClick={() => handleSelectProject(project)}>
                            <Grid>
                                <Box sx={{ ...borderStyle }}>
                                    <img src={imgSource[project.id] || "projectPreview.png"} width={"100%"} height={150} style={{ ...borderStyle }}>
                                    </img>
                                </Box>
                            </Grid>
                            <Grid>
                                <Typography variant="h5" align="center">{project.name}</Typography>
                            </Grid>
                        </Box>
                    ))}
            </Grid>
            <Grid sx={{ ...boxFooter }}>
                <Typography variant="caption">{t("ProjectSelect")}</Typography>
            </Grid>
        </Box>
    </Modal>
}
const boxStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    bgcolor: "background.paper",
    boxShadow: 15,
    display: "flex",
    flexDirection: "column",
    borderRadius: 2
};

const boxHeader = {
    pl: 1,
    pt: 1,
    pr: 1,
    borderBottom: "1px solid rgba(0, 0, 0, 0.25)"
}

const boxBody = {
    minHeight: 250,
    maxHeight: 500,
    overflowY: "auto",
    pt: 2,
    borderBottom: "1px solid rgba(0, 0, 0, 0.25)"
}

const boxFooter = {
    p: 1
}

const projectBox = {
    width: 200,
    height: 200,
    // border: "1px solid rgba(0, 0, 0, 0.25)",
    borderRadius: 2,
    my: 2,
    boxShadow: 5,
    cursor: "pointer"
}

const borderStyle = {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
}


export default ProjectModal;