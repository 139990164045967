import { Draw as DrawInteraction } from "ol/interaction";

const cleanupDraw = (map) => {
	//OVERLAYS
	let overlayToRemove = [];
	map.getOverlays().forEach((overlay) => {
		if (
			overlay?.get("element")?.className.startsWith("react-geo-measure-tooltip") //"react-geo-measure-tooltip react-geo-measure-tooltip-static"
		) {
			overlayToRemove.push(overlay);
		}
	});

	for (var j = 0; j < overlayToRemove.length; j++) {
		// console.log("all removed overlays:", overlayToRemove);
		map.removeOverlay(overlayToRemove[j]);
	}

	//LAYERS
	map.getLayers().forEach((layer) => {
		if (layer?.get("name") === "measure-popup-layer") {
			map.removeLayer(layer);
		}
	});
	//INTERACTIONS EXTRACTED
};

const cleanInteractions = (map) => {
	map.getInteractions().forEach((interaction) => {
		if (interaction instanceof DrawInteraction) {
			interaction.setActive(false);
			//map.removeInteraction(interaction); //react-geo_drawaction
		}
	});
};

const toggleInteractions = (map) => {
	const interactions = map.getInteractions();
	interactions.forEach((interaction) => {
		if (interaction instanceof DrawInteraction) {
			interaction.setActive(!interaction.getActive());

			if (!interaction.getActive()) {
				map.removeInteraction(interaction);
			}
		}
	});
};

export { cleanupDraw, cleanInteractions, toggleInteractions };
