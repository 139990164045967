import Map from "ol/Map";
import View from "ol/View";

// source
import XYZ from "ol/source/XYZ";
import VectorSource from "ol/source/Vector";
import ImageWMS from "ol/source/ImageWMS";
import WMTS from "ol/source/WMTS";
import BingMaps from "ol/source/BingMaps";
import { ImageArcGISRest } from "ol/source";
// layer
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import ImageLayer from "ol/layer/Image";
// proj
import { register } from "ol/proj/proj4";
import * as proj from "ol/proj";
import proj4 from "proj4";

import { MousePosition, ScaleLine } from "ol/control";
import { createStringXY } from "ol/coordinate";
import { getWidth, getTopLeft } from "ol/extent";

import { Fill, Stroke, Circle } from "ol/style";
import Style from "ol/style/Style";
import ExtentInteraction from "ol/interaction/Extent";
import PointerInteraction from "ol/interaction/Pointer";
import WMTSTileGrid from "ol/tilegrid/WMTS";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";


// ViewerProductLib
import { ServiceType } from "../../data/serviceType";
import { getServiceDescriptionById } from "../../services/getServiceDescription";
import { getCredentials, getToken } from "../../utils/auth";
import {
  map,
  baseLayerApiUrl,
  searchMode,
  appName,
  kgmRegionRolesHead,
} from "../../config";
import { Progress } from "../../utils/mapLoadEvent";

//Geojson
import GeoJSON from "ol/format/GeoJSON";

proj4.defs(
  "EPSG:5255",
  "+proj=tmerc +lat_0=0 +lon_0=33 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs"
);

proj4.defs(
  "EPSG:5254",
  "+proj=tmerc +lat_0=0 +lon_0=30 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
);

proj4.defs("EPSG:32636", "+proj=utm +zone=36 +datum=WGS84 +units=m +no_defs  ");
proj4.defs(
  "EPSG:100001",
  "+proj=lcc +lat_1=37.5 +lat_2=40.5 +lat_0=25 +lon_0=36 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs"
);

register(proj4);
const pointerLayer = new VectorLayer({
  source: new VectorSource(),
  style: new Style({
    image: new Circle({
      radius: 6,
      fill: new Fill({
        color: [0, 153, 255, 1],
      }),
      stroke: new Stroke({
        color: [255, 255, 255, 1],
        width: 1.5,
      }),
    }),
  }),
  zIndex: 10,
  className: "point",
});

// 🌡️ Measure line
const measureControl = new ScaleLine({
  className: "ol-scale-line",
  units: "metric",
  minWidth: 60,
  steps: 100,
});
// 🖱️ Mouse Control
const mousePositionControl = new MousePosition({
  placeholder: "Koordinatlar",
  // undefinedHTML: "Coordinates",
  coordinateFormat: createStringXY(2),
  className: "ol-control ol-mouse-control",
  target: document.getElementById("ol-mouse-control"),
  projection: map.epsg,
});

export const extentInteraction = new ExtentInteraction({
  condition: (evt) => {
    if (["wheel", "dblclick"].includes(evt.type)) {
      return false;
    }

    return true;
  },
  boxStyle: new Style({
    fill: new Fill({
      color: "#29b6f61A",
    }),
    stroke: new Stroke({
      color: "#29b6f6",
      width: 1,
    }),
  }),
});

const openStreetMap = new TileLayer({
  source: new XYZ({
    url: "https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    crossOrigin: "Anonymous",
  }),
});

export const olMap = new Map({
  target: "map",
  layers: [pointerLayer],
  view: new View({
    center: proj.fromLonLat(
      proj.transform(map?.center, map?.epsg, "EPSG:4326"),
      map.epsg
    ),
    zoom: map.zoom,
    minZoom: map.minZoom,
    maxZoom: map.maxZoom,
    projection: map.epsg,
  }),
  controls: [measureControl, mousePositionControl],
});

export const handleExtentInteraction = (shouldAdd = true) => {
  if (shouldAdd) {
    olMap.addInteraction(extentInteraction);
  } else {
    olMap.removeInteraction(extentInteraction);
  }
};

export const getPointFeature = (coords) =>
  new Feature({ geometry: new Point(coords) });

let pointFeature, isPointDown, targetElement;
export const pointerInteraction = new PointerInteraction({
  handleMoveEvent: (evt) => {
    if (!pointerLayer.getSource().getFeatures().length) {
      pointFeature = getPointFeature(evt.coordinate);
      pointerLayer.getSource().addFeature(pointFeature);
    } else {
      !isPointDown && pointFeature.getGeometry().setCoordinates(evt.coordinate);
    }
    const feature = evt.map.forEachFeatureAtPixel(
      evt.pixel,
      function (feature) {
        return feature;
      }
    );

    targetElement = evt.map.getTargetElement();

    if (targetElement && feature && isPointDown) {
      targetElement.style.cursor = "pointer";
    } else {
      targetElement.style.cursor = "";
    }
  },
  handleDragEvent: (evt) => {
    targetElement.style.cursor = "move";
    isPointDown = false;
  },
  handleUpEvent: (evt) => {
    // 🔄 custom dispatch event: on coordinate change
    isPointDown &&
      pointerInteraction.dispatchEvent({
        target: evt,
        type: "change:coords",
      });
  },
  handleDownEvent: (evt) => {
    if (pointFeature) {
      isPointDown = true;
    }

    const feature = evt.map.forEachFeatureAtPixel(
      evt.pixel,
      function (feature) {
        return feature;
      }
    );
    return !!feature;
  },
});

export const handlePointerInteraction = (shouldAdd = true) => {
  if (shouldAdd) {
    olMap.addInteraction(pointerInteraction);
  } else {
    olMap.removeInteraction(pointerInteraction);
    isPointDown = false;
    pointerLayer.getSource().removeFeature(pointFeature);
  }
};

//* PROJEKSİYON İŞLEMLERİ

const getProjectionExtent = (projectionName) => {
  const projection = proj.get(projectionName);
  const projectionExtent = projection.getExtent();
  return projectionExtent;
};

const setProjection = (proj) => {
  olMap.setView(
    new View({
      center: map.center,
      projection: proj,
      zoom: map.zoom,
    })
  );
};

const addProjection = (coordinateSystem) => {
  proj4.defs(`EPSG:${coordinateSystem.wkid}`, coordinateSystem.proj4);

  if (coordinateSystem.extent) {
    const defProj = new proj.Projection({
      code: `EPSG:${coordinateSystem.wkid}`,
      units: "m",
      extent: JSON.parse(coordinateSystem.extent),
    });
    proj.addProjection(defProj);
    register(proj4);
  } else {
    const defProj = new proj.Projection({
      code: `EPSG:${coordinateSystem.wkid}`,
      units: "m",
    });
    proj.addProjection(defProj);
    register(proj4);
  }
};

const getProjection = () => {
  return olMap.getView().getProjection().getCode();
};

//* KATMAN İŞLEMLERİ

export const addDbLayers = (data, force, bmap,searchValue) => {
  let layer;
  switch (data?.serviceType) {
    case ServiceType.WMS:
    case ServiceType.QueryableWMS:
    case ServiceType.EditableWMS:
    case ServiceType.PublicWMS:
      layer = addWMSLayer(data,undefined,searchValue);
      break;
    case ServiceType.WMTS:
    case ServiceType.PublicWMTS:
      layer = addWMTSLayer(data);
      break;
    case ServiceType.OSM:
      layer = addOSMLayer(data);
      break;
    case ServiceType.BingMap:
      layer = addBingMapLayer(data);
      break;
    case ServiceType.ArcgisRest:
    case ServiceType.QueryableArcgisRest:
    case ServiceType.EditableArcgisRest:
      layer = addArcgisRestLayer(data);
      break;
    default:
      break;
  }

  force ? layer?.setVisible(true) : layer?.setVisible(false);
  const isLayerExist = getLayer(data?.id, "");
  const layerList = olMap.getLayers();

  //* Eğer katman zaten eklenmişse eklemiyoruz
  if (isLayerExist === undefined) {
    if (bmap) {
      layer && layerList.insertAt(0, layer);
    } else {
      layer && layerList.insertAt(1, layer);
    }
  }
};

const handleWMSCQLFilterSettings = (cqlFilterParameter, cqlFilterColumns,searchValue) => {
  switch (searchMode) {
    case "kgm": // KGM modülü için yalnızca bolge_no değeri var
      const userRoles = sessionStorage[appName + ".UserRoles"]?.split(",");
      if (!userRoles) break;

      let returnValue = "";
      
      if (cqlFilterParameter && searchValue?.region) {
        returnValue = cqlFilterParameter + " AND " + cqlFilterColumns[0] + " IN ("+ searchValue?.region + ")";
      }
      else if (!cqlFilterParameter && searchValue?.region) {
        returnValue = cqlFilterColumns[0] + " IN ("+ searchValue?.region + ")";
      }
      else if (!searchValue?.region) {
        returnValue = "1=0";
      }
      else returnValue = "1=0";
      return returnValue;
    default:
      break;
  }

  // Viewer Çalıştırılan Bölge Filtresi
};

export const addWMSLayer = (data, cqlFilterParameter = "",searchValue=undefined) => {
  let shouldItBeUptadedBasedOnSearchValue = false;
  if (data?.settings && JSON?.parse(data?.settings)?.cqlFilterColumns) {
    cqlFilterParameter = handleWMSCQLFilterSettings(
      cqlFilterParameter,
      JSON?.parse(data?.settings).cqlFilterColumns,
      searchValue
    );
    if (searchValue) shouldItBeUptadedBasedOnSearchValue = true;
  }
  const source = new ImageWMS({
    url: baseLayerApiUrl + data?.id,
    params: {
      t: getToken(),
      LAYERS: data.mapServiceLayerName,
      VERSION: data?.serviceVersion ? data?.serviceVersion : "1.1.1",
      CQL_FILTER: cqlFilterParameter,
    },
    crossOrigin: "Anonymous",
  });

  const progress = new Progress();
  source.on("imageloadstart", function () {
    progress.addLoading();
  });
  source.on(["imageloadend", "imageloaderror"], function () {
    progress.addLoaded();
  });

  const layer = new ImageLayer({
    source: source,
    title: data?.id,
    shouldItBeUptadedBasedOnSearchValue:shouldItBeUptadedBasedOnSearchValue // Bu katmanın search value değerine göre güncellenmesi gerekiyorsa dikkate alınır
  });

  return layer;
};

const addWMTSLayer = (data) => {
  const settings = JSON.parse(data.settings);
  const projection = proj.get(settings?.matrixName);
  const projExtent = getProjectionExtent(settings?.matrixName);
  const size =
    getWidth(projExtent ? projExtent : JSON.parse(data.extent)) /
    settings?.tileSize[0];

  const resolutions = new Array(26);
  const matrixIds = new Array(26);

  for (let i = 0; i < 26; i++) {
    resolutions[i] = size / Math.pow(2, i);
    matrixIds[i] = settings?.matrixName + ":" + i;
  }
  const tileGrid = new WMTSTileGrid({
    origin: getTopLeft(projExtent ? projExtent : JSON.parse(data.extent)),
    resolutions: resolutions,
    matrixIds: matrixIds,
  });
  const token = getToken();
  const source = new WMTS({
    crossOrigin: "Anonymous",
    layer: data?.mapServiceLayerName,
    url: baseLayerApiUrl + data?.id + `?t=${token}`,
    matrixSet: settings?.matrixName,
    format: settings?.tileFormat,
    projection: projection,
    style: "_null",
    tileGrid: tileGrid,
  });

  const progress = new Progress();
  source.on("tileloadstart", function () {
    progress.addLoading();
  });
  source.on(["tileloadend", "tileloaderror"], function () {
    progress.addLoaded();
  });

  const layer = new TileLayer({
    source: source,
    title: data?.id,
  });

  return layer;
};

const addOSMLayer = (data) => {
  const source = new XYZ({
    url: "https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    crossOrigin: "Anonymous",
  });

  const progress = new Progress();
  source.on("tileloadstart", function () {
    progress.addLoading();
  });
  source.on(["tileloadend", "tileloaderror"], function () {
    progress.addLoaded();
  });

  const layer = new TileLayer({
    source: source,
    title: data?.id,
  });
  return layer;
};

const addBingMapLayer = (data) => {
  const settings = JSON.parse(data.settings);
  const source = new BingMaps({
    key: settings?.key,
    imagerySet: settings?.imagerySet,
    crossOrigin: "Anonymous",
  });

  const progress = new Progress();
  source.on("tileloadstart", function () {
    progress.addLoading();
  });
  source.on(["tileloadend", "tileloaderror"], function () {
    progress.addLoaded();
  });

  const layer = new TileLayer({
    source: source,
    title: data?.id,
  });

  return layer;
};

const addArcgisRestLayer = (data) => {
  const source = new ImageArcGISRest({
    url: baseLayerApiUrl + data?.id + "/MapServer",
    params: {
      t: getToken(),
      BBOXSR: data.wkid,
      IMAGESR: data.wkid,
    },
    crossOrigin: "anonymous",
  });

  const progress = new Progress();
  source.on("imageloadstart", function () {
    progress.addLoading();
  });
  source.on(["imageloadend, imageloaderror"], function () {
    progress.addLoaded();
  });

  const layer = new ImageLayer({
    source: source,
    title: data?.id,
  });

  const tlf = layer.getSource().getImageLoadFunction();
  var ntlf = function (image, src) {
    src = src.replace("/MapServer", "");
    return tlf(image, src);
  };
  layer.getSource().setImageLoadFunction(ntlf);

  return layer;
};

//* KATMAN GÖRÜNÜRLÜK İŞLEMLERİ

const getLayer = (id) => {
  let layer;
  olMap.getLayers().forEach((element, index, array) => {
    let title = element.get("title");
    if (title == id) {
      layer = element;
    }
  });
  return layer;
};

export const reverseVisibleLayer = (id) => {
  const layer = getLayer(id);
  if (layer) {
    layer.setVisible(layer.getVisible())
  }
}

export const visibleLayer = (id) => {
  const layer = getLayer(id);
  if (layer) {
    layer.setVisible(true);
  }
};

export const unvisibleLayer = (id) => {
  const layer = getLayer(id);
  layer && layer.setVisible(false);
};

export const setLayerOpacity = (id, opacity) => {
  const layer = getLayer(id);
  layer && layer.setOpacity(opacity / 100);
};

export const addGeojsonLocalLayer = (
  GeojsonObject,
  localLayerColor,
  layerId
) => {
  const image = new Circle({
    radius: 3,
    fill: new Fill({ color: localLayerColor }),
    stroke: new Stroke({ color: localLayerColor, width: 1 }),
  });

  const styles = {
    Point: new Style({
      image: image,
    }),
    LineString: new Style({
      stroke: new Stroke({
        color: localLayerColor,
        width: 2,
      }),
    }),
    MultiLineString: new Style({
      stroke: new Stroke({
        color: localLayerColor,
        width: 2,
      }),
    }),
    MultiPoint: new Style({
      image: image,
    }),
    MultiPolygon: new Style({
      stroke: new Stroke({
        color: localLayerColor,
        width: 2,
      }),
      fill: new Fill({
        color: localLayerColor,
      }),
    }),
    Polygon: new Style({
      stroke: new Stroke({
        color: localLayerColor,
        lineDash: [4],
        width: 3,
      }),
      fill: new Fill({
        color: localLayerColor,
      }),
    }),
    GeometryCollection: new Style({
      stroke: new Stroke({
        color: localLayerColor,
        width: 2,
      }),
      fill: new Fill({
        color: localLayerColor,
      }),
      image: new Circle({
        radius: 10,
        fill: null,
        stroke: new Stroke({
          color: localLayerColor,
        }),
      }),
    }),
    Circle: new Style({
      stroke: new Stroke({
        color: localLayerColor,
        width: 2,
      }),
      fill: new Fill({
        color: localLayerColor,
      }),
    }),
  };

  const vectorSource = new VectorSource({
    features: new GeoJSON().readFeatures(GeojsonObject, {
      featureProjection: map.epsg,
    }),
  });

  const styleFunction = function (feature) {
    return styles[feature.getGeometry().getType()];
  };

  const vectorLayer = new VectorLayer({
    className: "localLayer",
    source: vectorSource,
    title: layerId,
    style: styleFunction,
    visible: false,
  });
  const layerList = olMap.getLayers();

  layerList.push(vectorLayer);
};
