import { Home, SignalCellularNullSharp } from "@mui/icons-material";
import { Button, Tooltip } from "@mui/material";

export default function ToolbarButton({
	disabled = false,
	title = "",
	color = "primary",
	icon = <Home fontSize="small" />,
	backgroundColor = "",
	onClick = () => {},
	tooltipPlacement = "right",
}) {
	return (
		<Tooltip placement={tooltipPlacement} title={title}>
			<Button
				disabled = {disabled}
				size="small"
				onClick={onClick}
				color={!backgroundColor ? color : "white"}
				sx={{
					p: 2,
					width: 24,
					height: 36,
					// minWidth: "fit-content",
					bgcolor: backgroundColor,
					":hover": {
						color: !backgroundColor ? "secondary.main" : "white",
						bgcolor: !backgroundColor ? null : backgroundColor,
					},
				}}
			>
				{icon}
			</Button>
		</Tooltip>
	);
}
