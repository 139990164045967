import React, { useState, createContext } from 'react';

export const MainContext = createContext();


export const MainContextProvider = ({ children }) => {

    const [mainServiceToken,setMainServiceToken] = useState("");

    
    const [isOpenLoginModal, setIsOpenLoginModal] = useState(true);
    const [isOpenProjectModal, setIsOpenProjectModal] = useState(false);
    const [selectedProject, setSelectedProject] = useState({ id: 0, name: "", bmapid: 0 });
    const [isSearchPanelOpen,setIsSearchPanelOpen] = useState(true);
    const [legendPanelSRID, setLegendPanelSRID] = useState(null);
    const [rightPanelAction,setRightPanelAction] = useState({}); // Sağ Panelde Yapılan Özel İşlem Adı (Sağ Paneli Aktifleştirir)
    const [rightPanelQueryOnMap,setRightPanelQueryOnMap] = useState(false);
    const [isRightOverlayOpen, setIsRightOverlayOpen] = useState(false);

    const [isIFrame,setIsIFrame] = useState(false); //Viewer ın Iframe olarak çalışması durumları (Örn: UYS de ölçüm katmanı için overlayi açar)
    const [iFrameRegion,setIFrameRegion] = useState(false); //Viewer ın Iframe olarak çalışması durumları (Örn: UYS de ölçüm katmanı için overlayi açar)
    
    const [userRoles,setUserRoles] = useState([]);

    const [alertBoxSituation,setAlertBoxSituation] = useState({
        isOpen:false,
        type:"warning",
        message:null
    });

    const props = {
        isOpenLoginModal, setIsOpenLoginModal,
        isOpenProjectModal, setIsOpenProjectModal,
        selectedProject, setSelectedProject,
        isSearchPanelOpen,setIsSearchPanelOpen,
        legendPanelSRID, setLegendPanelSRID,
        rightPanelAction,setRightPanelAction,
        rightPanelQueryOnMap,setRightPanelQueryOnMap,
        isRightOverlayOpen, setIsRightOverlayOpen,
        isIFrame,setIsIFrame,
        iFrameRegion,setIFrameRegion,
        userRoles,setUserRoles,
        mainServiceToken,setMainServiceToken,
        alertBoxSituation,setAlertBoxSituation,
    }
    return <MainContext.Provider value={props}>{children}</MainContext.Provider>
}