import {
    Box,
    Button,
    Modal,
    TextField,
    Typography,
    Checkbox,
    Grid,
    FormControlLabel,
    Paper,
  } from "@mui/material";
  import React, { Fragment, useContext, useEffect, useState } from "react";
  import { login, saveCredentials } from "../utils/auth";
  import axios from "axios";
  import { baseApiUrl, captchaKey, loginPictures, logo, redirectUrl, userApiUrl } from "../config";
  import { MainContext } from "../context/mainContext";
  import { Link } from "react-router-dom";
  import ReCaptcha from "react-google-recaptcha";
  
  
  const SigninModal = () => {
    
    const { isOpenLoginModal, setIsOpenLoginModal,setMainServiceToken } = useContext(MainContext);
  
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(false);
    
    const [verifyRecaptcha, setVerifyRecaptcha] = useState(captchaKey ? false : true);
  
  
    const onClose = () => {
      setIsOpenLoginModal(false);
    };
  
    const handleSubmit = async () => {
      try {
        const response = await axios(userApiUrl + "post-issue-token-ud", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          data: { u: name, p: password },
          withCredentials: true,
        });
        const data = await response.data;      
        saveCredentials({
          userGroups: data.userGroups,
          userId: data.userId,
          userName: data.userName,
          userRoles: data.userRoles,
        });

        axios.defaults.headers.common["token"] = data.token;
        axios.defaults.headers.common["userId"] = data.userId;
        setMainServiceToken(data.token);

        setName("");
        setPassword("");
        setError(false);
        onClose();
      } catch (error) {
        setError(true);
      }
    };
  
    return (
      <Fragment>
        <Modal
        open={isOpenLoginModal}      
        // onClose={onClose}  
        >
        <Paper >
          <Grid
            container
            spacing={1}
            direction={"column"}
            justify={"center"}
            alignItems={"center"}        
          >
            <Box sx={{ ...boxStyle }}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <img src={logo} alt={"logo"} style={{height:"150px"}}></img>
              </Box>
  
              <Typography
                align={"center"}
                sx={{ ...textStyle, mt: "5%" }}
                variant="h4"
              >
                Kullanıcı Girişi
              </Typography>
  
              <Grid item xs={12} style={{ padding: "1px" }}>
                <TextField
                  error={error}
                  variant="outlined"
                  label="Kullanıcı Adı"
                  sx={{ ...inputStyle }}
                  value={name}
                  onChange={(evt) => {
                    setName(evt.target.value);
                  }}
                  fullWidth
                ></TextField>
              </Grid>
  
              <Grid item xs={12} style={{ padding: "1px" }}>
                <TextField
                  error={error}
                  variant="outlined"
                  label="Şifre"
                  type="password"
                  sx={{ ...inputStyle }}
                  value={password}
                  fullWidth
                  onChange={(evt) => {
                    setPassword(evt.target.value);
                  }}
                ></TextField>
              </Grid>
  
                
                    
              {captchaKey && <ReCaptcha
                    style={{ margin: "auto"}}
                    sitekey={captchaKey}
                    //onChange={(value) => verifyRecaptchaPost(value)}
                    onChange={(value) => setVerifyRecaptcha(true)}
                />}
  
              <Box sx={{ display: "flex", justifyContent: "space-between"}}>            
  
                <Button
                  onClick={handleSubmit}
                  sx={{
                    ...buttonStyle,
                    backgroundColor: "#00c853",
                    "&:hover": { backgroundColor: "#02b14a" },
                    margin: "5px",
                  }}
                  disabled = {!verifyRecaptcha}
                >
                  Giriş
                </Button>
                <Button
                  variant="contained"
                  sx={{ ...buttonStyle, margin: "5px" }}
                  onClick={() => {
                    window.open(redirectUrl?.userMaganerUrl + "/kayit","_self");
                  }}
                >
                  Kayıt Ol
                </Button>
              </Box>
  
              <Box sx={{ mt: 2 }}>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    window.open(redirectUrl?.userMaganerUrl + "/sifre_unuttum","_self");
                  }}
                >
                  Şifremi Unuttum
                </span>
              </Box>
            </Box>
          </Grid>
        </Paper>
        </Modal>
      </Fragment>
    );
  };
  
  export default SigninModal;
  
  const boxStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 15,
    pt: 2,
    px: 4,
    pb: 3,
    display: "flex",
    flexDirection: "column",
    borderRadius: "5%",
    border: "5px solid lightGray",
  };
  
  const inputStyle = {
    my: 3,
  };
  
  const buttonStyle = {
    width: 130,
    color: "#fff",
  };
  
  const textStyle = {
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: 600,
    // fontSize: "1.875rem",
    color: "#595959",
  };
  