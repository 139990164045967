import { useState, useEffect, useContext } from "react";
import { useScreen } from "./hooks/useScreen";
import { Tabs, Tab, Typography } from "@mui/material";
import { RootHorizontal, MainGrid, SubGrid, GridHandle } from "./common/grid";
import DataTable from "./dataTable";
import dataJson from "../data/table.json";
import { Rnd } from "react-rnd";
import {
	PANEL_HEIGHT,
	PANEL_WIDTH,
	Z_TABLE_PANEL,
	Z_DRAG_HANDLE,
} from "../constants/constants";
import DisplayRow from "./displayRow";
import { TableContext } from "../context/tableContext";
import { t } from "i18next";
import { getServiceDescriptionById } from "../services/getServiceDescription";

export default function TablePanel({
	dragWidth,
	dragHeight,
	onDragHeightChange,
	serviceDescriptionId,
}) {
	const [tableName, setTableName] = useState(null);
	const [tabValue, setTabValue] = useState(0);

	const { selectedRow, filterAttributes } = useContext(TableContext);

	const { isMobile, height, width } = useScreen();

	useEffect(() => {
		filterAttributes.length === 0 && setTabValue(0);
	}, [filterAttributes]);

	useEffect( () => {
		getServiceDescriptionById(serviceDescriptionId).then(serviceDesc => {
			setTableName(serviceDesc?.name);
		})
	}, []);

	return (
		<Rnd
			default={{
				x: PANEL_WIDTH,
				y: height - PANEL_HEIGHT,
				width: selectedRow ? width - PANEL_WIDTH - 360 : width - PANEL_WIDTH,
				height: PANEL_HEIGHT,
			}}
			position={{ x: dragWidth, y: height - dragHeight }}
			size={{ width: width - dragWidth, height: dragHeight }}
			onResize={(e, direction, ref, delta, position) => {
				onDragHeightChange(ref.offsetHeight);
			}}
			onResizeStop={(e, direction, ref, delta, position) => {
				localStorage.setItem("dragHeight", parseInt(ref.style.height));
			}}
			disableDragging
			enableResizing={{ top: true, right: false, left: true }}
			resizeGrid={[10, 10]}
			minWidth={
				selectedRow
					? width - 360 - PANEL_WIDTH * 1.5
					: width - PANEL_WIDTH * 1.5
			}
			maxWidth={
				selectedRow
					? width - 430 - PANEL_WIDTH * 0.7
					: width - PANEL_WIDTH * 0.7
			}
			minHeight={50}
			maxHeight={PANEL_HEIGHT * 2}
			resizeHandleComponent={{
				top: (
					<GridHandle
						sx={{
							top: 1,
							left: (width - PANEL_WIDTH) / 2,
						}}
					/>
				),
			}}
			resizeHandleStyles={{
				left: { zIndex: Z_DRAG_HANDLE },
			}}
			style={{
				position: "fixed",
				zIndex: Z_TABLE_PANEL,
			}}
		>
			<RootHorizontal id="table-panel">
				<MainGrid
					container
					direction="row"
					justify="flex-start"
					sx={{ borderTop: 1, borderColor: "gray.main" }}
				>
					<SubGrid
						item
						sm={12}
						lg={12}
						id="json-data-1"
						justify="flex-start"
						alignContent="flex-start"
						sx={{ borderRight: "2px solid", borderColor: "gray.light" }}
					>
						<Tabs
							value={tabValue}
							onChange={(event, newValue) => setTabValue(newValue)}
							sx={{ height: "20px", minHeight: "25px" }}
						>
							<Tab label={tableName} sx={{ height: "20px", minHeight: "25px" }} disabled />
							<Tab label={t("Table")} sx={{ height: "20px", minHeight: "25px" }}  value={0}/>
							{filterAttributes?.length > 0 && (
								<Tab
									label="Seçilenler"
									sx={{ height: "20px", minHeight: "25px" }}
								/>
							)}
						</Tabs>

						<DataTable
							serviceDescriptionId={serviceDescriptionId}
							tabValue={tabValue}
						/>
					</SubGrid>
				</MainGrid>
			</RootHorizontal>
		</Rnd>
	);
}
