import axios from "axios";
import { extentInteraction, handleExtentInteraction, handlePointerInteraction, olMap, pointerInteraction, visibleLayer } from "../components/map/mapView";
import { checkTokenValidityApi, dataSourceApiUrl, map } from "../config";
import { deleteLayer, flashLayer, getLayer } from "../utils/getFeatureLayer";
import { getFeatureByWhereClause } from "../utils/webgisApiUtils/getFeatureByWhereClause";
import { sendWindowMessage } from "./messageServiceContext";
import WKT from "ol/format/WKT";
import Polygon from "ol/geom/Polygon";
import Point from "ol/geom/Point";
import { getServiceDescriptionById } from "../services/getServiceDescription";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";

const megsisIslemResponse = "megsisIslemResponse"
const megsisIslemResponseRemovedDataMessage = "RemovedData"

export const megsisMessageServiceContext = async (method,message,parameters,setToggle) => {  
    

    const visibleAndSetToogleLayer = (layerCatalogId) => {
        visibleLayer(parseInt(layerCatalogId));
        setToggle((prev) => ({
            ...prev,
            [parseInt(layerCatalogId)]: true,
          }));
    }

    switch (method) {
        case "megsisIslemRequest":
            
            const islemType = parameters?.islem;
            const primaryColumKey = "ref"
            const catalogLayers = {
                kaza : { //ilce
                    catalogId: "48",
                    name:"kazaLayer",
                    responseColumNames: ["ref","ad"]
                },
                kasaba : {
                    catalogId: "49",
                    name:"kasabaLayer",
                    responseColumNames: ["ref","ad"]
                },
                mahalle : {
                    catalogId: "24",
                    name:"mahalleLayer",
                    responseColumNames: ["ref","ad"]
                },
                ada : {
                    catalogId: "20",
                    name:"adaLayer",
                    responseColumNames: ["ref","ad"]
                },
                parsel : {
                    catalogId: "21",
                    name:"parselLayer",
                    responseColumNames: ["ref","ad"]
                },
                pafta : { //index
                    catalogId: "23",
                    name:"paftaLayer",
                    responseColumNames: ["ref","ad"]
                },
                dere : {
                    catalogId: "47",
                    name:"dereLayer",
                    responseColumNames: ["ref","ad"]
                },
                yol : {
                    catalogId: "46",
                    name:"yolLayer",
                    responseColumNames: ["ref","ad"]
                },
            }
            
            var conditionKey = "";
            var conditionValue = "";
            if (parameters.parselRef) {conditionKey = "parsel"; conditionValue = parameters.parselRef;}
            else if (parameters.adaNoRef) {conditionKey = "ada"; conditionValue = parameters.adaNoRef;}
            else if (parameters.mahalleRef) {conditionKey = "mahalle"; conditionValue = parameters.mahalleRef;}
            else if (parameters.kasabaRef) {conditionKey = "kasaba"; conditionValue = parameters.kasabaRef;}
            else if (parameters.ilceRef) {conditionKey = "ilce" ; conditionValue = parameters.ilceRef;}

            const zoomGeometry = await getFeatureByWhereClause(
                true,
                [],
                catalogLayers[conditionKey]?.catalogId,
                `${primaryColumKey} = ${conditionValue}`,
            );
            flashLayer(zoomGeometry?.[0]?.geometry)

            const selectedCatalogLayers = []

            handlePointerInteraction(false);
            handleExtentInteraction(false);
            switch (islemType) {
                case "1":
                    selectedCatalogLayers.push(catalogLayers.dere);
                    visibleAndSetToogleLayer(catalogLayers.dere.catalogId);
                    startPointerInteractionForCatalogLayers(selectedCatalogLayers);
                    break;
                case "2":
                    selectedCatalogLayers.push(catalogLayers.yol);
                    visibleAndSetToogleLayer(catalogLayers.yol.catalogId);
                    startPointerInteractionForCatalogLayers(selectedCatalogLayers);
                    break;
                case "3":
                    selectedCatalogLayers.push(catalogLayers.dere);
                    selectedCatalogLayers.push(catalogLayers.yol);
                    visibleAndSetToogleLayer(catalogLayers.dere.catalogId);
                    visibleAndSetToogleLayer(catalogLayers.yol.catalogId);
                    startPointerInteractionForCatalogLayers(selectedCatalogLayers);
                    break;
                case "4":
                    selectedCatalogLayers.push(catalogLayers.ada);
                    selectedCatalogLayers.push(catalogLayers.parsel);
                    visibleAndSetToogleLayer(catalogLayers.ada.catalogId);
                    visibleAndSetToogleLayer(catalogLayers.parsel.catalogId);
                    startPointerInteractionForCatalogLayers(selectedCatalogLayers);
                    break;
                case "5":
                    selectedCatalogLayers.push(catalogLayers.pafta);
                    visibleAndSetToogleLayer(catalogLayers.pafta.catalogId); 
                    startPointerInteractionForCatalogLayers(selectedCatalogLayers);
                    break;
                case "6":
                    handleExtentInteraction();
                    extentInteraction.on("extentchanged", (evt) => {                        
                        let  timer;
                        clearTimeout(timer);
                        timer = setTimeout(() => {             
                            sendWindowMessage(
                                megsisIslemResponse,
                                "",
                                {
                                    minX: evt.extent[0],
                                    minY: evt.extent[1],
                                    maxX: evt.extent[2],
                                    maxY: evt.extent[3],
                                }
                            )  
                        }, 100); //1000
                    });
                    break;
                default:
                    break;
            }
            break;
        default:
            break;
    }
}

const startPointerInteractionForCatalogLayers = (selectedCatalogLayers) => {
    handlePointerInteraction();
    pointerInteraction.on("change:coords", (evt) => {
        // console.log(evt.target.coordinate);
        selectedCatalogLayers.map((catalogLayer)=>fetchDataFromPoint(evt.target.coordinate,catalogLayer,"point")) 
        handlePointerInteraction(false);
        handlePointerInteraction();
    });
}

const getOptions = async (type,coordinates,catalogLayer) => {
    const service =  await getServiceDescriptionById(
        parseInt(catalogLayer.catalogId)
    );
    const options = {};
    options.layerId = 1;
    options.serviceDescriptionId = parseInt(catalogLayer.catalogId);
    options.whereClause = "";
    // options.buffer = 1;
    options.bufferUnit = "m";
    options.tolerance = 0.1; //0.01 
    options.geometry =
        type === "polygon"
            ? new WKT().writeGeometry(new Polygon([coordinates]))
            : new WKT().writeGeometry(new Point(coordinates));
    options.geometryColumn = "shape";
    options.wkid = map.wkid;
    options.toWKID = service.wkid;
    options.outputWKID = map.wkid;
    options.returnFields = true;
    options.returnGeometry = true;
    options.spatialRelationType = 5;

    return options;
};


const fetchDataFromPoint = async (coordinates,catalogLayer,geometryType="point") => {
    const postOptions = await getOptions(geometryType,coordinates,catalogLayer);    
    axios(dataSourceApiUrl.spatialFilterApiUrl, {
        method: "post",
        data: postOptions,
    })
        .then((res) => {
            if (res.data.length > 0)
             {
                const data = res.data[0]
                const dataAttributesObject = data?.attributes

                const features = new WKT().readFeatures(data.geometry);

                const source = new VectorSource({
                    features: features,
                    crossOrigin: "Anonymous",
                });

                const extent = source.getExtent()
                const coordinates = {
                    minX: extent[0],
                    minY: extent[1],
                    maxX: extent[2],
                    maxY: extent[3],
                }

                const responseParameters = {
                    ...filterObjectByKeys(dataAttributesObject,catalogLayer?.responseColumNames),
                    extent:coordinates
                }
                                
                // flashLayer(data.geometry)                
                
                const isAddOrRemove = addOrRemoveFeature(
                    data.geometry,
                    dataAttributesObject[catalogLayer?.responseColumNames[1]],
                    catalogLayer.name + dataAttributesObject[catalogLayer?.responseColumNames[0]] ,
                    coordinates
                );
                
                
                responseParameters["layerName"] = catalogLayer?.name;
                sendWindowMessage(
                    megsisIslemResponse,
                    isAddOrRemove ? "" : megsisIslemResponseRemovedDataMessage,
                    responseParameters,
                )
             }
        })
        .catch((err) => {
            throw err;
        });
};

const filterObjectByKeys = (obj, keys) => {
    const filteredObject = {};
    keys.forEach(key => {
      if (obj.hasOwnProperty(key)) {
        filteredObject[key] = obj[key];
      }
    });
    return filteredObject;
  }

const addOrRemoveFeature = (geometry,name,className,extentCoordinates) => {

    const removeLayer = olMap
    .getLayers()
    .getArray()
    .filter((x) => x.className_ === className);

    if (removeLayer.length>0) {
        deleteLayer(className)
        return false
    }
    else {
        getLayer(geometry,name,className);
        return true
    }
}

//   megsis uygulaması tarafından	
	
//   method Adı : 	megsisIslemRequest
//   parametreler : 	islem
//       ilceRef
//       kasabaRef
//       mahalleRef
//       adaNoRef
//       parselRef
  