import { Divider, Stack } from "@mui/material";
import { SEARCH_TOOLBAR_BUTTONS, Z_TOOLBAR } from "../constants/constants";
import ToolbarButton from "./common/toolbarButton";
import { searchMode, district, province } from "../config";
import { deleteLayer } from "../utils/getFeatureLayer";
import { useContext } from "react";
import { LeftPanelContext } from "../context/leftPanelContext";
import { MainContext } from "../context/mainContext";
import removeAllRightPanelMeasurementLayers from "./rightPanelActions/rightPanelMeasurementComponents/rightPanelUtils/rightPanelMeasurementFunctions";
import { useTranslation } from 'react-i18next';
import { removeAllPMSAnalysisLayers } from "./rightPanelActions/rightPanelPMSAnalysis/rightPanelPMSAnalysisLayers";
import { removeInventoryLayer } from "./rightPanelActions/rightPanelInventoryComponents/rightPanelInventoryData";

export default function SearchModeToolbar({ dragWidth }) {
  const { t, i18n } = useTranslation();
  const { setSearchMode, setSearchValue } = useContext(LeftPanelContext);
  const { setRightPanelAction, setRightPanelQueryOnMap } =
    useContext(MainContext);

  const clearSearch = () => {
    if (district) {
      setSearchValue({ province: province?.id, district: district?.id });
    } else {
      setSearchValue({ province: province?.id });
    }
    
	// For KGM layers and Right Panel Actions
  removeAllRightPanelMeasurementLayers(); // GPS IRI FIRI FWD DAFWD
  removeAllPMSAnalysisLayers();
  removeInventoryLayer();
	deleteLayer("yol-layer");
	deleteLayer("inventory-cut-layer");
    setRightPanelAction({});
    setRightPanelQueryOnMap(false);
  };
  return (
    <Stack
      sx={{
        position: "absolute",
        left: `${dragWidth}px`,
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
        boxShadow: 3,
        background: "#fff",
        zIndex: Z_TOOLBAR,
      }}
    >
      {SEARCH_TOOLBAR_BUTTONS[searchMode].map((button, i) => (
        <div key={i}>
          <ToolbarButton
            onClick={() => {
              button.mode === -1 ? clearSearch() : setSearchMode(button.mode);
            }}
            title= {t(button.title)}
            icon={button.icon}
          />
          {i < SEARCH_TOOLBAR_BUTTONS.length - 1 && <Divider />}
        </div>
      ))}
    </Stack>
  );
}
