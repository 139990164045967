import {
  Avatar,
  FormControl,
  Input,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  StepIcon,
} from "@mui/material";

import Close from "@mui/icons-material/Close";
import ToolbarButton from "./common/toolbarButton";
import { Z_TOOLBAR } from "../constants/constants";

import React, { useState, useEffect, useContext } from "react";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { getAllCoordinateSystem } from "../services/getCoordinateSystem";
import { LeftPanelContext } from "../context/leftPanelContext";
import { exportDataApi, map } from "../config";
import { CloudDownload } from "@mui/icons-material";
import { getAllLayersFromTree } from "../utils/getAllLayersFromTree";
import { FindInPage } from "@mui/icons-material";
import axios from "axios";
import { TableContainer } from "@mui/material";
import { TableHead } from "@mui/material";
import { TableRow } from "@mui/material";
import { TableCell } from "@mui/material";
import { Table } from "@mui/material";
import { TableBody } from "@mui/material";
import { t } from "i18next";

const DownloadLayerButton = ({ dragWidth }) => {
  const { treeNodeIds, treeData } = useContext(LeftPanelContext);

  const [modalOpen, setModalOpen] = useState(false);
  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => {
    setFileFormat("");
    setModalOpen(false);
  };

  const [allLayers, setAllLayers] = useState({});
  const [selectedLayer, setSelectedLayer] = useState({});
  const [fileFormat, setFileFormat] = useState("GeoJson");
  const [fileCoordinateSystem, setFileCoordinateSystem] = useState(map?.wkid);
  const [allCoordinateSystem, setAllCoordinateSystem] = useState();

  const [outputGeojson, setOutputGeojson] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (modalOpen) {
      getAllCoordinateSystem().then((res) => setAllCoordinateSystem(res));
      setAllLayers(
        getAllLayersFromTree(treeData).filter(
          (layer) => layer?.operationType > 2
        )
      );
    }

  }, [modalOpen]);

  const fetchGeoJsonDetails = () => {
    setLoading(true);
    axios(exportDataApi.exportDataUrl, {
      method: "post",
      data: {
        serviceDescriptionId: parseInt(selectedLayer?.id),
        returnFields: true,
        outputWKID: fileCoordinateSystem,
      },
    })
      .then((res) => {
        res.data["crs"] = {
          type: "name",
          properties: {
            name: "urn:ogc:def:crs:EPSG::" + String(fileCoordinateSystem),
          },
        };
        setOutputGeojson(res.data);
        setLoading(false);
        // var element = document.createElement('a');
        // element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(JSON.stringify(data)));
        // element.setAttribute('download', selectedLayer?.title + ".geojson");
        // element.style.display = 'none';
        // document.body.appendChild(element);
        // element.click();
        // document.body.removeChild(element);
      })
      .catch((err) => {
        setLoading(false);
        throw err;
      });
  };

  const handleDownloadFile = () => {    
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(JSON.stringify(outputGeojson)));
    element.setAttribute('download', selectedLayer.title + ".geojson");
    element.style.display = 'none';
    document.body.appendChild(element);                    
    element.click();                  
    document.body.removeChild(element);
  }

  return (
    <div>
      <Modal
        keepMounted
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={{ ...style }}>
          <Grid container style={{ marginBottom: "25px" }}>
            <Grid item md={10}>
              {" "}
              <h2>{t("ExportLayer")}</h2>
            </Grid>

            <Grid item md={2}>
              <Button
                style={{
                  float: "right",
                  borderRadius: "50%",
                  minWidth: "0px",
                  marginTop: "15px",
                }}
                onClick={handleModalClose}
              >
                <Close />
              </Button>
            </Grid>
          </Grid>

          {/* Katman Adı */}
          <Grid container style={{ justifyContent: "space-between" }}>
            <Grid item md={4}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">{t("Layer")}</InputLabel>
                <Select
                  value={selectedLayer?.id}
                  label="Katman"
                  onChange={(value) => {
                    setSelectedLayer(
                      allLayers.filter(
                        (item) => item.id == value.target.value
                      )[0]
                    );
                  }}
                  MenuProps={{
                    style: {
                      maxHeight: 200,
                      maxWidth: 200,
                    },
                  }}
                >
                  {allLayers.length > 0 &&
                    allLayers?.map((item, index) => (
                      <MenuItem
                        title={item.title}
                        key={item.id}
                        value={item.id}
                      >
                        &nbsp; {item.title}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            
            {/* Dosya Formatı */}
            <Grid item md={2}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  {t("FileFormat")}
                </InputLabel>
                <Select
                  value={fileFormat}
                  label="Dosya Formatı"
                  onChange={(value) => setFileFormat(value.target.value)}
                >
                  <MenuItem value="GeoJson" selected>
                    GeoJson
                  </MenuItem>
                  <MenuItem value="ShapeFile" disabled>
                    ShapeFile
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Katman Koordinat Sistemi */}
            <Grid item md={5}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  {t("LayerCoordinateSystem")}
                </InputLabel>

                <Select
                  value={fileCoordinateSystem}
                  label="Katman Koordinat Sistemi"
                  onChange={(value) => {
                    setFileCoordinateSystem(value.target.value);
                  }}
                  MenuProps={{
                    style: {
                      maxHeight: 200,
                      maxWidth: 200,
                    },
                  }}
                >
                  {allCoordinateSystem?.map((item, index) => (
                    <MenuItem
                      title={item.name.toString()}
                      key={item.wkid}
                      value={item.wkid}
                    >
                      &nbsp; {item.wkid} - {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          {/* Dosya Yükle */}
          <Grid
            container
            style={{ marginTop: "25px", justifyContent: "center" }}
          >
            <Grid item style={{ marginRight: "10px" }} hidden={!loading}>
              <CircularProgress />
            </Grid>

            <Grid item>
              <label htmlFor="upload-photo">
                <Button
                  color="primary"
                  variant="contained"
                  component="span"
                  style={{ color: "white" }}
                  disabled={
                    !selectedLayer.title || !fileFormat || !fileCoordinateSystem
                  }
                  onClick={() => {
                    fetchGeoJsonDetails();
                  }}
                >
                  <FindInPage />
                  &nbsp;&nbsp;{t("PreviewFileContents")}
                </Button>
              </label>
            </Grid>
          </Grid>

          {/* Dosya Özellikleri */}
          <Grid
            container
            style={{ marginTop: "25px", justifyContent: "center" }}            
          >
            <TableContainer hidden={Object.keys(outputGeojson).length == 0}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: "bold" }}>
                      {t("FileName")}
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                    {t("FileSize")}
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                    {t("NumberOfData")}
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                    {t("FileFormat")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{selectedLayer?.title + ".geojson"}</TableCell>
                    <TableCell>
                      {(JSON?.stringify(outputGeojson)?.length /(1024 * 1024) ).toFixed(3) + "  "}
                      MegaByte
                    </TableCell>
                    <TableCell>{outputGeojson?.features?.length}</TableCell>
                    <TableCell>{fileFormat}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          {/* Yerel Katmanları Ekle */}
          <Grid
            container
            style={{ marginTop: "25px", justifyContent: "center" }}
            
          >
            <Grid item hidden={Object.keys(outputGeojson).length == 0}>
              <Button
                color="success"
                variant="contained"
                component="span"
                style={{ color: "white" }}
                onClick = {() => handleDownloadFile()}
                hidden={Object.keys(outputGeojson).length == 0}
              >
                <CloudDownload /> &nbsp;&nbsp;{t("ExportLayer")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      {/* --- Buton Özellikleri --- */}
      <Stack
        sx={{
          position: "absolute",
          left: `60px`,
          // top: 140,
          borderTopRightRadius: 8,
          borderBottomRightRadius: 8,
          boxShadow: 0,
          background: "#fff",
          zIndex: Z_TOOLBAR,
        }}
      >
        <ToolbarButton
          className="measureButtonStyles"
          title={t("ExportLayer")}
          icon={<CloudDownload />}
          //onClick={handleUploadLayer}
          onClick={handleModalOpen}
        />
      </Stack>
    </div>
  );
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: "10px",
};

export default DownloadLayerButton;
