import { Divider, Stack } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useScreen } from "./hooks/useScreen";
import { SEARCH_AREA_BUTTONS, Z_TOOLBAR } from "../constants/constants";
import MapContext from "./map/mapContext";
import {
	extentInteraction,
	handleExtentInteraction,
	handlePointerInteraction,
	olMap,
	pointerInteraction,
} from "./map/mapView";
import ToolbarButton from "./common/toolbarButton";
import Polygon from "ol/geom/Polygon";
import Point from "ol/geom/Point";
import WKT from "ol/format/WKT";
import { map as configMap, dataSourceApiUrl } from "../config";
import axios from "axios";
import { deleteLayer, getLayer } from "../utils/getFeatureLayer";
import CloseIcon from "@mui/icons-material/Close";
import { getServiceDescriptionById } from "../services/getServiceDescription";
import { LeftPanelContext } from "../context/leftPanelContext";
import { TableContext } from "../context/tableContext";
import { useTranslation } from 'react-i18next';


export default function SearchAreaToolbar({
	dragWidth,
	dragHeight,
}) {
	const { t, i18n } = useTranslation();
	const [extentCoords, setExtentCoords] = useState([]);
	const [pointCoords, setPointCoords] = useState([]);

	const { height } = useScreen();
	const { map } = useContext(MapContext);

	const { searchArea, setSearchArea, layerSelect, isOpenSearchArea } = useContext(LeftPanelContext);
	const { filterData, setFilterData, setFilterAttributes,filterAttributes, fields, tableKeyColumn,isThereIdColumn } = useContext(TableContext);

	useEffect(() => {
		switch (searchArea) {
			case 0:
				handleExtentInteraction(false);
				handlePointerInteraction();
				pointerInteraction.on("change:coords", (evt) => {
					setPointCoords(evt.target.coordinate);
				});
				break;
			case 1:
				handleExtentInteraction();
				handlePointerInteraction(false);

				let timeout, timer;

				extentInteraction.on("extentchanged", (evt) => {
					clearTimeout(timer);
					timer = setTimeout(() => {
						handleExtentInteraction(false);
					}, 1000);

					timeout = 1000;
					timer = setTimeout(() => {
						setExtentCoords([
							[evt.extent[0], evt.extent[1]],
							[evt.extent[0], evt.extent[3]],
							[evt.extent[2], evt.extent[3]],
							[evt.extent[2], evt.extent[1]],
							[evt.extent[0], evt.extent[1]],
						]);
					}, timeout);
				});
				break;

			default:
				handleExtentInteraction(false);
				handlePointerInteraction(false);
				break;
		}

		return () => {
			handleExtentInteraction(false);
			handlePointerInteraction(false);
		};
	}, [searchArea]);

	const fetchData = (options) => {
		axios(dataSourceApiUrl.spatialFilterApiUrl, {
			method: "post",
			data: options,
		})
			.then((res) => {
				if (res.data.length > 0) {
					var currentFilterData = [];
					res.data.forEach((item, index) => {
						const newAttributes = changeAttributes(item.attributes);
						if (!isThereIdColumn) // Eğer Id adında bir kolon mevcut degilse... (tablo yapısı bir tane id kolonu istiyor MUI de)
						{newAttributes.id = newAttributes[tableKeyColumn];} //Spatial sorgu sonrası id değerlerinin haritadaki veri ile eşleşmesi için kaldırıldı A.S 29.07.22
						index === 0
							? setFilterAttributes([newAttributes])
							: setFilterAttributes((prev) => [...prev, newAttributes]);
						currentFilterData.push(newAttributes)
					});
					sessionStorage.setItem("filterData",JSON.stringify(currentFilterData)); //FilterData değerlerinin harita üzerinden tıklanıldığında seçilebilmesi için sessionStorage a da kaydedilmesi işlemi A.S 10.08.22
					setFilterData(res.data);
					const geometries = res.data.map((item) => item.geometry);
					const dataIds = res.data.map((item) => item.oid);
					getLayer(geometries, "", "table-layer",dataIds);
					
					
					setSearchArea();
				} else {
					setSearchArea();
					setFilterAttributes([]);
					setFilterData([]);
					deleteLayer();
				}
			})
			.catch((err) => {
				throw err;
			});
	};

	useEffect(() => {
		if (pointCoords.length > 0) {
			getOptions("point").then((option) => fetchData(option));
		}
	}, [pointCoords]);

	//* sorgudan gelen attribute verisinin key lerini fieldName ile değiştirir
	const changeAttributes = (attributes) => {
		const newAttributes = {};
		fields.forEach((field, index) => {
			newAttributes[field.fieldName] = attributes[field.name];
		});
		return newAttributes;
	};

	useEffect(() => {
		if (extentCoords.length > 0) {
			getOptions("polygon").then((option) => fetchData(option));
		}
	}, [extentCoords]);

	const getOptions = async (type) => {
		const options = {};
		const service = await getServiceDescriptionById(
			parseInt(layerSelect.serviceDescriptionId)
		);
		options.layerId = 1;
		options.serviceDescriptionId = parseInt(layerSelect.serviceDescriptionId);
		options.whereClause = "";
		// options.buffer = 1;
		options.bufferUnit = "m";
		options.tolerance = 0.01;
		options.geometry =
			type === "polygon"
				? new WKT().writeGeometry(new Polygon([extentCoords]))
				: new WKT().writeGeometry(new Point(pointCoords));
		options.geometryColumn = "shape";
		options.wkid = configMap.wkid;
		options.toWKID = service.wkid;
		options.outputWKID = configMap.wkid;
		options.returnFields = true;
		options.returnGeometry = true;
		options.spatialRelationType = 5;

		return options;
	};

	return (
		<Stack
			sx={{
				position: "absolute",
				left: dragHeight < height - 200 ? +dragWidth : "initial",
				right: dragHeight > height - 200 ? 0 : "initial",
				bottom: dragHeight < height * 0.5 ? height * 0.5 : + dragHeight + 30,
				borderTopRightRadius: dragHeight < height - 200 ? 8 : 0,
				borderBottomRightRadius: dragHeight < height - 200 ? 8 : 0,
				borderTopLeftRadius: dragHeight > height - 200 ? 8 : 0,
				borderBottomLeftRadius: dragHeight > height - 200 ? 8 : 0,
				boxShadow: 3,
				background: "#fff",
				zIndex: Z_TOOLBAR,
			}}
		>
			{filterData?.length > 0 && (
				<ToolbarButton
					title="Temizle"
					icon={<CloseIcon fontSize="small" />}
					onClick={() => {
						setFilterAttributes([]);
						setFilterData([]);
						deleteLayer("table-layer");
					}}
				/>
			)}
			<Divider />
			{isOpenSearchArea &&
				layerSelect.state &&
				SEARCH_AREA_BUTTONS.map((button, i) => (
					<div key={i}>
						<ToolbarButton
							key={searchArea}
							icon={button.icon}
							title={t(button.title)}
							backgroundColor={
								searchArea === button.mode ? "secondary.main" : ""
							} //
							onClick={() =>
								setSearchArea(
									searchArea !== button.mode ? button.mode : null
								)
							}
						/>
						{i < SEARCH_AREA_BUTTONS.length - 1 && <Divider />}
					</div>
				))}
		</Stack>
	);
}
