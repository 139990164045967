export function Progress() {
    this.el = document.getElementById('progress1');
    this.loading = 0;
    this.loaded = 0;
}

/**
 * Increment the count of loading tiles.
 */
Progress.prototype.addLoading = function () {
    if (this.el) {
        ++this.loading;
        this.update();
    }
};

/**
 * Increment the count of loaded tiles.
 */
Progress.prototype.addLoaded = function () {
    if (this.el) {
        --this.loading;
        this.update();
    }
};

/**
 * Update the progress bar.
 */
Progress.prototype.update = function () {
    if (this.el) {
        if (this.loading <= 0) {
            this.hide();
        }
        else {
            this.show();
        }
    }
};

/**
 * Show the progress bar.
 */
Progress.prototype.show = function () {
    if (this.el) {
        this.el.style.visibility = 'visible';
    }
};

/**
 * Hide the progress bar.
 */
Progress.prototype.hide = function () {
    if (this.el) {
        const style = this.el.style;
        setTimeout(function () {
            style.visibility = 'hidden';
            // style.width = 0;
        }, 250);
    }
};