import React, { useState, createContext } from 'react';

export const LeftPanelContext = createContext();


export const LeftPanelContextProvider = ({ children }) => {
    const [searchMode, setSearchMode] = useState(0);
    const [searchArea, setSearchArea] = useState();
    const [serviceDescriptionId, setServiceDescriptionId] = useState();
    const [layerSelect, setLayerSelect] = useState({
        serviceDescriptionId: "",
        state: false,
    });
    const [searchValue, setSearchValue] = useState({});
    const [isOpenSearchArea, setIsOpenSearchArea] = useState(false);
    const [treeData, setTreeData] = useState();
    const [toggle, setToggle] = useState({});
    const [opacity, setOpacity] = useState({});

    const [treeNodeIds,setTreeNodeIds] = useState([]); //Ağaçtaki tüm klasör/katmanların Id değerleri
    const [expanded, setExpanded] = useState(["-1"]); //Tümünü Genişlet/Daralt
    const [selectedInnerTreeNodeIds,setSelectedInnerTreeNodeIds] = useState([]); // Ağaç içerisinde multi seçili iç içe node id leri (klasör seçimlerinde klasör içindeki itemleri de seçer)
    
    const [isInventoryCommonLayerOpen, setIsInventoryCommonLayerOpen] = useState(false); // Envanter Genel (Rapor) Katmanı için özel durum

    const [isMapCoordinatePickerOpen,setIsMapCoordinatePickerOpen] = useState(false);

    const props = {
        searchMode, setSearchMode,
        searchArea, setSearchArea,
        serviceDescriptionId, setServiceDescriptionId,
        layerSelect, setLayerSelect,
        searchValue, setSearchValue,
        isOpenSearchArea, setIsOpenSearchArea,
        treeData, setTreeData,
        toggle, setToggle,
        opacity, setOpacity,
        expanded, setExpanded,
        treeNodeIds,setTreeNodeIds,
        selectedInnerTreeNodeIds,setSelectedInnerTreeNodeIds,
        isInventoryCommonLayerOpen, setIsInventoryCommonLayerOpen,
        isMapCoordinatePickerOpen,setIsMapCoordinatePickerOpen
    }

    return <LeftPanelContext.Provider value={props}>{children}</LeftPanelContext.Provider>
}