import React, { useState, useContext, createContext } from "react";
import { useEffect } from "react";
import {olMap, unvisibleLayer, visibleLayer } from "../components/map/mapView";
import { deleteLayer, flashLayer, getLayer } from "../utils/getFeatureLayer";
import { LeftPanelContext } from "./leftPanelContext";
import { MainContext } from "./mainContext";
import { appName, checkTokenValidityApi, getTokenDetailsApi, userApiUrl } from "../config";
import axios from "axios";
import { megsisMessageServiceContext } from "./megsisMessageServiceContext";
import { saveCredentials } from "../utils/auth";

import MegsisMessageServiceIslemButtons from "./megsisMessageServiceIslemButtons";
import { initXDB } from "../services/database";
var parentOrigin;
var parentSource;
var connectedToOrigin = false;
var isProjectOpen = false;


// senging methods
const tokenHasBeenSet = "tokenHasBeenSet";
const webgisLayerConnected = "webgisLayerConnected"


export const MessageServiceContext = createContext();

export const sendWindowMessage = (method, message, parameters) => {
  var data = { msg: message, mtd: method, param: parameters };
  console.log("___sendWindowMessage",data)
  var json = JSON.stringify(data);
  
  parentSource.postMessage(json, parentOrigin);
};

const validateAndSetToken = async (token,isForce,setIsOpenLoginModal,setMainServiceToken) => {  
  if (token) {
    try {
      
      document.cookie = "_pst_="+token+"; path=/;";
      
      // if (isForce) document.cookie = "_pst_="+token+"; domain=megsis.tapu.icisleri.com.tr; path=/;";
      axios.defaults.headers.common["token"] = token;
      
      var url;
      if (isForce)  url = checkTokenValidityApi + token;
      else url = checkTokenValidityApi + token;
      
      // const response = await axios(url);
      // const resData = await response.data;
      

      var url2;
      if (isForce)  url2 = getTokenDetailsApi + token;
      else url2 = getTokenDetailsApi + token;
      
      const response2 = await axios(url2);
      const resData2 = await response2.data;

      localStorage.setItem(appName + ".LastUserId", parseInt(resData2.userId));
      if (resData2) axios.defaults.headers.common["userId"] = resData2.userId;

      setMainServiceToken(token);

      saveCredentials({
        userGroups: resData2.userGroups,
        userId: resData2.userId,
        userName: resData2.userName,
        userRoles: resData2.userRoles,
      });
      sessionStorage.setItem(appName + ".userId", resData2.userId);
      sessionStorage.setItem(appName + ".userName", resData2.userName);
      sessionStorage.setItem(appName + ".userGroups", resData2.userGroups);
      sessionStorage.setItem(appName + ".UserRoles", resData2.userRoles);

      await initXDB();
      setIsOpenLoginModal(false);

    } 
      catch (error) 
      {
        throw error;
    }
  }
};

export const MessageServiceContextProvider = ({ children }) => {  
  
  const [messageMS, setMessageMS] = useState("");
  const [methodMS, setMethodMS] = useState("");
  const [parametersMS, setParametersMS] = useState({});
  const { setToggle } = useContext(LeftPanelContext);
  const {
    setIsSearchPanelOpen,
    setIsOpenProjectModal,
    setSelectedProject,
    isIFrame,
    setIsIFrame,
    setIFrameRegion,
    setIsOpenLoginModal,
    setMainServiceToken
  } = useContext(MainContext);

  const [megsisIslemRequest,setMegsisIslemRequest] = useState({});
  
  const receiveWindowMessage = async (event) => {    
    if (typeof event.data == "string") {
      var data = JSON?.parse(event.data);
      
      console.log("___receiveWinddowMessage",data)
      // var data = event.data
      var message = data?.msg;
      var method = data?.mtd;
      var parameters = data?.param;
      parentSource = event.source;
      if (method !== undefined) {
        switch (method) {
          case "setToken":            
            await validateAndSetToken(parameters.token,false,setIsOpenLoginModal,setMainServiceToken);            
            sendWindowMessage(tokenHasBeenSet, "", {});
            break;
          case "setTokenForce":  
            await validateAndSetToken(parameters.token,true,setIsOpenLoginModal,setMainServiceToken);
            sendWindowMessage(tokenHasBeenSet, "", {});
            break;
          case "setSourceAndOrigin":
            parentOrigin = event.origin;
            parentSource = event.source;
            connectedToOrigin = true;
            console.log("___WEBGIS Layer connected to " + event.origin);
            sendWindowMessage(webgisLayerConnected, "", {});
            break;
          case "checkIframeLoad":
            // setWebgisFrameLoad(true);
            break;
          case "addLayerByCatalogId":
            if (parameters.layerId) {
              visibleLayer(parseInt(parameters.layerId));
              setToggle((prev) => ({
                ...prev,
                [parseInt(parameters.layerId)]: true,
              }));
            }
            break;
          case "setSelectedProject":
            if (
              parameters.projectId &&
              parameters.projectName &&
              parameters.projectbmapid
            ) {
              setSelectedProject({
                id: parseInt(parameters.projectId),
                name: parameters.projectName,
                bmapid: parseInt(parameters.projectbmapid),
              });
              visibleLayer(parseInt(parameters.projectbmapid));
              setToggle((prev) => ({
                ...prev,
                [parseInt(parameters.projectbmapid)]: true,
              }));
              setIsOpenProjectModal(false);
            }
            break;
          case "deleteLayerByCatalogId":
            if (parameters.layerId) {
              unvisibleLayer(parseInt(parameters.layerId));
              setToggle((prev) => ({
                ...prev,
                [parseInt(parameters.layerId)]: false,
              }));
            }
            break;
          case "zoomGeometry":
            if (parameters.geometry) {
              flashLayer(parameters.geometry);
            }
            break;
          case "addDraftLayerByGeometry":
            if (
              parameters.geometry &&
              parameters.featureName &&
              parameters.layerName
            ) {
              getLayer(
                parameters.geometry,
                parameters.featureName,
                parameters.layerName
              );
            }
            break;
          case "deleteLayerByLayerName":
            if (parameters.layerName) {
              deleteLayer(parameters.layerName);
            }
            break;
          case "itIsIframe":
            setIsIFrame(true);
            break;
          case "itIsNotIframe":
            setIsIFrame(false);
            break;
          case "setRegion":            
            setIFrameRegion(parameters);
            break;
          case "alert":
            alert(message);
            break;
          case "megsisIslemRequestOld":
            await megsisMessageServiceContext(
              method,
              message,
              parameters,
              setToggle
            )
            break;
          case "megsisIslemRequest":
            setIsSearchPanelOpen(false);
            setMegsisIslemRequest({
              method:method,
              message:message,
              parameters:parameters,
              isIslemButtons:true,
            })
            break;
          default:
          // alert(message);
        }
        // sendWindowMessage("checkIframeLoad", "checkIframeLoad", {});
      }
      parentOrigin = event.origin;
    }
    // sendWindowMessage("webgisLayerConnected", "", {});
  };

  useEffect(() => {
    // window.removeEventListener("message", receiveWindowMessage);
    console.log("___WEBGIS Layer waiting for messages.");
    console.log("___WEBGIS origin:", window.origin);
    if (!isIFrame) {      
      window.addEventListener("message", receiveWindowMessage, false);
    }
  }, []);

  const props = {
    messageMS,
    setMessageMS,
    methodMS,
    setMethodMS,
    parametersMS,
    setParametersMS,
  };

  return (
    <>
    {megsisIslemRequest.isIslemButtons && <MegsisMessageServiceIslemButtons request={megsisIslemRequest} />}
    <MessageServiceContext.Provider value={props}>
      {children}
    </MessageServiceContext.Provider>
    </>
  );
};
