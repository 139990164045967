import { Stack } from "@mui/material";
import { useContext, useState } from "react";
import { MainContext } from "../../context/mainContext";
import { useEffect } from "react";
import { Root2width } from "../common/grid";
import { Home } from "@mui/icons-material";
import { Button } from "@mui/material";
import { Tooltip } from "@mui/material";
import { TravelExplore } from "@mui/icons-material";
import { kgmMeasurementPanelName } from "../../config";
import { RIGHT_PANEL_ACTIONS } from "../../constants/constants";
import { t } from "i18next";

export default function RightPanelToolbar() {
  const { rightPanelAction, rightPanelQueryOnMap, setRightPanelQueryOnMap } = useContext(MainContext);

  // useEffect(() => {
  //   if (rightPanelQueryOnMap) {
  //       console.log("??")
  //   }
  // }, [rightPanelQueryOnMap]);

  return (
    rightPanelAction?.title === RIGHT_PANEL_ACTIONS[1] && (
      <Stack
        sx={{
          position: "absolute",
          right: Root2width-3,
          borderTopLeftRadius: 8,
          borderBottomLeftRadius: 8,
          boxShadow: 3,
          top: 10,
          background: "#fff",
          zIndex: 1, //Z_TOOLBAR
        }}
      >
        <Tooltip placement="left" title={!rightPanelQueryOnMap ? t("QueryOpenMap") : t("QueryCloseMap")}>
          <Button
            size="small"
            onClick={() => setRightPanelQueryOnMap(!rightPanelQueryOnMap)}
            color={!rightPanelQueryOnMap ? "primary" : "white"}
            sx={{
              p: 2,
              width: 24,
              height: 36,
              borderTopLeftRadius: 8,
              borderBottomLeftRadius: 8,
              // minWidth: "fit-content",
              bgcolor: !rightPanelQueryOnMap ? null : "secondary.main",
              ":hover": {
                color: !rightPanelQueryOnMap ? "secondary.main" : "white",
                bgcolor: !rightPanelQueryOnMap ? "white" : "secondary.main",
              },
            }}
          >
            <TravelExplore fontSize="small" />
          </Button>
        </Tooltip>
      </Stack>
    )
  );
}
