import React, { useContext, useEffect, useState } from "react";
import {
  Autocomplete,
  TextField,
  InputAdornment,
  Button,
  Typography,
  Grid,
} from "@mui/material";
import {
  province,
  district as dist,
  kadastroApiUrl,
  map,
  parsel,
} from "../../../config";
import axios from "axios";
import { deleteLayer, getLayer } from "../../../utils/getFeatureLayer";
import { visibleLayer, unvisibleLayer } from "../../map/mapView";
import { LeftPanelContext } from "../../../context/leftPanelContext";
import { CleaningServicesRounded, Clear, Map } from "@mui/icons-material";
import { useTranslation } from 'react-i18next';

export default function UnstyledSelectsMultiple({ title }) {
  const [district, setDistrict] = useState([]); //ilçe
  const [neighborhoods, setNeighborhoods] = useState([]); //mahalle
  const [square, setSquare] = useState([]); //ada
  const [parcel, setParcel] = useState([]); //parsel
  const [megsisOpen, setMegsisOpen] = useState(false);
  const { t, i18n } = useTranslation();

  const { searchValue, setSearchValue } = useContext(LeftPanelContext);

  useEffect(() => {
    if (dist) {
      axios(kadastroApiUrl.mahalleApiUrl + dist?.id)
        .then((res) => setNeighborhoods(res.data))
        .catch((err) => {
          throw err;
        });
    } else {
      axios(kadastroApiUrl.ilceApiUrl)
        .then((res) => setDistrict(res.data))
        .catch((err) => {
          throw err;
        });
    }
  }, []);

  const handleChange = (val, title) => {
    switch (title) {
      case "district":
        if (val) {
          axios(kadastroApiUrl.mahalleApiUrl + val?.id)
            .then((res) => setNeighborhoods(res.data))
            .catch((err) => {
              throw err;
            });
        } else {
          setNeighborhoods([]);
        }
        setSquare([]);
        setParcel([]);
        setSearchValue({ province: province?.id, [title]: val?.id });
        break;
      case "neighborhoods":
        if (val) {
          axios(kadastroApiUrl.adaApiUrl + val?.id)
            .then((res) => setSquare(res.data))
            .catch((err) => {
              throw err;
            });
        } else {
          setSquare([]);
        }
        setParcel([]);
        setSearchValue((prev) => ({
          ...prev,
          square: undefined,
          parcel: undefined,
          [title]: val?.id,
        }));
        break;

      case "square":
        if (val) {
          axios(
            kadastroApiUrl.sgParselApiUrl +
              searchValue?.neighborhoods +
              "/" +
              val?.ad
          )
            .then((res) => setParcel(res.data))
            .catch((err) => {
              throw err;
            });
        } else {
          setParcel([]);
        }
        setSearchValue((prev) => ({
          ...prev,
          parcel: undefined,
          [title]: val?.ad,
        }));
        
        break;

      case "parcel":
        if (val) {
          axios(
            kadastroApiUrl.sgParselApiUrl + val?.id + "/geometry/" + map.wkid
          )
            .then((res) => {
              getLayer(
                res.data,
                searchValue.square + " / " + val?.ad,
                "parcel-layer"
              );
            })
            .catch((err) => {
              throw err;
            });
        }
        setSearchValue((prev) => ({ ...prev, [title]: val?.ad }));
        break;

      default:
        break;
    }
  };

  const getLabel = (option) => {
    if (typeof option === "string") {
      return option;
    } else {
      return option.ad;
    }
  };

  const handleClick = () => {
    setMegsisOpen(!megsisOpen);
    !megsisOpen ? visibleLayer(parsel?.id) : unvisibleLayer(parsel?.id);
  };

  return (
    <>
      {!province?.ad && (
        <Autocomplete
          //disableClearable={true}
          clearOnEscape
          clearIcon={
            <Clear
              onClick={() => {
                handleChange("", "district");
                handleChange("", "neighborhoods");
                handleChange("", "square");
                handleChange("", "parcel");
              }}
            />
          }
          freeSolo
          readOnly={true}
          fullWidth
          id="combo-box-demo"
          options={[]}
          sx={{}}
          getOptionLabel={(option) => getLabel(option)}
          isOptionEqualToValue={(option, value) => option.ad === value}
          value={!searchValue?.province ? "" : null}
          onChange={(evt, v) => handleChange(v, "province")}
          ListboxProps={{
            style: {
              maxHeight: 140,
              border: "1px solid #039be5",
              fontSize: 13,
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                sx: { fontSize: "13px !important" },
                readOnly: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <Typography
                      variant="body1"
                      sx={{ fontSize: "14px !important" }}
                    >
                      {t("Province")}:
                    </Typography>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      )}

      <Autocomplete
        //disableClearable={true}
        clearOnEscape
        clearIcon={
          <Clear
            onClick={() => {
              handleChange("", "district");
              handleChange("", "neighborhoods");
              handleChange("", "square");
              handleChange("", "parcel");
            }}
          />
        }
        forcePopupIcon={dist ? false : true}
        freeSolo
        readOnly={dist ? true : false}
        fullWidth
        id="combo-box-demo"
        options={district}
        sx={{}}
        getOptionLabel={(option) => getLabel(option)}
        isOptionEqualToValue={(option, value) => option.ad === value}
        value={!searchValue?.district ? "" : null}
        onChange={(evt, v) => handleChange(v, "district")}
        ListboxProps={{
          style: {
            maxHeight: 140,
            border: "1px solid #039be5",
            fontSize: 13,
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              sx: { fontSize: "13px !important" },
              readOnly: dist ? true : false,
              startAdornment: (
                <InputAdornment position="start">
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "14px !important" }}
                  >
                    {t("District")}:
                  </Typography>
                </InputAdornment>
              ),
            }}
          />
        )}
      />

      <Autocomplete
        forcePopupIcon={searchValue?.["district"] === undefined ? false : true}
        //disableClearable={true}
        clearOnEscape
        clearIcon={
          <Clear
            onClick={() => {
              handleChange("", "neighborhoods");
              handleChange("", "square");
              handleChange("", "parcel");
            }}
          />
        }
        freeSolo
        readOnly={searchValue?.["district"] === undefined ? true : false}
        fullWidth
        id="combo-box-demo"
        options={neighborhoods}
        sx={{}}
        getOptionLabel={(option) => getLabel(option)}
        isOptionEqualToValue={(option, value) => option.ad === value}
        value={!searchValue?.neighborhoods ? "" : null}
        onChange={(evt, v) => handleChange(v, "neighborhoods")}
        ListboxProps={{
          style: {
            maxHeight: 140,
            border: "1px solid #039be5",
            fontSize: 13,
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              sx: { fontSize: "13px !important" },
              readOnly: searchValue?.["district"] === undefined ? true : false,
              startAdornment: (
                <InputAdornment position="start">
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "14px !important" }}
                  >
                    {t("Neighborhood")}:
                  </Typography>
                </InputAdornment>
              ),
            }}
          />
        )}
      />

      <Autocomplete
        forcePopupIcon={
          searchValue?.["neighborhoods"] === undefined ? false : true
        }
        //disableClearable={true}
        clearOnEscape
        clearIcon={
          <Clear
            onClick={() => {
              handleChange("", "square");
              handleChange("", "parcel");
            }}            
          />
        }
        freeSolo
        readOnly={searchValue?.["neighborhoods"] === undefined ? true : false}
        fullWidth
        id="combo-box-demo"
        options={square}
        sx={{}}
        getOptionLabel={(option) => getLabel(option)}
        isOptionEqualToValue={(option, value) => option.ad === value}
        value={!searchValue?.square ? "" : null}
        onChange={(evt, v) => handleChange(v, "square")}
        ListboxProps={{
          style: {
            maxHeight: 140,
            border: "1px solid #039be5",
            fontSize: 13,
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              sx: { fontSize: "13px !important" },
              readOnly:
                searchValue?.["neighborhoods"] === undefined ? true : false,
              startAdornment: (
                <InputAdornment position="start">
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "14px !important" }}
                  >
                    {t("Block")}:
                  </Typography>
                </InputAdornment>
              ),
            }}
          />
        )}
      />

      <Autocomplete
        forcePopupIcon={searchValue?.["square"] === undefined ? false : true}
        //disableClearable={true}
        clearOnEscape
        clearIcon={
          <Clear
            onClick={() => {
              handleChange("", "parcel");
            }}
          />
        }
        freeSolo
        readOnly={searchValue?.["square"] === undefined ? true : false}
        fullWidth
        id="combo-box-demo"
        options={parcel}
        sx={{}}
        getOptionLabel={(option) => getLabel(option)}
        isOptionEqualToValue={(option, value) => option.ad === value}
        value={!searchValue?.parcel ? "" : null}
        onChange={(evt, v) => handleChange(v, "parcel")}
        ListboxProps={{
          style: {
            maxHeight: 140,
            border: "1px solid #039be5",
            fontSize: 13,
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              sx: { fontSize: "13px !important" },
              readOnly: searchValue?.["square"] === undefined ? true : false,
              startAdornment: (
                <InputAdornment position="start">
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "14px !important" }}
                  >
                    {t("Parcel")}:
                  </Typography>
                </InputAdornment>
              ),
            }}
          />
        )}
      />

      <Grid container spacing={2}>
        <Grid item xs={7}>
            {searchValue?.parcel && (
              <Button
                fullWidth
                variant="outlined"
                sx={{ ...buttonStyles }}
                onClick={handleClick}
              >
                {t("NeighboringParcels")} &nbsp;<Map sx={{height: "15px"}}></Map>
              </Button>
            )}
        </Grid>

        <Grid item xs={5}>
            {searchValue?.district && (
              <Button
                fullWidth
                variant="outlined"
                sx={{ ...buttonStyles }}
                onClick={() => {
                  handleChange("", "district");
                  handleChange("", "neighborhoods");
                  handleChange("", "square");
                  handleChange("", "parcel");
                  deleteLayer("parcel-layer");
                }}
              >
                {t("Clean")} &nbsp;
                <CleaningServicesRounded
                  sx={{ transform: "rotate(315deg)",height: "15px" }}
                ></CleaningServicesRounded>
              </Button>
            )}
        </Grid>
      </Grid>
    </>
  );
}

const buttonStyles = {
  justifyContent: "center !important",
  fontSize: "12px",
  fontWeight: "bold",
  alignItems: "center",
  mt: 2,
  // color: "black",
  // boxShadow: "1px 1px 2px #9e9e9e, 0 0 3px #9e9e9e, 0 0 3px #9e9e9e",
  // "&:hover": { background: "#f5f5f5" },
};
