import { Button } from "@mui/material";
import DisplayRow from "../displayRow";
import { useContext } from "react";
import { TableContext } from "../../context/tableContext";
import { redirectUrl, province } from "../../config";
import { useTranslation } from 'react-i18next';

const RigthPanelSelectedRow = () => {
  const { t, i18n } = useTranslation();
  const { selectedRow, setSelectedRow, fields } = useContext(TableContext);
  return (
    <>
      <DisplayRow
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        fields={fields}
      />

      {selectedRow?.status === "parcel" &&
      redirectUrl?.cebsUrl &&
      (province.ad === "Mersin" || province.ad === "Kocaeli") ? (
        <Button
          disabled={!selectedRow?.data?.tzref}
          type="a"
          href={`https:${
            redirectUrl?.cebsUrl
          }index.html#/app/modules/takpas/takpas-zemin-sorgula/${selectedRow?.data?.tzref.toString()}`}
          target="_blank"
        >
          {t("ShowParcelDetails")}
        </Button>
      ) : (
        " "
      )}
    </>
  );
};

export default RigthPanelSelectedRow;
