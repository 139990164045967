import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { Box } from "@mui/material";
import { Modal } from "@mui/material";

export const RightPanelMeasurementTabsModal = ({
    legendModalOpen,
    setLegendModalOpen,
    legendInfo
}) => {
  return (
    <Modal
      open={legendModalOpen}
      onClose={() => setLegendModalOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box style={{ ...modalBoxStyle }}>
        <IconButton
          sx={{
            // position: "absolute",
            // right: "0",
            float: "right",
            marginTop: "5px",
          }}
          onClick={() => setLegendModalOpen(false)}
        >
          <Close></Close>
        </IconButton>
        <img
          src={legendInfo.lgnd}
          width="100%"
          height="auto"
          alt="legend-1"
          loading="lazy"
          style={{ objectFit: "cover" }}
        />
      </Box>
    </Modal>
  );
};

const modalBoxStyle = {
    position: "absolute",
    top: "50%",
    right: "15%",
    transform: "translate(-50%, -50%)",
    width: 320,
    height: "auto",
    backgroundColor: "white",
    bordeRadius: "10px !important",
    boxShadow: 24,
    p: 4,
  };