import { useContext, useEffect, useState } from "react";
import { MainContext } from "../../context/mainContext";
import { Tabs } from "@mui/material";
import { Tab } from "@mui/material";
import { Box } from "@mui/material";
import RightPanelMeasurementTabs from "./rightPanelMeasurementComponents/rightPanelMeasurementTabs";

const RightPanelMeasurement = ({}) => {
  const {
    rightPanelAction,
    setRightPanelAction,
    rightPanelQueryOnMap,
    setRightPanelQueryOnMap,
    isRightOverlayOpen,
    setIsRightOverlayOpen,
  } = useContext(MainContext);
  const [measurementType, setMeasurementType] = useState(1);
  const [isLoadingTab,setIsLoadingTab] = useState(false);

  return (
    <div>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={measurementType}
          onChange={(event, newValue) => setMeasurementType(newValue)}
          aria-label="basic tabs example"
          variant="fullWidth"
          centered
          disabled={isLoadingTab}
        >
          <Tab label="RSP" value={1} disabled={isLoadingTab}/>
          <Tab label="FWD" value={2} disabled={isLoadingTab}/>
          <Tab label="SKS" value={3} disabled={isLoadingTab}/>
        </Tabs>
      </Box>
      <RightPanelMeasurementTabs
          measurementType= {measurementType}
          rightPanelAction={rightPanelAction}
          rightPanelQueryOnMap={rightPanelQueryOnMap}
          setRightPanelQueryOnMap={setRightPanelQueryOnMap}
          isRightOverlayOpen={isRightOverlayOpen}
          setIsRightOverlayOpen={setIsRightOverlayOpen}
          isLoadingTab={isLoadingTab}
          setIsLoadingTab={setIsLoadingTab}
        />
    </div>
  );
};

export default RightPanelMeasurement;
