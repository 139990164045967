import axios from "axios"
import { baseApiUrl, dataSourceApiUrl, map } from "../../config"
import { flashLayer } from "../getFeatureLayer";

export const getFeatureByWhereClause = async(
    returnFields = false,
    subFields = [],
    serviceDescriptionId = null,    
    whereClause = "", //"objectid = 18529"
    outputWKID = map.wkid
    ) => {
    const resultParameters = {
        "returnFields": returnFields,
        "subFields": subFields,
        "serviceDescriptionId": serviceDescriptionId,
        "whereClause": whereClause,
        "outputWKID": outputWKID
    }
    const response = await axios.post(dataSourceApiUrl.queryFilterApiUrl,resultParameters)
    const data = await response.data;
    return data
}
