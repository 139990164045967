import {
	Adjust,
	CropFree,
	Home,
	Place,
	Search,
	TabUnselected,
	Directions,
	SearchOff,
} from "@mui/icons-material";

// DIMENSIONS
export const PANEL_WIDTH = 400;
export const PANEL_HEIGHT = 300;
export const PANEL_BP_WIDTH = 360; // BreakPoint width

// Z INDEXES
export const Z_DRAG_HANDLE = 30;
export const Z_TOOLBAR = 20;
export const Z_SEARCH_PANEL = 10;
export const Z_LEGEND_PANEL = 11;
export const Z_TABLE_PANEL = 5;
export const Z_MAIN_GRID = 2;
export const Z_SUB_GRID = 1;

export const RIGHT_PANEL_ACTIONS = {
	1 : "Measurement",
	2 : "Inventory",
	3 : "PMS Analysis",
	4 : "Others"
}

export const SEARCH_DETAILS_TITLE = {
	address: [
		{ title: "district", name: "İlçe" },
		{ title: "neighborhoods", name: "Mahalle" },
		{ title: "street", name: "Cadde" },
		{ title: "avenue", name: "Sokak" },
		{ title: "door", name: "Kapı" },
	],
	cadastre: [
		{ title: "district", name: "İlçe" },
		{ title: "neighborhoods", name: "Mahalle" },
		{ title: "square", name: "Ada" },
		{ title: "parcel", name: "Parsel" },
	],
	poi: [
		{ title: "district", name: "İlçe" },
		{ title: "name", name: "Ad" },
	],
	yol: [
		{ title: "region", name: "Bölge" },
		{ title: "kkno", name: "KKNO" },
	],
};

export const SEARCH_OPTION_BUTTONS = {
	other: [
		{
			mode: 0,
			name: "address",
			placeholder: "SearchAddress",
			icon: () => (
				<>
					<Search sx={{ ml: 1 }} />
					<Home viewBox="0 0 38 38" />
				</>
			),
		},
		{
			mode: 1,
			name: "cadastre",
			placeholder: "SearchParcel",
			icon: () => (
				<>
					<Search sx={{ ml: 1 }} />
					<TabUnselected viewBox="0 0 38 38" />
				</>
			),
		},
		{
			mode: 2,
			name: "poi",
			placeholder: "SearchImportentLocation",
			icon: () => (
				<>
					<Search sx={{ ml: 1 }} />
					<Place viewBox="0 0 38 38" />
				</>
			),
		},
	],
	kktc: [		
		{
			mode: 0,
			name: "cadastre",
			placeholder: "SearchParcel",
			icon: () => (
				<>
					<Search sx={{ ml: 1 }} />
					<TabUnselected viewBox="0 0 38 38" />
				</>
			),
		},
	],
	kgm: [
		{
			mode: 0,
			name: "yol",
			placeholder: "SearchRoad",
			icon: () => (
				<>
					<Search sx={{ ml: 1 }} />
					<Directions viewBox="0 0 38 38" />
				</>
			),
		},
	],
};

export const SEARCH_TOOLBAR_BUTTONS = {
	other: [
		{ mode: 0, title: "SearchAddress", icon: <Home fontSize="small" /> },
		{ mode: 1, title: "SearchParcel", icon: <TabUnselected fontSize="small" /> },
		{ mode: 2, title: "SearchImportentLocation", icon: <Place fontSize="small" /> },
	],
	kktc: [
		{ mode: 0, title: "SearchParcel", icon: <TabUnselected fontSize="small" /> },		
	],
	kgm: [
		{ mode: -1, title: "ClearSearch", icon: <SearchOff fontSize="small" /> },
	],
};

export const SEARCH_AREA_BUTTONS = [
	{
		mode: 0,
		title: "SearchatPoint",
		icon: (
			<>
				<Adjust fontSize="small" sx={{ ml: 2.5 }} />
				<Search fontSize="small" sx={{ ml: 0.5 }} viewBox="0 0 36 36" />
			</>
		),
	},
	{
		mode: 1,
		title: "SearchatField",
		icon: (
			<>
				<CropFree fontSize="small" sx={{ ml: 2.5 }} />
				<Search fontSize="small" sx={{ ml: 0.5 }} viewBox="0 0 36 36" />
			</>
		),
	},
];

export const GRID_LOCALE_TEXT = {
	// Root
	noRowsLabel: "Hiç satır yok",
	noResultsOverlayLabel: "Hiçbir sonuç bulunamadı.",
	errorOverlayDefaultLabel: "Bir hata oluştu.",

	// Density selector toolbar button text
	toolbarDensity: "Yoğun",
	toolbarDensityLabel: "Yoğun",
	toolbarDensityCompact: "Kompakt",
	toolbarDensityStandard: "Standard",
	toolbarDensityComfortable: "Geniş",

	// Columns selector toolbar button text
	toolbarColumns: "Sütunlar",
	toolbarColumnsLabel: "Sütunları seç",

	// Filters toolbar button text
	toolbarFilters: "Filtreler",
	toolbarFiltersLabel: "Filtreleri göster",
	toolbarFiltersTooltipHide: "Filtreleri sakla",
	toolbarFiltersTooltipShow: "Filtreleri göster",
	toolbarFiltersTooltipActive: (count) => `${count} aktif filtre`,

	// Export selector toolbar button text
	toolbarExport: "Dışarı aktar",
	toolbarExportLabel: "Dışarı aktar",
	toolbarExportCSV: "CSV olarak indir",
	toolbarExportPrint: "Yazdır",

	// Columns panel text
	columnsPanelTextFieldLabel: "Sütun ara",
	columnsPanelTextFieldPlaceholder: "Sütun ismi",
	columnsPanelDragIconLabel: "Sütunları sırala",
	columnsPanelShowAllButton: "Hepsini göster",
	columnsPanelHideAllButton: "Hepsini gizle",

	// Filter panel text
	filterPanelAddFilter: "Filtre ekle",
	filterPanelDeleteIconLabel: "Sil",
	filterPanelOperators: "Operatör",
	filterPanelOperatorAnd: "Ve",
	filterPanelOperatorOr: "Veya",
	filterPanelColumns: "Sütunlar",
	filterPanelInputLabel: "Değer",
	filterPanelInputPlaceholder: "Değeri filtrele",

	// Filter operators text
	filterOperatorContains: "içeriyor",
	filterOperatorEquals: "eşittir",
	filterOperatorStartsWith: "ile başlar",
	filterOperatorEndsWith: "ile biter",
	filterOperatorIs: "",
	filterOperatorNot: "değil",
	filterOperatorAfter: "sonra",
	filterOperatorOnOrAfter: "sonra veya aynı",
	filterOperatorBefore: "önce",
	filterOperatorOnOrBefore: "önce veya aynı",
	filterOperatorIsEmpty: "boş",
	filterOperatorIsNotEmpty: "boş değil",

	// Filter values text
	filterValueAny: "herhangi",
	filterValueTrue: "var",
	filterValueFalse: "yok",

	// Column menu text
	columnMenuLabel: "Menü",
	columnMenuShowColumns: "Sütunlar",
	columnMenuFilter: "Filtrele",
	columnMenuHideColumn: "Gizle",
	columnMenuUnsort: "Varsayılan",
	columnMenuSortAsc: "Artan",
	columnMenuSortDesc: "Azalan",

	// Column header text
	columnHeaderFiltersTooltipActive: (count) => `${count} aktif filtre`,
	columnHeaderFiltersLabel: "Filtreleri göster",
	columnHeaderSortIconLabel: "Sırala",

	// Rows selected footer text
	footerRowSelected: (count) => `${count.toLocaleString()} satır seçildi`,

	// Total rows footer text
	footerTotalRows: "Toplam Satır:",

	// Total visible rows footer text
	footerTotalVisibleRows: (visibleCount, totalCount) =>
		`${visibleCount.toLocaleString()} / ${totalCount.toLocaleString()}`,

	// Checkbox selection text
	checkboxSelectionHeaderName: "Checkbox selection",

	// Boolean cell text
	booleanCellTrueLabel: "var",
	booleanCellFalseLabel: "yok",

	// Actions cell more text
	actionsCellMore: "daha fazla",

	// Column pinning text
	pinToLeft: "Sola iliştir",
	pinToRight: "Sağa iliştir",
	unpin: "Serbest bırak",

	// Tree Data
	treeDataGroupingHeaderName: "Grupla",
	treeDataExpand: "see children",
	treeDataCollapse: "hide children",

	// Used core components translation keys
	MuiTablePagination: {},
};
