import { Close } from "@mui/icons-material";
import { Tab } from "@mui/material";
import { Button } from "@mui/material";
import { Tabs } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { List } from "@mui/material";
import { ListItemText } from "@mui/material";
import { Typography } from "@mui/material";
import { TableContainer } from "@mui/material";
import { TableRow } from "@mui/material";
import { Table } from "@mui/material";
import { TableBody } from "@mui/material";
import { TableCell } from "@mui/material";
import { TableHead } from "@mui/material";
import { ListItem } from "@mui/material";
import { LinearProgress } from "@mui/material";
import { Box } from "@mui/material";
import WKT from "ol/format/WKT";
import { ImageWMS } from "ol/source";
import { useEffect, useState } from "react";
import { baseApiUrl, baseLayerApiUrl, kgmCatalogLayers } from "../../config";
import { getToken } from "../../utils/auth";
import { deleteLayer, getLayer } from "../../utils/getFeatureLayer";
import { olMap } from "../map/mapView";
const RightPanelOverlay = ({
  rightPanelAction,
  isRightOverlayOpen,
  setIsRightOverlayOpen,
  setRightPanelQueryOnMap,
  coordinateOnMap,
  measurementType,
}) => {
  const [overlayTabValue, setOverlayTabValue] = useState(-1);
  const [layerData, setLayerData] = useState({});
  const [loading, setLoading] = useState(false);
  const [isEmptyResult, setIsEmptyResult] = useState(false);
  const RSPLayers = [
    // { layer: kgmCatalogLayers?.kgmRSPGPSLayer, name: "GPS" },
    { layer: kgmCatalogLayers?.kgmRSPIRILayer, name: "IRI" },
    { layer: kgmCatalogLayers?.kgmRSPFIRILayer, name: "FIRI" },
  ];
  const FWDLayers = [
    { layer: kgmCatalogLayers?.kgmFWDLayer, name: "FWD" },
    {
      layer: kgmCatalogLayers?.kgmDropsAnalyzedFWDLayer,
      name: "Analiz Edilmiş FWD",
    },
  ];

  const SKSLayers= [
    { layer: kgmCatalogLayers?.kgmSKSLayer, name: "SKS" }
  ]

  const removedLayerColumns = ["karayolu_id", "istikamet", "bbox"];
  const layerColumnDict = {};

  useEffect(async () => {
    if (coordinateOnMap && overlayTabValue !== -1) {
      setLoading(true);
      const wmsSource = new ImageWMS({
        url:
        baseLayerApiUrl +
          overlayTabValue.wmsOverlayLayerId +
          "?",
        params: {
          LAYERS: overlayTabValue.wmsOverlayLayerName,
          VERSION: "1.1.0",
          CQL_FILTER:
            "karayolu_id=" +
            rightPanelAction?.kkno?.id,
          t: getToken(),
        },
        serverType: "geoserver",
        crossOrigin: "anonymous",
        buffer: "10",
      });

      const url = await wmsSource.getFeatureInfoUrl(
        coordinateOnMap,
        olMap.getView().getResolution(),
        olMap.getView().getProjection(),
        {
          INFO_FORMAT: "application/json",
        }
      );

      if (url) {
        const response = await fetch(url);
        const html = await response.text();
        const responseJSON = await JSON.parse(html);
        if (responseJSON.features.length !== 0) {
          await setLayerData(responseJSON.features);
          setIsEmptyResult(false);
        } else {
          setIsEmptyResult(true);
        }
      }

      await setLoading(false);
    }
  }, [overlayTabValue,coordinateOnMap]);

  useEffect(() => {
    if (isRightOverlayOpen) {
      setLoading(false);
      setLayerData({});
      deleteLayer("right-panel-overlay-layer");
      setOverlayTabValue(-1);
    }
  }, [isRightOverlayOpen]);

  
  return (
    <div
      id="right-panel-overlay"
      hidden={!isRightOverlayOpen}
      style={{ ...overlayStyle }}
    >
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        {loading && (
          <LinearProgress color="primary" sx={{ animationDuration: "550ms" }} />
        )}
        <Tabs
          value={overlayTabValue}
          onChange={(event, newValue) => setOverlayTabValue(newValue)}
          variant="fullWidth"
          centered
        >
          {measurementType === 1
            ? RSPLayers.map((data) => (
                <Tab label={data.name} value={data.layer} onClick = {()=>setLayerData({})}/>
              ))
            : measurementType === 2 ? FWDLayers.map((data) => (
                <Tab label={data.name} value={data.layer} onClick = {()=>setLayerData({})} />
              ))
            : SKSLayers.map((data) => (
              <Tab label={data.name} value={data.layer} onClick = {()=>setLayerData({})} />
            ))}

          <Tab
            icon={<Close color="secondary"></Close>}
            onClick={() => {                           
              setLoading(false);
              setLayerData({});
              setIsRightOverlayOpen(false);
              setRightPanelQueryOnMap(false);
              deleteLayer("right-panel-overlay-layer");
            }}
            value={-1}
          />
        </Tabs>

        {Object.keys(layerData).length !== 0 && (
          <>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {Object.keys(layerData[0]?.properties)?.map(
                      (property, index) =>
                        !removedLayerColumns.includes(property) && (
                          <TableCell
                            sx={{ fontWeight: "bold", textAlign: "center" }}
                          >
                            {property.replaceAll("_", " ").toUpperCase()}
                          </TableCell>
                        )
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {layerData.map((data, index) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      {Object.keys(data?.properties).map(
                        (property, propertyIndex) =>
                          !removedLayerColumns.includes(property) && (
                            <TableCell>
                              {typeof data.properties[property] === "number"
                                ? data.properties[property].toFixed(2)
                                : data.properties[property]}
                            </TableCell>
                          )
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Typography variant="body1" color="primary">
              Veri Sayısı: {Object.keys(layerData).length}
            </Typography>
          </>
        )}
        {isEmptyResult && overlayTabValue!==-1 && "Veri Bulunamadı."}
      </Box>
    </div>
  );
};

export default RightPanelOverlay;

const overlayStyle = {
  backgroundColor: "white",
  width: "25em",
};
