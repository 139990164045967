import {
  Box,
  FormControl,
  InputLabel,
  NativeSelect,
} from "@mui/material";

export const RightPanelPMSAnalysisTimeComponent = ({
  time,
  setTime,
}) => {
  const handleChangeRequestValue = (e) => {
    const value = e.target.value;
    setTime(value);
  };

  return (
    <Box>
      <FormControl
        style={{ padding: "1em", paddingRight: "0.5em", width: "90%" }}
      >
        <InputLabel variant="filled" htmlFor="right-panel-infentory-time-component">
          Tarih Aralığı
        </InputLabel>
        <NativeSelect
          defaultValue={time}
          inputProps={{
            id: 'right-panel-infentory-time-component',
          }}
          onChange={handleChangeRequestValue}
        >
          <option value={1}>Tümü</option>
          <option value={2}>Son 1 ay</option>
          <option value={3}>Son 6 ay</option>
          <option value={4}>Son 1 yıl</option>
        </NativeSelect>
      </FormControl>
    </Box>
  );
};

