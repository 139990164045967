import { useState } from "react";
import { Stack, Typography } from "@mui/material";
import { Z_TOOLBAR } from "../constants/constants";
import Timeline from "@mui/icons-material/Timeline";
import ToolbarButton from "./common/toolbarButton";
import StraightenIcon from "@mui/icons-material/Straighten";
import SquareFootIcon from "@mui/icons-material/SquareFoot";
import ArchitectureIcon from "@mui/icons-material/Architecture";
import { olMap } from "./map/mapView";
import { cleanInteractions, cleanupDraw } from "../utils/measure";
import MeasureButton from "@terrestris/react-geo/dist/Button/MeasureButton/MeasureButton";
import ToggleGroup from "@terrestris/react-geo/dist/Button/ToggleGroup/ToggleGroup";
import { useTranslation } from 'react-i18next';

const MeasureToolbar = ({ dragWidth }) => {
	const { t, i18n } = useTranslation();
	const [isOpen, setIsOpen] = useState(false);
	const [toolName, setToolName] = useState("");
	const [deselectAll, setDeselectAll] = useState(false);

	const handleRemoveMeasureLayers = () => {
		toolName && setDeselectAll(true);

		cleanupDraw(olMap);
		cleanInteractions(olMap);

		olMap.getLayers().forEach((layer) => {
			if (layer?.get("name") === "measure-layer") {
				olMap.removeLayer(layer);
			}
		});
	};

	const handleOpen = () => {
		setIsOpen(!isOpen);
		handleRemoveMeasureLayers();
		setToolName("");
	};

	return (
		<>
			<Stack
				sx={{
					position: "absolute",
					left: `${dragWidth}px`,
					top: 173,
					borderTopRightRadius: 0,
					borderBottomRightRadius: 0,
					boxShadow: 0,
					background: "#fff",
					zIndex: Z_TOOLBAR,
				}}
			>
				<ToolbarButton
					title={t("MeasuringTools")}
					icon={<StraightenIcon />}
					onClick={handleOpen}
				/>
			</Stack>
			{isOpen && (
				<Stack
					sx={{
						...measureTabStyles,
						left: `${+dragWidth + 80}px`,
						zIndex: Z_TOOLBAR,
					}}
				>
					<Typography sx={{ padding: 1}} align="center" variant="subtitle2" title={t("Measurement")}>
						{toolName || t("shortMeasurement")}
					</Typography>
					<ToggleGroup
						className="measureToggleGroupStyles"
						selectedName={toolName}
						onChange={(props) => {
							props.name !== toolName ? setToolName(props.name) : setToolName();
						}}
						deselectAll={deselectAll}
						orientation="horizontal"
					>
						<MeasureButton
							type="primary"
							name={t("Distance")}
							map={olMap}
							measureType="line"
							// multipleDrawing
							className="measureButtonStyles"
							strokeColor="rgba(41, 182, 246,0.8)"
							fillColor="rgba(41, 182, 246,0.3)"
							continueLineMsg=""
							measureLayerName="measure-layer"
							showMeasureInfoOnClickedPoints
							icon={<Timeline sx={{ color: "#757575" }} />}
							pressedIcon={<Timeline sx={{ color: "#2196f3" }} />}
						></MeasureButton>
						<MeasureButton
							name={t("Area")}
							map={olMap}
							// multipleDrawing
							measureType="polygon"
							decimalPlacesInTooltips={3}
							strokeColor="rgba(41, 182, 246,0.8)"
							fillColor="rgba(41, 182, 246,0.3)"
							className="measureButtonStyles"
							continuePolygonMsg=""
							measureLayerName="measure-layer"
							icon={<SquareFootIcon sx={{ color: "#757575" }} />}
							pressedIcon={<SquareFootIcon sx={{ color: "#2196f3" }} />}
						></MeasureButton>
						<MeasureButton
							name={t("Angle")}
							map={olMap}
							measureType="angle"
							strokeColor="rgba(41, 182, 246,0.8)"
							fillColor="rgba(41, 182, 246,0.3)"
							decimalPlacesInTooltips="1"
							continueAngleMsg=""
							className="measureButtonStyles"
							measureLayerName="measure-layer"
							icon={<ArchitectureIcon sx={{ color: "#757575" }} />}
							pressedIcon={<ArchitectureIcon sx={{ color: "#2196f3" }} />}
						></MeasureButton>
					</ToggleGroup>
				</Stack>
			)}
		</>
	);
};

const measureTabStyles = {
	position: "absolute",
	top: 90,
	backgroundColor: "#fff",
	borderRadius: 2,
	boxShadow: 3,
	width: 70,
};

export default MeasureToolbar;
