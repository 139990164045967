import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Style from "ol/style/Style";
import { Circle as CircleStyle, Fill, Stroke, Text } from "ol/style";
import { olMap } from "../components/map/mapView";
import WKT from "ol/format/WKT";
import { PANEL_WIDTH } from "../constants/constants";

const flashLayerStyle = [
  new Style({
    stroke: new Stroke({
      color: "rgb(255, 0, 0)",
      width: 10,
    }),
    fill: new Fill({
      color: "rgba(255, 0, 0, 1)",
    }),
  }),
  new Style({
    image: new CircleStyle({
      radius: 10,
      fill: new Fill({
        color: "red",
      }),
    }),
  }),
];

const wktLayerStyle = [
  new Style({
    stroke: new Stroke({
      color: "rgb(102, 255, 51)",
      width: 5,
    }),
    fill: new Fill({
      color: "rgba(102, 255, 51, 0)",
    }),

    zIndex: 2,
  }),
  new Style({
    image: new CircleStyle({
      radius: 3,
      fill: new Fill({
        color: "rgb(102, 255, 51)",
      }),
    }),
    zIndex: 2,
  }),
  //* border style
  new Style({
    stroke: new Stroke({
      color: "rgba(0, 0, 0, 1)",
      width: 6,
    }),
    image: new CircleStyle({
      radius: 5,
      fill: new Fill({
        color: "rgb(0, 0, 0)",
      }),
    }),
    zIndex: 1,
  }),
];

export const getFeatureLayer = (
  geometry,
  name,
  flashLayer,
  className,
  dataIds
) => {
  let styles;
  if (flashLayer) {
    styles = flashLayerStyle;
  } else {
    styles = [
      ...wktLayerStyle,
      new Style({
        text: new Text({
          text: name || null,
          placement: "point",
          font: "18px Calibri,sans-serif",
          fill: new Fill({
            color: "rgb(255,255,255)",
          }),
          stroke: new Stroke({
            color: "rgb(0,0,0)",
            width: 2,
          }),
        }),
      }),
    ];
  }

  if (Array.isArray(geometry)) {
    geometry.forEach((item, index) => {
      const features = new WKT().readFeatures(item);

      const source = new VectorSource({
        features: features,
        crossOrigin: "Anonymous",
      });

      const poly = features[0].getGeometry();
      const featureLayer = new VectorLayer({
        className: className,
        type: "FeatureCollection",
        source: source,
        style: styles,
        zIndex: 10,
        oid: dataIds?.[index],
      });

      olMap.getView().fit(poly, { maxZoom: 19, duration: 500 });

      olMap.addLayer(featureLayer);
    });
  } else {
    const features = new WKT().readFeatures(geometry);
    const source = new VectorSource({
      features: features,
      crossOrigin: "Anonymous",
    });

    const poly = features[0].getGeometry();

    const featureLayer = new VectorLayer({
      className: flashLayer ? "flash-layer" : className,
      type: "FeatureCollection",
      source: source,
      style: styles,
      zIndex: 10,
    });

    olMap.getView().fit(poly, {
      padding: [100, 100, 100, PANEL_WIDTH],
      maxZoom: 19,
      duration: 500,
    });

    olMap.addLayer(featureLayer);
  }
};

export const getFeatureLayerNoZoom = (
  parameters = {
    geometry: null,
    name: null,
    className: null,
    dataIds: null,
    styleName: null,
    color:null,
  }
) => {
  const geometry = parameters?.source;
  const name = parameters?.name;
  const flashLayer = false;
  const className = parameters?.className;
  const dataIds = parameters?.dataIds;
  const styleName = parameters?.styleName;
  const color = parameters?.color;
  let styles;
  if (flashLayer) {
    styles = flashLayerStyle;
  } else {
    styles = [
      ...wktLayerStyle,
      new Style({
        text: new Text({
          text: name || null,
          placement: "point",
          font: "18px Calibri,sans-serif",
          fill: new Fill({
            color: "rgb(255,255,255)",
          }),
          stroke: new Stroke({
            color: "rgb(0,0,0)",
            width: 2,
          }),
        }),
      }),
    ];
  }

  if (styleName==="draft-polygon") {
    styles = [
      new Style({
        stroke: new Stroke({
          color: "rgba(67,154,225,100)",
          width: 5,
        }),
        zIndex: 1,
      }),
      new Style({
        stroke: new Stroke({
          color: "rgba(7,90,147,100)",
          width: 1,
        }),
        zIndex: 2,
      }),
    ];
  }

  if (styleName==="draft-islem-polygon") {
    styles = [
      new Style({
        text: new Text({
          text: name || null,
          placement: "point",
          font: "18px Calibri,sans-serif",
          fill: new Fill({
            color: "rgb(255,255,255)",
          }),
          stroke: new Stroke({
            color: "rgb(0,0,0)",
            width: 2,
          }),
        }),
        zIndex: 3,
      }),
      new Style({
        stroke: new Stroke({
          color: "rgb(254,205,50)",
          width: 5,
        }),
        fill: new Fill({
          color: color || "rgba(129,195,65, 0.3)",
        }),
        zIndex: 1,
      }),
      new Style({
        stroke: new Stroke({
          color: "rgb(46,125,50)",
          width: 1,
        }),
        zIndex: 2,
      }),
    ];
  }

  if (Array.isArray(geometry)) {
    geometry.forEach((item, index) => {
      const features = new WKT().readFeatures(item);

      const source = new VectorSource({
        features: features,
        crossOrigin: "Anonymous",
      });

      const poly = features[0].getGeometry();
      const featureLayer = new VectorLayer({
        className: className,
        type: "FeatureCollection",
        source: source,
        style: styles,
        zIndex: 10,
        oid: dataIds?.[index],
      });
      olMap.addLayer(featureLayer);
    });
  } else {
    const features = new WKT().readFeatures(geometry);
    const source = new VectorSource({
      features: features,
      crossOrigin: "Anonymous",
    });

    const poly = features[0].getGeometry();

    const featureLayer = new VectorLayer({
      className: flashLayer ? "flash-layer" : className,
      type: "FeatureCollection",
      source: source,
      style: styles,
      zIndex: 10,
    });

    olMap.addLayer(featureLayer);
  }
};

export const getLayer = (source, name, className, dataIds) => {
  deleteLayer(className);

  if (Array.isArray(source)) {
    getFeatureLayer(source, name, false, className, dataIds);
  } else {
    getFeatureLayer(source, name, false, className);
  }
};

export const getLayerNoZoom = (
  parameters = {
    source: null,
    name: null,
    className: null,
    dataIds: null,
    styleName: null,
    color:null,
  }
) => {

  deleteLayer(parameters?.className);

  if (Array.isArray(parameters?.source)) {
    getFeatureLayerNoZoom(parameters);
  } else {
    getFeatureLayerNoZoom(parameters);
  }
};

export const deleteLayer = (className) => {
  const removeLayer = olMap
    .getLayers()
    .getArray()
    .filter((x) => x.className_ === className);

  Array.isArray(removeLayer)
    ? removeLayer.forEach((layer) => olMap.removeLayer(layer))
    : olMap.removeLayer(removeLayer);
};

export const deleteLayerByAWordIn = (word) => {
  const removeLayer = olMap
    .getLayers()
    .getArray()
    .filter((x) => x.className_?.includes(word));

  Array.isArray(removeLayer)
    ? removeLayer.forEach((layer) => olMap.removeLayer(layer))
    : olMap.removeLayer(removeLayer);
};

export const deleteAllLayers = () => {
  const removeLayers = olMap.getAllLayers();

  if (removeLayers.length > 0) {
    Array.isArray(removeLayers)
      ? removeLayers.forEach((layer) => olMap.removeLayer(layer))
      : olMap.removeLayer(removeLayers);
  }
};

export const deleteLayersForSpecificAttributeBoolValue = (AttributeName) => {
  const removeLayerInfoArray = [];
  const removeLayers = [];
  olMap.getLayers().forEach((layer) => {
      if (layer?.get(AttributeName) && layer?.get("title")) {
        removeLayerInfoArray.push({title:layer.get("title"),isVisible:layer.getVisible()});
        removeLayers.push(layer)
      }
    }); 
  removeLayers.forEach((layer)=>  olMap.removeLayer(layer))
  return removeLayerInfoArray;
};


export const flashLayer = (geometry) => {
  getFeatureLayer(geometry, "", true);
  flash();
};

const flash = (feature) => {
  const removeLayer = olMap
    .getLayers()
    .getArray()
    .filter((x) => x.className_ === "flash-layer");

  let opacity = 1;
  const interval = setInterval(() => {
    if (opacity > 0) {
      removeLayer[removeLayer.length - 1].setOpacity(opacity);
      opacity -= 0.1;
    }
    if (opacity <= 0) {
      olMap.removeLayer(removeLayer[removeLayer.length - 1]);
      clearInterval(interval);
    }
  }, 100);
};

export const setCenter = (type) => {
  const feature = olMap
    .getLayers()
    .getArray()
    .find((x) => x.className_ === type)
    ?.getSource()
    ?.getFeatures()[0];
  const polygon = feature?.getGeometry();
  polygon && olMap.getView().fit(polygon, { maxZoom: 19 });
};

olMap.on("pointermove", function (e) {
  const hit = olMap.forEachFeatureAtPixel(e.pixel, function (feature, layer) {
    return layer?.getClassName() === "parcel-layer" ||
      layer?.getClassName() === "table-layer"
      ? true
      : false;
  });

  this.getTargetElement().style.cursor = hit ? "pointer" : "";
});
