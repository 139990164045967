import MapApp from "./pages/mapApp";
import "./App.css";
import axios from "./services/httpService";
import { getToken, login, validateToken } from "./utils/auth";
import { useContext, useEffect } from "react";
import SigninModal from "./pages/signinModal";
import { MainContext } from "./context/mainContext";
import DatabaseProviderXDB from "./services/databaseProviderXDB";
// import db from "./services/database";

function App() {	

	useEffect(() => {
		login();
	}, []);

	// useEffect(()=> {
	// 	validateToken(getToken());
	// },[])
	return (
	<>
	<DatabaseProviderXDB/>
	<SigninModal />
	<MapApp />
	</>
	);
}

export default App;
