import { Visibility } from "@mui/icons-material";
import { VisibilityOff } from "@mui/icons-material";
import { Button } from "@mui/material";
import { handleOnClickLayerButton } from "./rightPanelUtils/rightPanelMeasurementFunctions";

export const RightPanelMeasurementTabsDropsAnalyzedFWDListButton = ({
  listObject,
  measurementFilesLayers,
  setMeasurementFilesLayers,
  dropsAnalyzedFWDLayers,
  setDropsAnalyzedFWDLayers,
  searchValue
}) => {
  return (
    <div>
      <Button
        variant="outlined"
        color={
          dropsAnalyzedFWDLayers[listObject.id]?.isDropsAnalyzedFWDLayerOpen
            ? "success"
            : "error"
        }
        sx={{ ...buttonStyles }}
        onClick={() => {
          handleOnClickLayerButton(
            "DAFWD-layer",
            listObject?.id,
            measurementFilesLayers,
            setMeasurementFilesLayers,
            setDropsAnalyzedFWDLayers,
            dropsAnalyzedFWDLayers,
            searchValue
          );
        }}
        endIcon={
          !dropsAnalyzedFWDLayers[listObject.id]?.isDropsAnalyzedFWDLayerOpen ? (
            <VisibilityOff style={{ ...iconInbuttonStyles }}></VisibilityOff>
          ) : (
            <Visibility style={{ ...iconInbuttonStyles }}></Visibility>
          )
        }
      >
        A.E. FWD
      </Button>
    </div>
  );
};

const buttonStyles = {
  height: "2em",
  width: "100%",
  mt: 0.5,
};

const iconInbuttonStyles = {
  height: "0.75em",
  width: "auto",
};
