
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Fragment, forwardRef, useContext, useState } from 'react';
import { Alert } from '@mui/material';
import { MainContext } from '../../context/mainContext';
import { Close } from '@mui/icons-material';


const AlertBox = () => {

  const { alertBoxSituation,setAlertBoxSituation } = useContext(MainContext);


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      setAlertBoxSituation({
        isOpen:false,
        type:"",
        message:null
    });
    }

    setAlertBoxSituation({
        isOpen:false,
        type:"",
        message:null
    });
  };
  // error warning info success

  return (
        <Snackbar
            open={alertBoxSituation.isOpen}
            autoHideDuration={2000}
            onClose={handleClose}
            // className="alert-box"
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
        >
            <Alert severity={alertBoxSituation.type}><strong>{alertBoxSituation.message}</strong></Alert> 
        </Snackbar>
  );
}

export default AlertBox;