import { useState, useEffect, useContext } from "react";
import {
	Slide,
	IconButton,
	Stack,
	Typography,
	easing,
	Tooltip,
} from "@mui/material";
import { MainGrid, SubGrid } from "./common/grid";
import {
	ArrowBackIos,
	ArrowForwardIos,
	KeyboardReturn,
	OpenInFull,
} from "@mui/icons-material";
import { Z_LEGEND_PANEL } from "../constants/constants";
import Legend1 from "../assets/legends/plan50k-legend.jpg";
import { getServiceLegendBySid } from "../services/getServiceLegend";
import { getServiceDescriptionById } from "../services/getServiceDescription";
import { MainContext } from "../context/mainContext";
import { useTranslation } from 'react-i18next';

export default function LegendPanel({ onClose, selectedSid }) {
	const { t, i18n } = useTranslation();
	const [contents, setContents] = useState({});

	const { legendPanelSRID } = useContext(MainContext);

	useEffect(() => {
		if (legendPanelSRID) {
			getServiceLegendBySid(selectedSid).then(
				(legend) =>
					legend && setContents((prev) => ({ ...prev, img: legend?.lgnd }))
			);
			getServiceDescriptionById(selectedSid).then(
				(service) =>
					service && setContents((prev) => ({ ...prev, name: service?.name }))
			);
		} else {
			setContents({});
		}
	}, [legendPanelSRID]);

	return (
		<Slide
			in={legendPanelSRID !== null}
			easing={{
				enter: easing.easeOut,
				exit: easing.easeOut,
			}}
			mountOnEnter
			unmountOnExit
			direction="right"
		>
			<MainGrid
				container
				id="legend-panel"
				direction="column"
				justify="flex-start"
				alignContent="flex-start"
				sx={{
					position: "absolute",
					zIndex: Z_LEGEND_PANEL,
				}}
			>
				<Tooltip placement="right" title={t("Close")}>
					<IconButton
						size="small"
						onClick={onClose}
						color="gray"
						sx={{
							top: 1,
							right: -30,
							position: "absolute",
							borderLeft: 1,
							borderColor: "gray.main",
							borderTopLeftRadius: 1,
							borderBottomLeftRadius: 1,
							background: "#fff",
							boxShadow: 3,
							":hover": { background: "#fff", color: "secondary.main" },
						}}
					>
						<KeyboardReturn fontSize="small" />
					</IconButton>
				</Tooltip>

				{/* legend controls */}
				<SubGrid
					item
					justify="flex-start"
					alignContent="flex-start"
					sx={{
						border: 1,
						borderColor: "gray.main",
						overflowY: "clip",
					}}
					sm={1}
				>
					<Stack direction="row" sx={{ justifyContent: "center", m: 0 }}>
						{/* <IconButton disableRipple size="small" onClick={() => {}}>
							<ArrowBackIos fontSize="small" />
						</IconButton> */}
						<Typography variant="h5" align="center">
							{contents.name}
						</Typography>
						{/* <IconButton disableRipple size="small" onClick={() => {}}>
							<ArrowForwardIos fontSize="small" />
						</IconButton> */}
					</Stack>
				</SubGrid>

				{/* legend img */}
				<SubGrid item sm={11} sx={{ m: 0, py: 2 }}>
					{contents.img ? (
						<>
							<Tooltip title={t("ViewFullScreen")}>
								<IconButton
									size="small"
									onClick={() => {
										const image = new Image();
										image.src = contents.img;
										image.style.maxWidth = "30%";
										image.style.display = "block";
										image.style.margin = "0px auto";
										const w = window.open("");
										w.document.write(image.outerHTML);
									}}
									sx={{
										top: "5em",
										right: 24,
										position: "absolute",
										border: 1,
										outline: 1,
										borderColor: "gray.main",
										background: "#fff",
										boxShadow: 4,
										":hover": { background: "#fff", color: "primary.main" },
									}}
								>
									<OpenInFull fontSize="small" />
								</IconButton>
							</Tooltip>

							<img
								src={contents.img}
								width="100%"
								height="auto"
								alt="legend-1"
								loading="lazy"
								style={{ objectFit: "cover" }}
							/>
						</>
					) : (
						<Typography variant="h5">{t("NoLegendinLayer")}</Typography>
					)}
				</SubGrid>
			</MainGrid>
		</Slide>
	);
}
