import React, { useContext, useEffect, useState } from "react";
import {
    LightMode,
    Visibility,
    VisibilityOffOutlined,
    TableChartOutlined,
    PhotoOutlined,
} from "@mui/icons-material";
import {
    Box,
    Checkbox,
    IconButton,
    Input,
    Slider,
    Tooltip,
    Typography,
} from "@mui/material";
import { PANEL_BP_WIDTH } from "../../constants/constants";
import {
    handleExtentInteraction,
    handlePointerInteraction,
} from "../map/mapView";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { StyledTreeItem } from "./tree";
import { getServiceDescriptionById } from "../../services/getServiceDescription";
import { LeftPanelContext } from "../../context/leftPanelContext";
import { MainContext } from "../../context/mainContext";
import { useTranslation } from 'react-i18next';

const BaseMapFolder = ({
    dragWidth,
    onToggleChange,
    onOpacityChange,
    toggleLayerTable,
}) => {
    const { t, i18n } = useTranslation();
    const [serviceType, setServiceType] = useState();
    const [utilTab, setUtilTab] = useState(false);
    const [baseMapName, setBaseMapName] = useState("");

    const { setSearchArea, setLayerSelect, toggle, opacity,serviceDescriptionId } = useContext(LeftPanelContext);
    const { selectedProject, setLegendPanelSRID } = useContext(MainContext);

    useEffect(() => {
        if (selectedProject.id) {
            getServiceDescriptionById(selectedProject.bmapid).then(serviceDesc => {
                setBaseMapName(serviceDesc?.name);
            })
        }
    }, [selectedProject])

    return (
        <StyledTreeItem
            nodeId={String(selectedProject.bmapid)}
            label={
                <Box
                    style={{
                        display: "inline-flex",
                        marginLeft: -9,
                        alignItems: "center",
                    }}
                >
                    {!utilTab ? (
                        <>
                            <Checkbox
                                icon={<VisibilityOffOutlined fontSize="14" />}
                                checkedIcon={<Visibility fontSize="14" />}
                                value={toggle?.[selectedProject.bmapid]} //+
                                checked={toggle?.[selectedProject.bmapid]} //+
                                onChange={(evt, checked) => {
                                    onToggleChange(selectedProject.bmapid, checked); //+
                                    handleExtentInteraction(false);
                                    handlePointerInteraction(false);
                                    setSearchArea();
                                }}
                                sx={{
                                    ":hover": {
                                        height: "fit-content",
                                        alignSelf: "center",
                                        bgcolor: "gray.light",
                                    },
                                }}
                            />
                            <Typography
                                variant={"h5"}
                                sx={{
                                    alignSelf: "center",
                                    fontSize: dragWidth < PANEL_BP_WIDTH ? 12 : 13,
                                    pb: 0.5,
                                    width: "90%",
                                    maxHeight: 30,
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    WebkitLineClamp: 2,
                                    WebkitBoxOrient: "vertical",
                                    // whiteSpace: dragWidth < PANEL_BP_WIDTH ? "wrap" : "nowrap",
                                    display:
                                        dragWidth < PANEL_BP_WIDTH ? "-webkit-box" : "block",
                                }}
                            >
                                {baseMapName}
                            </Typography>
                            {toggle?.[selectedProject.bmapid] && ( //+
                                <IconButton onClick={() => setUtilTab(true)} sx={{ mx: 1 }}>
                                    <ChevronRightIcon fontSize="small" color="action" />
                                </IconButton>
                            )}
                        </>
                    ) : (
                        toggle?.[selectedProject.bmapid] && //+
                        utilTab && (
                            <>
                                <IconButton
                                    onClick={() => setUtilTab(false)}
                                    sx={{ mx: 1 }}
                                >
                                    <ChevronLeftIcon fontSize="small" color="action" />
                                </IconButton>
                                <Slider
                                    step={10}
                                    min={10}
                                    max={100}
                                    value={opacity?.[selectedProject.bmapid] || 100} //+
                                    onChange={(evt, val) =>
                                        onOpacityChange(selectedProject.bmapid, val) //+
                                    }
                                    size="small"
                                    aria-label="Opaklık"
                                    valueLabelDisplay="off"
                                    sx={{ width: 30, mr: 1 }}
                                />
                                <Input
                                    size="small"
                                    disableUnderline
                                    color="primary"
                                    startAdornment={
                                        <LightMode
                                            color="primary"
                                            fontSize="13"
                                            sx={{ pr: 1 }}
                                        />
                                    }
                                    value={opacity?.[selectedProject.bmapid] || 100} //+
                                    onChange={(e) => {
                                        const value = +e.target.value;
                                        onOpacityChange( //+
                                            selectedProject.bmapid,
                                            value < 10 ? 10 : value > 100 ? 100 : value
                                        );
                                    }}
                                    // sx={{ mx: 1, pr: 1 }}
                                    inputProps={{
                                        step: 10,
                                        min: 0,
                                        max: 100,
                                        type: "number",
                                        color: "rgb(65,159,202)",
                                        sx: {
                                            width: 45,
                                            textDecoration: "underline",
                                            fontSize: 14,
                                            // "&::before": {borderBottom: "none !important"}, "&::after": {borderBottom: "none !important" },
                                        },
                                    }}
                                />
                                {serviceType && (
                                    <Tooltip title="Tablo">
                                        <Checkbox
                                            icon={
                                                <TableChartOutlined
                                                    fontSize="small"
                                                    color="action"
                                                />
                                            }
                                            checkedIcon={
                                                <TableChartOutlined
                                                    fontSize="small"
                                                    color="primary"
                                                />
                                            }
                                            onChange={() => {
                                                toggleLayerTable(selectedProject.bmapid);
                                                handleExtentInteraction(false);
                                                handlePointerInteraction(false);
                                                setSearchArea();
                                            }}
                                            sx={{
                                                ":hover": {
                                                    height: "fit-content",
                                                    alignSelf: "center",
                                                    bgcolor: "gray.light",
                                                },
                                            }}
                                        />
                                    </Tooltip>
                                )}
                                <Tooltip title={t("Legend")}>
                                    <IconButton
                                        onClick={() => {
                                            setLegendPanelSRID(selectedProject.bmapid);
                                            setLayerSelect({ serviceDescriptionId: selectedProject.bmapid, state: true })
                                        }}
                                    >
                                        <PhotoOutlined fontSize="small" color="action" />
                                    </IconButton>
                                </Tooltip>
                            </>
                        )
                    )}
                </Box>
            }
        />
    )
}

export default BaseMapFolder;