import React, { useEffect, useState, useContext } from "react";
import { Autocomplete, TextField, InputAdornment, Typography } from "@mui/material";
import { poiApiUrl, map } from "../../../config";
import axios from "axios";
import { getLayer } from "../../../utils/getFeatureLayer";
import { LeftPanelContext } from "../../../context/leftPanelContext";
import { useTranslation } from 'react-i18next';

export default function UnstyledSelectsMultiple({ title }) {
	const { t, i18n } = useTranslation();
	const [name, setName] = useState([]); //ad
	const [searchInput, setSearchInput] = useState("");
	const [isLoading, setIsLoading] = useState(true);

	const { searchValue, setSearchValue } = useContext(LeftPanelContext)

	useEffect(() => {
		if (searchInput.length > 2 && searchInput.length % 3 === 0) {
			axios.post(poiApiUrl + "list", `${searchInput}`, { headers: { "Content-Type": "application/json" } })
				.then(res => { setName(res.data); setIsLoading(false) })
				.catch(err => { throw err });
		}
	}, [searchInput])

	const handleChange = (val, title) => {
		if (val) {
			axios.get(poiApiUrl + val.id + "/geometry/" + map.wkid)
				.then(res => {
					getLayer(res.data, val.ad, "poi-layer");
				})
				.catch(err => { throw err });
			setSearchValue((prev) => ({ ...prev, [title]: val?.id }));
		}
	};

	const getLabel = (option) => {
		console.log(option)
		if (typeof option === "string") {
			return option;
		} else {
			return option.ad + " (" + option?.mahalle + "/" + option?.ilce + ")";
		}
	};

	return (
		<Autocomplete
			disableClearable={true}
			freeSolo
			fullWidth
			id="combo-box-demo"
			options={name}
			loading={isLoading}
			sx={{}}
			getOptionLabel={(option) => getLabel(option)}
			value={!searchValue?.name ? "" : null}
			onChange={(evt, v) => handleChange(v, "name")}
			ListboxProps={{
				style: {
					maxHeight: 140,
					border: "1px solid #039be5",
					fontSize: 13,
				},
			}}
			renderInput={(params) => (
				<TextField
					{...params}
					InputProps={{
						...params.InputProps,
						sx: { fontSize: "13px !important" },
						startAdornment: (
							<InputAdornment position="start"><Typography variant="body1" sx={{ fontSize: "14px !important" }}>{t("Name")}:</Typography></InputAdornment>
						),
					}}
					onChange={(evt) => { setSearchInput(evt.target.value) }}
				/>
			)}
		/>
	);
}
