import { Close, ContactlessOutlined } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { Box } from "@mui/material";
import { Modal } from "@mui/material";
import { kgmCatalogLayers } from "../../../../config";
import { getServiceDescriptionById } from "../../../../services/getServiceDescription";
import { deleteLayer } from "../../../../utils/getFeatureLayer";
import { addWMSLayer, olMap } from "../../../map/mapView";

// Katman Aç-Kapa Butonu
export const handleOnClickLayerButton = async (
  layer,
  measurementId,
  measurementFilesLayers,
  setMeasurementFilesLayers,
  setDetailedLayers, // RSP için  setFilteredRSPtasksLayers / FWD için setDropsAnalyzedFWDLayers
  detailedLayers, // RSP için  filteredRSPtasksLayers / FWD için dropsAnalyzedFWDLayers
  searchValue,
) => {
  // - - - RSP - - -

  // IRI KATMANI
  if (layer === "IRI-layer") {
    deleteLayer("IRI-layer");
    if (!measurementFilesLayers[measurementId].isIRILayerOpen) {
      var data = await getServiceDescriptionById(
        kgmCatalogLayers.kgmRSPIRILayer.wmsId
      );
      data.visible = true;
      var IRIlayer = addWMSLayer(
        data,
        "olcum_id=" + measurementId + " AND is_deleted=false",
        searchValue
      );
      IRIlayer.className_ = layer;
      const layerList = olMap.getLayers();
      IRIlayer && layerList.push(IRIlayer);
    }
    handleListItemLayersIRI(
      measurementId,
      measurementFilesLayers[measurementId]?.isIRILayerOpen,
      measurementFilesLayers,
      setMeasurementFilesLayers
    );
  }
  // FIRI KATMANI
  if (layer === "FIRI-layer") {    
    deleteLayer("FIRI-layer");
    if (!detailedLayers[measurementId].isFIRILayerOpen) {
      var data = await getServiceDescriptionById(
        kgmCatalogLayers.kgmRSPFIRILayer.wmsId
      );
      data.visible = true;
      var FIRIlayer = addWMSLayer(
        data,
        "fg_param_id=" + measurementId + " AND is_deleted=false",
        searchValue
      );
      FIRIlayer.className_ = layer;
      const layerList = olMap.getLayers();
      FIRIlayer && layerList.push(FIRIlayer);
    }
    handleListItemLayersFIRI(
      measurementId,      
      detailedLayers[measurementId]?.isFIRILayerOpen,
      setDetailedLayers,
      detailedLayers
    );
  }

  // - - - FWD - - -

  // FWD Katmanı
  if (layer === "FWD-layer") {
    deleteLayer("FWD-layer");
    if (!measurementFilesLayers[measurementId].isFWDLayerOpen) {
      var data = await getServiceDescriptionById(
        kgmCatalogLayers.kgmFWDLayer.wmsId
      );

      data.visible = true;
      var FWDlayer = addWMSLayer(
        data,
        // "karayolu_id=" +
        //   "0" +
          "olcum_id=" +
          measurementId +
          " AND is_deleted=false",
          searchValue
      );
      FWDlayer.className_ = layer;
      const layerList = olMap.getLayers();
      FWDlayer && layerList.push(FWDlayer);
    }
    handleListItemLayersFWD(
      measurementId,
      measurementFilesLayers[measurementId]?.isFWDLayerOpen,
      measurementFilesLayers,
      setMeasurementFilesLayers
    );
  }

  // Drops Analyzed FWD Katmanı
  if (layer === "DAFWD-layer") {        
    deleteLayer("DAFWD-layer");    
    if (!detailedLayers[measurementId].isDropsAnalyzedFWDLayerOpen) {
      var data = await getServiceDescriptionById(
        kgmCatalogLayers.kgmDropsAnalyzedFWDLayer.wmsId
      );
      data.visible = true;
      var DAFWDlayer = addWMSLayer(
        data,
        "analyze_id=" + measurementId + " AND is_deleted=false",
        searchValue
      );
      DAFWDlayer.className_ = layer;
      const layerList = olMap.getLayers();
      DAFWDlayer && layerList.push(DAFWDlayer);
    }
    handleListItemLayersDAFWD(
      measurementId,      
      detailedLayers[measurementId]?.isDropsAnalyzedFWDLayerOpen,
      setDetailedLayers,
      detailedLayers
    );
  }
 
  // - - - SKS - - -

  // SKS Katmanı
  if (layer === "SKS-layer") {
    deleteLayer("SKS-layer");
    if (!measurementFilesLayers[measurementId].isSKSLayerOpen) {
      var data = await getServiceDescriptionById(
        kgmCatalogLayers.kgmSKSLayer.wmsId
      );

      data.visible = true;
      var SKSlayer = addWMSLayer(
        data,
          "olcum_id=" +
          measurementId +
          " AND is_deleted=false",
          searchValue
      );
      SKSlayer.className_ = layer;
      const layerList = olMap.getLayers();
      SKSlayer && layerList.push(SKSlayer);
    }
    handleListItemLayersSKS(
      measurementId,
      measurementFilesLayers[measurementId]?.isSKSLayerOpen,
      measurementFilesLayers,
      setMeasurementFilesLayers
    );
  }
  
};



// Tüm Katmanları Haritadan Kaldırma Butonu
export default function removeAllRightPanelMeasurementLayers() {
  deleteLayer("IRI-layer");
  deleteLayer("FIRI-layer");
  deleteLayer("SKS-layer");
  deleteLayer("FWD-layer");
  deleteLayer("DAFWD-layer");
}


// - - - RSP - - -

// Listedeki Katman Görünürlükleri için fonksiyon (IRI)
export const handleListItemLayersIRI = (
  listItemId,
  isOpen,
  measurementFilesLayers,
  setMeasurementFilesLayers
) => {
  var layers = measurementFilesLayers;
  Object.keys(layers).map((key) => {
    if (key === listItemId) {
      setMeasurementFilesLayers((prev) => ({
        ...prev,
        [key]: {
          ...prev[key],
          isIRILayerOpen: !isOpen,
        },
      }));
    } else {
      if (!isOpen) {
        setMeasurementFilesLayers((prev) => ({
          ...prev,
          [key]: {
            ...prev[key],
            isIRILayerOpen: false,
          },
        }));
      }
    }
  });
};

// Listedeki Katman Görünürlükleri için fonksiyon (FIRI)
export const handleListItemLayersFIRI = (
  listItemId,
  isOpen,
  setFilteredRSPtasksLayers,
  filteredRSPtaskLayers
) => {
  var layers = filteredRSPtaskLayers;
  Object.keys(layers).map((key) => {
    if (key === listItemId) {
      setFilteredRSPtasksLayers((prev) => ({
        ...prev,
        [key]: {
          isFIRILayerOpen: !isOpen,
        },
      }));
    } else {
      if (!isOpen) {
        setFilteredRSPtasksLayers((prev) => ({
          ...prev,
          [key]: {
            isFIRILayerOpen: false,
          },
        }));
      }
    }
  });
};

// - - - FWD - - -
// Listedeki Katman Görünürlükleri için fonksiyon (IRI)
export const handleListItemLayersFWD = (
  listItemId,
  isOpen,
  measurementFilesLayers,
  setMeasurementFilesLayers
) => {
  var layers = measurementFilesLayers;
  Object.keys(layers).map((key) => {
    if (key === listItemId) {
      setMeasurementFilesLayers((prev) => ({
        ...prev,
        [key]: {
          ...prev[key],
          isFWDLayerOpen: !isOpen,
        },
      }));
    } else {
      if (!isOpen) {
        setMeasurementFilesLayers((prev) => ({
          ...prev,
          [key]: {
            ...prev[key],
            isfwdLayerOpen: false,
          },
        }));
      }
    }
  });
};

// Listedeki Katman Görünürlükleri için fonksiyon (DAFWD)
export const handleListItemLayersDAFWD = (
  listItemId,
  isOpen,
  setDropsAnalyzedFWDLayers,
  dropsAnalyzedFWDLayers
) => {
  var layers = dropsAnalyzedFWDLayers;
  Object.keys(layers).map((key) => {
    if (key === listItemId) {
      setDropsAnalyzedFWDLayers((prev) => ({
        ...prev,
        [key]: {
          isDropsAnalyzedFWDLayerOpen: !isOpen,
        },
      }));
    } else {
      if (!isOpen) {
        setDropsAnalyzedFWDLayers((prev) => ({
          ...prev,
          [key]: {
            isDropsAnalyzedFWDLayerOpen: false,
          },
        }));
      }
    }
  });
};

// - - - SKS - - -
// Listedeki Katman Görünürlükleri için fonksiyon (IRI)
export const handleListItemLayersSKS = (
  listItemId,
  isOpen,
  measurementFilesLayers,
  setMeasurementFilesLayers
) => {
  var layers = measurementFilesLayers;
  Object.keys(layers).map((key) => {
    if (key === listItemId) {
      setMeasurementFilesLayers((prev) => ({
        ...prev,
        [key]: {
          ...prev[key],
          isSKSLayerOpen: !isOpen,
        },
      }));
    } else {
      if (!isOpen) {
        setMeasurementFilesLayers((prev) => ({
          ...prev,
          [key]: {
            ...prev[key],
            isSKSLayerOpen: false,
          },
        }));
      }
    }
  });
};