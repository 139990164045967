import React, { Fragment, useEffect, useState } from 'react'
import { RightPanelInventoryData } from './rightPanelInventoryComponents/rightPanelInventoryData'
import { RightPanelInventoryTimeComponent } from './rightPanelInventoryComponents/rightPanelInventoryTimeComponent'

export const RightPanelInventory = () => {
    const [time,setTime] = useState({})
    const [data,setData] = useState([])

  return (
    <Fragment>
        <RightPanelInventoryTimeComponent
            time={time}
            setTime={setTime}
        />
        <RightPanelInventoryData time = {time}/>
    </Fragment>
  )
}
