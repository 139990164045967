import { styled } from "@mui/material/styles";
import { Grid } from "@mui/material";
import {
	Z_MAIN_GRID,
	Z_SEARCH_PANEL,
	Z_SUB_GRID,
	Z_TABLE_PANEL,
} from "../../constants/constants";
import DragHandle from "@mui/icons-material/DragHandle";

export const Root2width = 360;

export const Root = styled(Grid)(({ theme }) => ({
	top: 0,
	left: 0,
	height: "100%",
	background: "#fff",
	zIndex: Z_SEARCH_PANEL,

	[theme.breakpoints.down("xs")]: {
		width: "100vw",
	},
}));

export const Root2 = styled(Grid)(({ theme }) => ({
	top: 0,
	right: 0,
	height: "100%",
	background: "#fff",
	zIndex: Z_SEARCH_PANEL,
	position: "fixed",
	width: Root2width,
	display: "inline-flex",
	boxSizing: "border-box",
	flexShrink: 0,

	[theme.breakpoints.down("xs")]: {
		width: "100vw",
	},
}));

export const RootHorizontal = styled(Grid)(({ theme }) => ({
	bottom: 0,
	right: 0,
	height: "100%",
	// width: `calc(100% - ${PANEL_WIDTH}px)`,
	background: "#fff",
	zIndex: Z_TABLE_PANEL,

	[theme.breakpoints.down("xs")]: {
		width: "100-vw",
	},
}));

export const MainGrid = styled(Grid)(({ theme }) => ({
	textAlign: "center",
	left: 0,
	zIndex: Z_MAIN_GRID,
	height: "100%",
	boxShadow: theme.shadows[13],
	background: "#fff",

	[theme.breakpoints.down("xs")]: {
		position: "fixed",
		display: "contents",
		width: "100%",
		overflow: "hidden",
		marginBottom: theme.spacing(1),
	},
}));

export const SubGrid = styled(Grid)(({ theme }) => ({
	width: "100%",
	overflowY: "auto",
	padding: theme.spacing(1),
	background: "#fff",
	zIndex: Z_SUB_GRID,

	[theme.breakpoints.down("xs")]: {
		left: 0,
		bottom: 0,
		width: "100vw",
		height: "auto",
		marginTop: 0,
		padding: theme.spacing(0.5),
	},
}));

export const GridHandle = ({ sx }) => {
	return (
		<DragHandle
			id="grid-handle"
			color="gray"
			fontSize="medium"
			sx={{
				position: "absolute",
				width: "1.2em",
				height: "0.4em",
				zIndex: Z_SEARCH_PANEL,
				borderRadius: 2,
				background: "#fff",
				transform: "rotate(180deg) !important",
				outline: "1px solid rgba(0,0,0,0.3)",
				cursor: "grab",
				":hover": { outline: "1px solid rgba(0,0,0,0.5)", color: "#777" },
				...sx,
			}}
		/>
	);
};
